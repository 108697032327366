<div class="modal fade teacherFeedback" id="feedbackModal" data-keyboard="false" tabindex="-1" role="dialog"
       aria-labelledby="exampleModalLabel" aria-hidden="true">
       <div class="modal-dialog  modal-dialog-centered" role="document">
              <div class="modal-content">
                     <div class="modal-header">
                            <div class="img_container">
                                   <img src="/assets/images/teacher-feedback/feedback_info.svg" alt="Teacher Feedback">
                            </div>
                            <button type="button" class="close" (click)="closefeedbackPopupData()">
                                   <img src="/assets/images/teacher-feedback/ion-close.svg" alt="Teacher Feedback">
                            </button>
                     </div>
                     <div class="modal-body text-center">
                            <h4>myTeachers' Feedback</h4>
                            <ng-container *ngIf="feedbackPopupData[currentAlertIndex]?.formType === tab?.week; else monthlyTpl">
                                   <p>
                                          Please provide the feedback for all the concepts marked as completed by your faculty from
                                          {{(feedbackPopupData[currentAlertIndex]?.startDate ||
                                          phaseData[feedbackPopupData[currentAlertIndex]?.phaseNo]?.startDate) | date: dateFormat}} to
                                          {{feedbackPopupData[this.currentAlertIndex]?.endDate ||
                                          phaseData[feedbackPopupData[this.currentAlertIndex]?.phaseNo]?.endDate | date: dateFormat }}
                                   </p>
                            </ng-container>
                            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="getFeedbackForm()">{{
                                   feedbackPopupData?.formId ? feedbackStatus.EDIT : feedbackStatus.GIVE }} Feedback</button>
                     </div>
              </div>
       </div>
</div>
<ng-template #monthlyTpl>
       <p>Please provide faculty feedback for {{ description }}<br />
              ({{ (feedbackPopupData[currentAlertIndex]?.startDate ||
              phaseData[feedbackPopupData[currentAlertIndex]?.phaseNo]?.startDate) | date: dateFormat }} - {{
              (feedbackPopupData[this.currentAlertIndex]?.endDate ||
              phaseData[feedbackPopupData[this.currentAlertIndex]?.phaseNo]?.endDate) | date: dateFormat }}).</p>
</ng-template>
<app-header-before-login *ngIf="!authenticated && showHeader"></app-header-before-login>

<app-header-after-login *ngIf="authenticated && showHeader"></app-header-after-login>
<app-loader></app-loader>
<router-outlet></router-outlet>
<app-alert></app-alert>
<!-- <app-connection-unstable></app-connection-unstable> -->
<!-- <app-page-not-found></app-page-not-found> -->
<!-- <app-under-development></app-under-development> -->
<!-- <app-footer-before-login *ngIf="!authenticated && showHeader"></app-footer-before-login> -->





export const environment = {
  production: true,
  baseHref: '/',
  glorifireUrl: 'https://glorifire.com',
  myPatUrl: 'https://mypat.in',
  myPatUrlv4: 'https://mypat.in',
  fiitjeeUrlv3: 'https://fiitjee-v3.mypat.in',
  fiitjeeUrlv4: 'https://fiitjee.mypat.in',
  serverURLv3: 'https://v2api2-prod.mypat.in/',
  serverURLv4: 'https://v4-auth-service.mypat.in/',
  mockServerURL: '',
  fbAppId: '432998907135563',
  google_clientId: '544027600908-fl76h9b7sq949f3m9co5pnr9805nd61a.apps.googleusercontent.com',
  partnerDomain: 'https://fiitjee.mypat.in/auth/admin-access/',
  razorPayKey: 'rzp_live_tEr4IjWx6QfbwV',
  clevertapId: '698-8W8-545Z',
  kaptureCrmKey : 'Basic aDM3b2FtcmtjYzY1dHhzbzl6bDFmM2hoNnNodWF2cnNsenM5bGJuaGN1M3NqYnowdGg=',
  name: 'prod' ,
  captchaKey : '6LdURVEUAAAAADSnZK0cJacinv-PqlTO_7K-CQkU',
  zestMoneyMerchantId: '89cad954-b7ea-47a9-af2d-8c9a0027357f',
  zestMoneyPaymentGatewayId: '31a2b66b-f364-48f9-a7fd-6870a5a224b4',
  teacherSiteURL: 'https://schools-teacher.mypat.in/login',
  schoolPartnerDomain: 'https://schools.mypat.in/',
  teacherServerURL: 'https://v2enterprise-api.mypat.in/',
  s3BucketBaseUrl: 'https://s3.us-west-2.amazonaws.com/mypat-v2-staging/',
  glorifireCommunity: 'https://community.glorifire.com',
  glorifireliveClasses: 'https://liveclasses.glorifire.com',
  gtmId: 'GTM-NHSSJKV',
  fingerprintJsKey: 'V5ebyiwdj0kjhUKkCrCj',
  authors: 'https://authors.glorifire.com',
  glorifireJr: 'https://junior.glorifire.com/',
  helpGuidePdfId: '66338b417d066b471dda531d',
  aitsRedirectUrl: 'https://fiitjee-aits.mypat.in',
};

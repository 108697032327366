export const DATE_SUFFIX = {
    ST: 'st',
    ND: 'nd',
    RD: 'rd',
    TH: 'th',
    TEN: 10,
    ELEVEN: 11,
    THIRTEEN: 13,
};

export const DEFAULT = 'default';
<!--logoutModal Popup Modal -->
<div class="modal fade" id="logoutModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="closeModal()">
          <span aria-hidden="true"><img src="../../../../assets/images/cross-icon-modal.svg"></span>
        </button>
      </div>
      <div class="modal-body">
        <img src="../../../../assets/images/logout.svg" />
        <h3>Logout</h3>
        <p>Are you sure you want to logout?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-footer" (click)="closeModal()">No</button>
        <button type="button" class="btn btn-close" (click)="logout()">Yes</button>
      </div>
    </div>
  </div>
</div>
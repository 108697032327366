<div class="reportLists idea-support-report-list">
    <div *ngIf="!showLoader">
    <!-- Responsive info modal  -->
    <h4 class="mobileHeading d-block d-sm-none" *ngIf="!maxPageSize">{{status || 'All'}}  
        <span *ngIf="status"><img src="/assets/images/info-icon.svg" data-toggle="modal" (click)="openInfo($event)"/> </span>
    </h4>
    <!-- End: Responsive info modal -->
    <div id="myGroup" >
    <div class="accordion">
       <ng-container *ngFor="let helpReport of ideaDataFiltered; let i=index">
        <div id="notificationListingTop{{helpReport?.ticketId}}"></div>
            <div class="accordion accordion-group" id="accordion{{i}}" aria-expanded="false" (click)="clickTab(i, helpReport)">
            <div class="ticket-move-info-view" *ngIf="helpReport?.subStatus"><img src="/assets/img/s3-images/bestAnswer.svg" alt="Info" /> 
                <span *ngIf="helpReport.subStatus === STATUS_VALUE.PARTIALLY_ACCEPTED">This {{helpReport.type | titlecase}} is Partially Accepted</span>
                <span *ngIf="helpReport.subStatus === STATUS_VALUE.ALREADY_AVAILABLE">This {{helpReport.type | titlecase}} is Already Available</span>
            </div>
             <div class="accordion-body">
                <div class="accordion-heading">
                    <a class="accordion-toggle" aria-expanded="false" data-toggle="collapse"  id="{{helpReport?.ticketId}}" data-parent="#myGroup"
                        href="#collapse{{helpReport?.ticketId}}">
                        <b class="titleTxt">{{helpReport?.description}}</b>
                        <em class="{{getStyle(helpReport?.status)}}">{{getStausText(helpReport?.status)}}</em>

                        <div class="listDetails">
                            <span>ID: <b class="detailData" innerHTML="{{ helpReport?.ticketId | highlight : searchHighlightStr}}"></b></span>
                            <span>Type: <b class="detailData">{{helpReport?.type}}</b></span>
                            <span [innerHTML]="getCurrentTicketStatusWithDate(helpReport)"></span>
                        </div>
                    </a>
                </div>
                <!-- [ngClass]="(fi < 3 || getSelectedFilterInfo(filter)) ? 'show' : 'collapse'" -->
                <div id="collapse{{helpReport?.ticketId}}" class="accordion-body collapse" data-parent="#myGroup" [class.show]="tid===helpReport?.ticketId">
                    <div class="accordion-inner">
                        <div class="reportedDate">
                            <div class="reportType" *ngIf="helpReport?.categoriesObj?.length">
                                <span> <span id="captial">{{helpReport?.type}}</span> Type: {{getCategories(helpReport)}}
                                    <i [ngClass]="{arrowIcon: suggestionItemsShow}" (click)="tooltipshow()" *ngIf="getNumber(helpReport)">+{{getNumber(helpReport)}} <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.5L5.0352 5.5L10.0704 0.5H0Z" fill="#1D7DEA"/>
                                        </svg>                                        
                                        <span class="customTooltip" [ngClass]="{active: suggestionItemsShow}" *ngIf="suggestionItemsShow">
                                            <em class="SuggestionItems" *ngFor="let item of selectedCategory">{{item}}</em>
                                        </span>
                                    </i>
                                </span>
                            </div>
                            <div class="submittext" *ngIf="helpReport?.status !== HELP_REPORT_STATUS.SUBMITTED" [ngClass]="{spanlisttwo: helpReport.actionDates.length===3}" [innerHTML]= helpReport.htmlContent>
                            </div>
                        </div>
                <!-- Comment box -->
                <div class="comment_container" *ngIf="displayComments[helpReport?._id]?.length && ([HELP_REPORT_STATUS.SUBMITTED,HELP_REPORT_STATUS.ACCEPTED,HELP_REPORT_STATUS.ONGOING,HELP_REPORT_STATUS.CLOSED].includes(helpReport.status))">
                    <div class="commentBox">
                        <div class="comment-header">
                            <h5><img src="assets/images/help/add-comments.svg" alt = " Add Comment Image "> Comments </h5>
                        </div>
                        <div class="comment-body" id="{{helpReport?.ticketId}}">
                            
                            <ng-container *ngFor="let commentDetail of displayComments[helpReport?._id];let i = index">
                                <div class="commentsList right" *ngIf="commentDetail?.userType === USER_TYPE.MYPAT">
                                    <div class="mr-12 df-list-end">
                                        <div class="comments-txt">{{commentDetail?.message}}</div>
                                        <div class="attached-img" *ngIf="commentDetail?.attachments?.length">
                                            <ul>
                                                <li *ngFor="let attachment of commentDetail?.attachments; let i = index">
                                                        <img [hidden]="!imageShimmer[i]" (load)="onImageLoad(i)" (click)="modalPopOpen($event, commentDetail, i)" *ngIf="attachment.type === MEDIA_TYPE.IMAGE"
                                                            [src]="attachment.url" alt = " Comment Attachment "/>
                                                        <img [hidden]="!imageShimmer[i]" (load)="onImageLoad(i)" (click)="modalPopOpen($event, commentDetail, i)" *ngIf="attachment.type == MEDIA_TYPE.VIDEO"
                                                            src="/assets/images/help/bgwith-play.png"  alt = " Comment Attachment " />
                                                    <div *ngIf="!imageShimmer[i]" class="shimmer">
                                                        <div class="box-md"></div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="comment-date">{{commentDetail.createdAt | date :'d MMM y, h:mm a'}}</div>
                                    </div>
                                
                                    <div>
                                        <div class="profileImg">
                                            <img *ngIf="photoUrl; else initialClass" [src]="photoUrl" alt = " Profile Image ">
                                            <ng-template #initialClass>
                                                <span class="{{initialsClass}}">{{userProfile.userData?.initials|slice:0:2}}</span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                                <div class="commentsList left" *ngIf="commentDetail?.userType !== USER_TYPE.MYPAT">
                                        <div class="profileImg">
                                            <img src="/assets/images/glogo.svg" alt = " Profile Image ">
                                        </div>
                                    <div class="ml-12 df-list-start">
                                        <div class="comments-txt">{{commentDetail?.message}}</div>
                                        <div class="attached-img" *ngIf="commentDetail?.attachments?.length">
                                            <ul>
                                                <li *ngFor="let attachment of commentDetail?.attachments; let i = index">
                                                    <img [hidden]="!imageShimmer[i]" (load)="onImageLoad(i)" (click)="modalPopOpen($event, commentDetail, i)" *ngIf="attachment?.type === MEDIA_TYPE.IMAGE"
                                                        [src]="attachment.url" alt = " Comment Attachment "/>
                                                    <img [hidden]="!imageShimmer[i]" (load)="onImageLoad(i)" (click)="modalPopOpen($event, commentDetail, i)" *ngIf="attachment?.type == MEDIA_TYPE.VIDEO"
                                                        src="/assets/images/help/bgwith-play.png" alt = " Comment Attachment " />
                                                    <div *ngIf="!imageShimmer[i]" class="shimmer">
                                                        <div class="box-md"></div>
                                                    </div>    
                                                </li>
                                            </ul>
                                            </div>
                                        <div class="comment-date">{{commentDetail?.createdAt | date :'d MMM y, h:mm a'}}</div>
                                    </div>   
                                </div>
                            </ng-container>
                        
                        </div>
                    </div>
                </div>
                <div class="text-right">     
                    <span class="text-right addComment" *ngIf="displayComments[helpReport?._id]?.length && ([HELP_REPORT_STATUS.SUBMITTED, HELP_REPORT_STATUS.ACCEPTED, HELP_REPORT_STATUS.ONGOING].includes(helpReport.status)) " (click)="openCommentModal(helpReport)">
                        Add Comment
                    </span>
                </div>
        
                <!-- End -->
                        <p  innerHTML="{{ helpReport?.description | highlight : searchHighlightStr}}">
                        </p>
                        <div class="fileattached">
                            <span *ngFor="let listImage of helpReport?.attachments; let i = index">
                                <img (click)="modalPopOpen($event, helpReport, i)" *ngIf="listImage.type == MEDIA_TYPE.IMAGE" [src]="listImage?.url" alt = " Attached Image "/>
                                <img (click)="modalPopOpen($event, helpReport, i)" *ngIf="listImage.type == MEDIA_TYPE.VIDEO" src="/assets/images/help/bgwith-play.png" alt = " Attached Video " />
                            </span>
                        </div>
                        <!-- Solution -->
                        <div *ngIf="helpReport?.solution?.message" class="solutionPanle">
                            <div class="solution" [ngClass]="{'remarks': [HELP_REPORT_STATUS.ACCEPTED, HELP_REPORT_STATUS.CLOSED].includes(helpReport.status)}">
                                <div class="titleDate">
                                    <h5 class="title">
                                    <img *ngIf="[HELP_REPORT_STATUS.ACCEPTED, HELP_REPORT_STATUS.CLOSED].includes(helpReport.status); else solutionImage" class="icon" src="/assets/images/remarks1.svg"  alt = " Remarks Image " />
                                    <ng-template #solutionImage>
                                        <img  class="icon" src="/assets/images/help/solution-icon.svg" alt = " Solution Icon Image "/>{{([HELP_REPORT_STATUS.ACCEPTED, HELP_REPORT_STATUS.CLOSED].includes(helpReport.status)) ? 'Remarks' : 'Solution' }}
                                    </ng-template>
                                </h5>
                                <span *ngIf="[HELP_REPORT_STATUS.COMPLETED, HELP_REPORT_STATUS.CLOSED].includes(helpReport.status)">{{helpReport?.lastActionTaken?.date | date:'d MMM y, h:mm a'}}</span>
                            </div>
                                <p>
                                    {{helpReport?.solution?.message}}
                                </p>
                            </div>
                            <div *ngIf="helpReport?.feedback?.rating === null" class="LeaveFeedback">
                                <a role = "button" (click)="onFeedBackOpen(helpReport)" class="btn-text"><img src="/assets/images/help/leaveFeedback.svg" alt = " Leave Feedback Image "/> Leave Feedback</a>
                            </div>
                            <!-- Edit feedback -->
                            <div *ngIf="helpReport?.feedback?.rating !== null" class="editFeedback">
                                <div class="header">
                                    <div class="leftSec">
                                        <b><img class="icon" src="/assets/images/help/feedback-icon.svg" alt = " Feedback Image " />My Feedback</b>
                                        <a href="javascript:void(0)" (click)="onFeedBackOpen(helpReport)" class="btn-edit">Edit</a>
                                    </div>
                                    <em>{{getFormattedDate(helpReport?.feedback?.createdAt)}}</em>
                                </div>
                                <div *ngIf="helpReport?.feedback?.rating" class="feedRating">
                                    <div class="star" *ngFor="let star of [1,2,3,4,5]; let i= index" [ngClass]="{'active': (star <= helpReport?.feedback?.rating)}">
                                        <svg width="20" height="20" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" class="active">
                                            <path d="M14 0L17.1432 9.67376H27.3148L19.0858 15.6525L22.229 25.3262L14 19.3475L5.77101 25.3262L8.9142 15.6525L0.685208 9.67376H10.8568L14 0Z" fill="#C4C4C4"/>
                                        </svg>
                                    </div>
                                </div>
                                <p>{{helpReport?.feedback?.message}}</p>
                                
                            </div>
                        </div>
                        <!-- End -->
                    </div>
                </div>
             </div>
           </div>
        </ng-container>
    </div>
</div>
<!-- <div *ngIf="(!ideaDataFiltered || !ideaDataFiltered.length && !newImage) && !showLoader" class="no-issue">
    <img src="/assets/images/help/no-issue-reported.svg" alt="">
    <h4>So Far So Good</h4>
    <p>You haven’t added any Ideas/Suggestions yet!</p>
    <button class="btn btn-primary" data-toggle="modal" data-target="#reportIssueidea" (click)="toggleIssuePopUp()">
        Submit Idea
    </button>
</div> -->

<div *ngIf="(!ideaDataFiltered || !ideaDataFiltered.length && newImage) && !showLoader" class="no-issue idea">
    <img src="/assets/images/help/no-issue-found.svg" alt="">
    <h4 *ngIf="!isOtherFilterSelected && !searchHighlightStr">No Ideas/Suggestions Found</h4>
    <h4 *ngIf="isOtherFilterSelected || searchHighlightStr">No Results Found</h4>
    <!-- <p *ngIf="!isOtherFilterSelected"><span *ngIf="status == 'ongoing' || status == 'completed' || status =='accepted'">None of the</span><span *ngIf="status == 'submitted' || status == 'All'">No</span> Ideas/Suggestions <span *ngIf="status == 'All'">are</span> submitted <br *ngIf="status != 'submitted'"/> by you<span *ngIf="status == 'All'"> yet</span><span *ngIf="status == 'accepted'"> are in accepted state</span>
    <span *ngIf="status == 'ongoing'"> are currently being worked upon</span><span *ngIf="status == 'completed'"> have been completed so far</span>!</p>
     -->
    <p *ngIf="!isOtherFilterSelected && !searchHighlightStr">
        <span *ngIf="status == 'submitted'">No Ideas/Suggestions have been submitted by you!</span>
        <span *ngIf="status =='accepted'">None of the Ideas/Suggestions submitted <br/>by you are in accepted state!</span>
        <span *ngIf="status == 'ongoing'">None of the Ideas/Suggestions submitted by<br/> you are currently being worked upon!</span>
        <span *ngIf="status == 'completed'">None of the Ideas/Suggestions submitted <br/>by you have been completed so far!</span>
        <span *ngIf="status == 'closed'">None of the Ideas/Suggestions submitted <br/>by you have been closed so far!</span>
    </p>

    <p *ngIf="searchHighlightStr"><span>Please try searching with a different ID or keyword</span></p>
    <p *ngIf="isOtherFilterSelected && !searchHighlightStr"><span>There are no results matching the filters you have applied!</span></p>
</div>  

</div>
<!-- <app-selected-card-loader *ngIf="showLoader" [cardName]="'ideaReport'"></app-selected-card-loader> -->
<div class="swotboardLoader-container" *ngIf="showLoader">
    <ul>
      <li>
        <div class="graph-container">
          <div class="graph-text"></div>
          <div class="graph-text"></div>
          <div class="graph-text"></div>
        </div>
        <div class="graph-container">
            <div class="graph-text"></div>
            <div class="graph-text"></div>
            <div class="graph-text"></div>
          </div>
          <div class="graph-container">
            <div class="graph-text"></div>
            <div class="graph-text"></div>
            <div class="graph-text"></div>
          </div>
      </li>
    </ul>
  </div>
</div>
<app-help-feedback [currentHelpObj]="currentHelpObj" [type]="'idea'"></app-help-feedback>
<app-help-light-box ></app-help-light-box>
<app-report-an-issue-idea *ngIf="!isMobile && showNewIdeaPopUp" (onClosePopUp)="toggleIssuePopUp()" ></app-report-an-issue-idea>
<app-provide-comments *ngIf="showCommentModal" [issueId]="commentId" (onClosePopUp)="onClosePopUp()" (onSuccessfulComment)="onSuccessfulComment()"></app-provide-comments>


  

<!-- status info modal -->
<div class="modal fade small-modal" tabindex="-1" id="ideaSuggestionStatusInfoPopup">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
      <div class="modal-content">
        <div class="modal-body">
          <img *ngIf="status && status !== 'All'" [src]="'/assets/images/idea-and-suggestion/'+infoPopUpData[status]?.iconUrl"/>
          <h3>{{infoPopUpData[status]?.heading}}</h3>
          <p>{{infoPopUpData[status]?.text}}</p>
        </div>
        <div class="modal-footer single-btn">
          <button type="button" class="btn btn-primary" data-dismiss="modal">Got It</button>
        </div>
      </div>
    </div>
  </div>

import { Component, Input, OnInit, Output, EventEmitter, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SignupService } from 'src/app/auth/signup-login-master/shared/services/signup.service';
import { UtilityService } from '../../services/utility.service';
import { CaptchaDirective } from '../../directives/captcha.directive';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-verify-number-with-otp',
  templateUrl: './verify-number-with-otp.component.html',
  styleUrls: ['./verify-number-with-otp.component.scss']
})
export class VerifyNumberWithOtpComponent implements OnInit, OnChanges, OnDestroy {

  @Input() userInfo: any = {}
  @Input() process: string;
  @Input() verifyMode: string;
  @Input() fromScreen: string = '';
  @Output() resendOtpClicked = new EventEmitter<any>();
  @Output() backClickedVerifyOtp = new EventEmitter<any>();
  @Output() submittedOTP = new EventEmitter<any>();
  showTimer = true;
  editMobile = false;
  subject = new Subject();
  timerSec: Observable<number>;
  public otpSub: any;
  public timeValue = 30;
  public interval: any;
  public enteredOtpValue: any;
  public otpApiError = '';
  public captchaShow: boolean = false;
  public capcthaChecked: boolean = false;
  public showCaptchaError: boolean = false;
  public otpSentToDetails: string = '';
  public showTimerValue: string = '';
  public otpVerifyModeDetails: string = '';
  @ViewChild(CaptchaDirective) vc: CaptchaDirective;
  constructor(private signupService: SignupService, private utility: UtilityService, private router: Router) { }

  ngOnInit(): void {
    this.setOtpSentToDetails();
    this.otpSub = this.signupService.otpApiError.subscribe((res) => {
      if (res) {
        this.otpApiError = res;
      } else {
        this.otpApiError = '';
      }
    });

    this.signupService.resetCaptcha.subscribe((res) => {
      if (res) {
        this.captchaExpired();
      }
    });
  }

  ngOnChanges(): void {
    this.process = ''
    this.loadScriptForOTP();
    this.startTimer();
    this.clearAllValues();
    this.checkIfCaptchaShow();
  }

  clearAllValues(): void {
    (document.getElementById('otp1') as HTMLInputElement).value = '';
    (document.getElementById('otp2') as HTMLInputElement).value = '';
    (document.getElementById('otp3') as HTMLInputElement).value = '';
    (document.getElementById('otp4') as HTMLInputElement).value = '';
  }

  startTimer(): void {
    this.otpApiError = '';
    this.showTimer = true;
    this.showCaptchaError = false;
    this.capcthaChecked = false;
    clearInterval(this.interval);
    const otpResendCount: number =
      parseInt(sessionStorage.getItem('otpResendCount')) || 0;
    if (otpResendCount <= 3) {
      this.timeValue = 30;
    } else if (otpResendCount <= 10) {
      this.timeValue = 2 * 60;
    } else if (otpResendCount <= 20) {
      this.timeValue = 5 * 60;
    } else {
      this.timeValue = 10 * 60;
    }
    this.setShowTimerValue();
    this.interval = setInterval(() => {
      if (this.timeValue > 0) {
        this.timeValue--;
        this.setShowTimerValue();
      } else {
        this.pauseTimer();
      }
    }, 1000);
  }

  pauseTimer(): void {
    this.showTimer = false;
    clearInterval(this.interval);
  }

  resendOtp(): void {
    if (this.captchaShow && !this.capcthaChecked) {
      this.showCaptchaError = true;
    } else {
      let otpResendCount: number =
        parseInt(sessionStorage.getItem('otpResendCount')) || 0;
      sessionStorage.setItem(
        'otpResendCount',
        JSON.stringify(++otpResendCount)
      );
      let token;
      if (this.captchaShow && this.vc) {
        token = this.vc.getResponse();
      }
      this.resendOtpClicked.emit(token);
    }
  }

  backClicked(value?): void {
    this.backClickedVerifyOtp.emit(value);
  }

  closeScreen(): void {
    this.router.navigate(['/']);
  }


  loadScriptForOTP(): void {
    // tslint:disable-next-line: typedef
    $('.inputs').keyup(function (e) {
      if (e.which === 8 || e.which === 46) {
        $(this).prev('.inputs').focus();
      } else {
        if (this.value.length === this.maxLength) {
          $(this).next('.inputs').focus();
        }
      }
    });
  }

  submitOTP(value): void {
    if (!value.otp1 || !value.otp2 || !value.otp3 || !value.otp4) {
      this.otpApiError = 'please enter valid otp';
      return;
    }
    this.enteredOtpValue = Number(value.otp1 + value.otp2 + value.otp3 + value.otp4);

    let userDataOnEnterOtp: any = {
      otp: this.enteredOtpValue,
      mobile: this.userInfo.mobile,
      email: this.userInfo.email,
      verifyMode: this.verifyMode,
    };

    if (this.fromScreen !== 'manage-devices') {
      userDataOnEnterOtp = {
        ...userDataOnEnterOtp,
        mobile: this.userInfo.mobile,
      }
    }

    this.submittedOTP.emit(userDataOnEnterOtp);
  }

  checkIfCaptchaShow(): void {
    this.utility.fetchCaptchaDetails().then((res) => {
      if (res) {
        if (res && res.code === 200) {
          this.captchaShow = res.data.showCaptcha;
        }
      }
    });
  }

  getSuccess(): void {
    this.capcthaChecked = true;
  }

  captchaExpired(): void {
    if (this.vc) {
      this.vc.reset();
    }
    this.capcthaChecked = false
  }

  setShowTimerValue(): void {
    const minutes = Math.floor(this.timeValue / 60);
    const seconds = this.timeValue % 60;
    this.showTimerValue = `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }

  setOtpSentToDetails(): void {
    this.otpVerifyModeDetails =
      this.verifyMode === 'email'
        ? this.userInfo.email
        : `${this.userInfo.countryCode} - ${this.userInfo.mobile}`;
    let data = '';
    if (this.userInfo.mobile) {
      data += `${this.userInfo.countryCode} - ${this.userInfo.mobile}`;
    }
    if (this.userInfo.mobile && this.userInfo.email) {
      data += ' & ';
    }
    if (this.userInfo.email) {
      data += this.userInfo.email;
    }
    this.otpSentToDetails = data;
  }

  ngOnDestroy(): void {
    if (this.otpSub) {
      this.otpSub.unsubscribe();
    }
    this.signupService.otpApiError.next(null);
    this.signupService.resetCaptcha.next(false);
  }
}

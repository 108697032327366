import { Injectable } from '@angular/core';

import { HttpService } from '../../shared/services/http.service';
import { AlertService } from '../../shared/services/alert.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressIndexService {

  constructor(private http: HttpService, private alert: AlertService) { }

  getProgressIndex(courseIds): any {
    return new Promise((resolve, reject) => {
      this.http.get('v4/auth/progress-index', true,
        { courseId: courseIds }, true, false
      ).subscribe((res) => {
        if (res) {
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  getProgressIndexAndProgressTrackerData(courseId: string): Observable<any> {
    const query = { 
      courseId,
    };
    return this.http.get('v4/auth/progress-index/student-progress', true, query, true);
  }

  getPendingSyllabusData(courseId: string, subjectSyllabusId: string): Observable<any> {
    const query: any = { 
      courseId,
    };
    if (subjectSyllabusId) {
      query.subjectSyllabusId = subjectSyllabusId;
    }
    return this.http.get('v4/auth/progress-index/pending-syllabus', true, query, true);
  }

  fetchMyGoalData(): any {
    return new Promise((resolve, reject) => {
      const courses:any = JSON.parse(localStorage.getItem('cachedCourses'));
      if (courses && courses['data']){
        resolve(courses.data);
      } else {
        this.http.get('v4/auth/users/courses', true, null, true, false,
        ).subscribe((res) => {
          if (res && res.code === 200) {
            resolve(res.data);
          } else {
            this.alert.showErrorMessage(res.message);
          }
        });
      }
    });
  }


  getProgressTracker(courseId, subjectId): any {
    return new Promise((resolve, reject) => {
      this.http.get('v4/auth/progress-tracker', true,
        { courseId: courseId, subjectId: subjectId }, true, false, 
      ).subscribe((res) => {
        if (res) {
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }


}

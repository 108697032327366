import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

declare var MathLive: any;
@Directive({
  selector: '[mathlive]'
})
export class MathliveDirective implements OnChanges {

  private renderCount = 0;
  private intervalId: any;
  @Input('mathlive') content: any;

  constructor(private element: ElementRef) {
  }

  async makeFormula(formula: any) {

    const dynamicFormulaElement = this.element.nativeElement;
    dynamicFormulaElement.innerHTML = formula;

    clearInterval(this.intervalId);
    this.intervalId = setInterval(() => {
      this.renderCount++;
      if (MathLive) {
        MathLive.renderMathInElement(dynamicFormulaElement);
      }
      if (this.renderCount === 2)
        clearInterval(this.intervalId);
    }, 1000);

    if (MathLive) {
      MathLive.renderMathInElement(dynamicFormulaElement);
    }

  }
  ngOnChanges(changes: SimpleChanges): void {

    if (changes["content"]?.currentValue)
      this.makeFormula(this.content);

  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HelpFilterModel } from './help-filter.model';

@Injectable({ providedIn: 'root' })
export class HelpFilterService {
    private subject = new Subject<any>();
    public isClearFilters = new Subject<any>();

    sendFilter(data: HelpFilterModel) {
        this.subject.next(data);
        this.isClearFilters.next(false);
    }

    clearFilters() {
        this.subject.next();
        this.isClearFilters.next(true);
    }

    onFilter(): Observable<any> {
        return this.subject.asObservable();
    }
}
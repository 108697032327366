import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError, map} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromApp from '../../stores/app.reducers';
import * as LoaderAction from '../../stores/loader/loader.actions';
import { AlertService } from './alert.service';
import { LazyLoadingService } from './lazy-loading.service';
import { LazyLoadFileImpl } from '../shared.module';

@Injectable({
  providedIn: 'root'
})
export class DetectLocationService {

  public location = {
    latitude: 0,
    longitude: 0,
  };

  constructor(private lazyLoadingService:LazyLoadingService, private httpService: HttpClient, private store: Store<fromApp.AppState>, private alert: AlertService) {
  }

  getMyCurrentLocation(): void {
    /*if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        if (position.coords) {
          this.location.latitude = position.coords.latitude;
          this.location.longitude = position.coords.longitude;
          this.lazyLoadingService.lazyLoad([
            new LazyLoadFileImpl("https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyCSnaItBR8MPsF5YeHbP8vJ9dqVW_1WHbA",'script')
          ]).toPromise();
        }
      });
    }*/
  }

  handleError = (error: any): Observable<any> => {
    this.store.dispatch(new LoaderAction.SetLoader(false));
    try {
      return throwError(error);
    } catch (e) {
      return throwError(e);
    }
  }

  getLocation(): Observable<any> {
      const lat = this.location.latitude;
      const lan = this.location.longitude;
      if (lat === 0 || lan === 0) {
        this.alert.showErrorMessage('Please allow location service');
        return EMPTY;
      } else {
        if (lat !== 0 || lan !== 0) {
          // this.store.dispatch(new LoaderAction.SetLoader(true));
          return this.httpService.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lan + '&sensor=true&key=AIzaSyCSnaItBR8MPsF5YeHbP8vJ9dqVW_1WHbA').pipe(
            map((res: Response) => {
              this.store.dispatch(new LoaderAction.SetLoader(false));
              return res;
            }),
            catchError(this.handleError)
          );
        } else {
          return EMPTY;
        }
      }
  }

  isFiitjeeDomain(): boolean {
    if (window.location.host.split('.')[0].includes('fiitjee')) {
      return true;
    }
    return false;
  }

  isSchoolDomain(): boolean {
    if (window.location.host.split('.')[0].includes('school')) {
      return true;
    }
    return false;
  }
}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { OverviewService } from 'src/app/test-results-analytics/services/overview.service';

declare var $: any;
@Component({
  selector: 'app-multiselectfilter',
  templateUrl: './multiselectfilter.component.html',
  styleUrls: ['./multiselectfilter.component.css']
})
export class MultiselectfilterComponent implements OnInit, OnChanges {

  @Input() filters: any;
  @Output() onChange = new EventEmitter<any>();
  @Input() clearingData: Boolean;
  constructor(private overviewService:OverviewService) { }
  ngOnInit() {

  }

  ngOnChanges() {
    if (this.clearingData) {
      const items = document.getElementsByName('cBoxes');
      const checkElements = Array.from(items);
      checkElements.forEach((res) => {
        if (res['type'] === 'checkbox') {
          res['checked'] = false;
        }
      });
    }
  }

  change(isChecked, filterKey, filterValue) {
    const that = this;
    setTimeout(function () {
      const objToPass = {
        'isChecked': isChecked,
        'filterKey': filterKey,
        'filterValue': filterValue
      };
      that.onChange.emit(objToPass);
    }, 0);
  }

  // isSelected(){
  //   let id="#"+this.option.value;
  //   if(this.filterProps[this.propName].indexOf(this.option.value)>=0)
  //   $(id).prop('checked',true); 
  //   else
  //   $(id).prop('checked',false);
  // }

}

export class Proforma {
    userId: string;
    name: string;
    userMobile: string;
    countryCode: string;
    email: string;
    mypatEnrollmentNumber: string;
    enrollmentNumber: string;
    photoUrlThumb: string;
    photoUrl: string;
    isFiitjee: boolean;
    isSchool: boolean;
    pastAchievements: any[];
    prevEnrollmentInfo: any[];
    student: any;
    parent: {
        status: string,
        name: string,
        email: string,
        mobile: string,
        countryCode: string
    };
    teacher: {
        status: string,
        name: string,
        email: string,
        mobile: string,
        countryCode: string
    };
    revisedGoalSetting: boolean;
    userType: string;
    school: {
        country: any;
        state: any;
        city: any;
        id: string;
        name: string;
        schoolIdUrl: string;
    };
    goalSettingRequired: boolean;
    dueDate: string;
    userClass: any;
    program: {};
    userGoals: [];

    questionsStatus: string;
    goalSelectionStatus: string;
    questions: [];
    goalSelections: [];
    initials: string;
    initialsClass: string;
    userCentres: any;
    public isTemporaryUnblocked: boolean = false;
    public goalSettingFormFilledByStudent: boolean = false;
    constructor(userId?, name?, userMobile?, countryCode?, email?, mypatEnrollmentNumber?,
        enrollmentNumber?, photoUrlThumb?, photoUrl?, isFiitjee?, isSchool?,
        pastAchievements?, prevEnrollmentInfo?, studentObj?, parentObj?, teacherObj?,
        revisedGoalSetting?, userType?, school?, goalSettingRequired?, dueDate?, userClass?, program?, userGoals?, questionStatus?, questions?,
         goalSelectionStatus?, goalSelections?, initials?, initialsClass?, userCentres?, goalSettingFormFilledByStudent?, isTemporaryUnblocked?) {
        this.userId = userId || '';
        this.name = name || '';
        this.userMobile = userMobile || '';
        this.countryCode = countryCode || '';
        this.email = email || '';
        this.mypatEnrollmentNumber = mypatEnrollmentNumber || '';
        this.enrollmentNumber = enrollmentNumber || '';
        this.photoUrlThumb = photoUrlThumb || '';
        this.photoUrl = photoUrl || '';
        this.isFiitjee = isFiitjee || false;
        this.isSchool = isSchool || false;
        this.pastAchievements = pastAchievements || [];
        this.prevEnrollmentInfo = prevEnrollmentInfo || [];
        this.student = studentObj || { status: 'pending' };
        this.parent = parentObj || { status: 'pending' };
        this.teacher = teacherObj || { status: 'pending' };
        this.revisedGoalSetting = revisedGoalSetting || false;
        this.userType = userType || '';
        this.school = school || {};
        this.goalSettingRequired = goalSettingRequired || false;
        this.dueDate = dueDate || '';
        this.userClass = userClass || {};
        this.program = program || {};
        this.userGoals = userGoals || [];
        this.questions = questions || [];
        this.goalSelectionStatus = goalSelectionStatus || [];
        this.questionsStatus = questionStatus;
        this.goalSelections = goalSelections || [];
        this.initials = initials || '';
        this.initialsClass = initialsClass || '';
        this.userCentres = userCentres || null;
        this.goalSettingFormFilledByStudent = goalSettingFormFilledByStudent;
        this.isTemporaryUnblocked = isTemporaryUnblocked;
    }
}

import { DOCUMENT } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef, HostListener, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../stores/app.reducers';
import { UtilityService } from '../../services/utility.service';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AlertService } from '../../services/alert.service';
import { HomeService } from 'src/app/home-page/services/home.service';
import { HomeSearchComponent } from 'src/app/home-page/shared/home-search/home-search.component';
import { RouteDataService } from '../../services/route-data.service';
declare var $: any;

@Component({
  selector: 'app-header-after-login',
  templateUrl: './header-after-login.component.html',
  styleUrls: ['./header-after-login.component.scss']
})

export class HeaderAfterLoginComponent implements OnInit {

  @ViewChild('sideBar') sideBarRef: ElementRef;
  isClassVisible = true;
  public userData: any;
  public freeTrialData: any;
  public currentUrl = '';
  public showLogoutPopup = false;
  public showChangePassword = false;
  public sidebarOverlay: boolean = false;
  isMobile = false;
  showSearchIcon = false;
  hideRightNav = false;
  clearSearch = false;
  public clickedTabDetail: string = '';
  public showRevModal: boolean = false;
  public isNcrpUser = false;
  @ViewChild(HomeSearchComponent) homeSearchComponent: HomeSearchComponent;
  constructor(
    @Inject(DOCUMENT) private document,
    private store: Store<fromApp.AppState>,
    private routeDataService: RouteDataService,
    private router: Router, public utilityService: UtilityService, private alert: AlertService, private homeService: HomeService) {
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    this.sidebarOverlay = false;
    this.store.select('userProfile').subscribe((userProfile) => {
      this.userData = userProfile.userData;
      this.isNcrpUser = this.userData.isNCRPUser;
    });

    // freeTrial code
    // this.getTheFreeTrialData();

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });

    this.currentUrl = this.router.url;

    // for hiding navbar on outer click
    $(document).click((event) => {
      const clickover = $(event.target);
      const $navbar = $('.navbar-collapse');
      const opened = $navbar.hasClass('show');
      if (opened === true && !clickover.hasClass('navbar-toggle')) {
        $navbar.collapse('hide');
      }
    });

    this.headerScrollFixed();
  }

  @HostListener('document:click', ['$event']) onClick(event) {
    let searchEle: any = document.getElementsByClassName('searchHideRes');
    if (searchEle && searchEle.length === 0) {
      this.showSearchIcon = false;
    }
  }

  onExpand(event) {
    this.hideRightNav = event;
  }

  searchClicked() {
    this.showSearchIcon = true;
    this.homeSearchComponent?.clearSearchText();
  }

  showSidebar(): void {
    this.sideBarRef.nativeElement.style.left = '0px';
    this.sidebarOverlay = !this.sidebarOverlay;
  }

  hideSidebar(): void {
    this.sideBarRef.nativeElement.style.left = '-1000px';
    this.sidebarOverlay = !this.sidebarOverlay;
  }

  getTheFreeTrialData(): void {
    this.utilityService.fetchFreeTrialDataAPI();
    this.utilityService.freeTrialDataContainer.subscribe((res) => {
      this.freeTrialData = res;
    });
  }

  navigateToHome(): void {
    this.homeService.updateWorkingOnHomePage.next({ update: 'openHome' });
    if (localStorage.getItem('onLastMinuteRevision') === 'true') {
      this.clickedTabDetail = 'home';
      this.showRevModal = true;
      setTimeout(() => {
        $('#doneRevisionModal').modal({
          backdrop: 'static',
          keyboard: false
        });
      }, 100);
    } else {
    localStorage.removeItem('toExpandCard');
    localStorage.removeItem('openendCardDetails');
    localStorage.removeItem('lastRequested');
    localStorage.removeItem('toExpandCardProgressIndex');
      this.clickedTabDetail = '';
      this.clearSearch = true;
      const card = JSON.parse(localStorage.getItem('openendCardDetails'))?.selectedCard;
      if (card?.type != 'help' && card?.type != 'ideasAndSuggestions') {
        //localStorage.removeItem('toExpandCard');
        //localStorage.removeItem('openendCardDetails');
      }
      if (this.router.url.includes('home') && !this.router.url.includes('/searchResult/')) {
        this.alert.updateWorkingHomePage.next({ update: 'openHome' });
      }
      this.router.navigateByUrl('/home');
      
    }
    this.router.navigateByUrl('/home');
    this.routeDataService.selectedCard.next({});
  }

  navigateToClasses(): void {
    if (localStorage.getItem('onLastMinuteRevision') === 'true') {
      this.clickedTabDetail = 'liveclasses';
      this.showRevModal = true;
      setTimeout(() => {
        $('#doneRevisionModal').modal({
          backdrop: 'static',
          keyboard: false
        });
      }, 100);
    } else {
      this.clickedTabDetail = '';
      const url = (this.userData.isFiitjee ? environment.glorifireliveClasses : environment.myPatUrl) + '?authToken=' + (localStorage.getItem('authToken') || sessionStorage.getItem('authToken')) + '&loginVia=liveClasses';
      window.open(url, '_blank');
    }
  }

  navigateToCommunity(): void {
    if (localStorage.getItem('onLastMinuteRevision') === 'true') {
      this.clickedTabDetail = 'community';
      this.showRevModal = true;
      setTimeout(() => {
        $('#doneRevisionModal').modal({
          backdrop: 'static',
          keyboard: false
        });
      }, 100);
    } else {
      // this.clickedTabDetail = '';
      // const url = (this.userData.isFiitjee ? environment.fiitjeeUrlv3 : environment.myPatUrl) +
      // '/community/view/popular?authToken=' + (localStorage.getItem('authToken') || sessionStorage.getItem('authToken')) + '&redirectUrl=' + environment.fiitjeeUrlv4;
      // window.open(url, '_blank');
      this.router.navigate(['/community/view/popular']);
    }
  }

  goToDownloadApp(): void {
    this.router.navigate(['/mobile-app-download']);
  }

  logoutConfirm(): void {
    this.showLogoutPopup = true;
    setTimeout(() => {
      $('#logoutModal').modal({
        backdrop: 'static',
        keyboard: false
      });
    }, 0);
  }

  closePopup(): void {
    $('#logoutModal').modal('hide');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }

  changePassword(): void {
    this.showChangePassword = true;
    setTimeout(() => {
      $('#changePasswordModal').modal({
        backdrop: 'static',
        keyboard: false
      });
    }, 0);
  }

  closeChangePassword(): void {
    $('#changePasswordModal').modal('hide');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }

  closeRevisionPopup(): void {
    $('#doneRevisionModal').modal('hide');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }


  confirmExit(event): void {
    localStorage.removeItem('onLastMinuteRevision');
    this.showRevModal = false;
    switch (this.clickedTabDetail) {
      case 'home': {
        this.navigateToHome();
        this.closeRevisionPopup();
        break;
      }
      case 'liveclasses': {
        this.navigateToClasses();
        this.closeRevisionPopup();
        break;
      }
      case 'community': {
        this.navigateToCommunity();
        this.closeRevisionPopup();
        break;
      }
    }
  }

  closeRevPopup(): void {
    this.showRevModal = false;
  }

  autoFocusSearchField(): void {
    this.homeService.homeSearchUpdate.emit({ action: 'autoFocusSearchField' });
  }

  clearSearchResults(): void {
    this.homeService.homeSearchUpdate.emit({ action: 'clearAllSuggestions' });
  }

  //Header fixed on scroll
  headerScrollFixed(): void{
    window.addEventListener('scroll', this.scrollListener);
  }
  scrollListener(){
    if(this.document.documentElement.scrollTop > 0){
      this.document.querySelector('header').classList.add('fixed-top');
    }else{
      this.document.querySelector('header').classList.remove('fixed-top');
    }
  }
  ngOnDestroy(): void{
    window.removeEventListener('scroll', this.scrollListener);
  }
}
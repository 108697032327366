import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToOrdinal'
})
export class NumberToOrdinalPipe implements PipeTransform {

  transform(value: number): string {
    const units = ['Zeroth', 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth', 'Eleventh', 'Twelfth', 'Thirteenth', 'Fourteenth', 'Fifteenth', 'Sixteenth', 'Seventeenth', 'Eighteenth', 'Nineteenth'];
    const tens = ['Twent', 'Thirt', 'Fort', 'Fift', 'Sixt', 'Sevent', 'Eight', 'Ninet'];
    if (value < 20) {
      return units[value];
    }
    if (value % 10 === 0) {
      return tens[Math.floor(value / 10) - 2] + 'ieth';
    }
    return tens[Math.floor(value / 10) - 2] + 'y ' + units[value % 10];
  }

}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

declare var $: any;

@Component({
  selector: 'app-register-device',
  templateUrl: './register-device.component.html',
  styleUrls: ['./register-device.component.scss'],
})
export class RegisterDeviceComponent implements OnInit {
  @Input() registeredDevicesData: any = [];
  @Input() fromScreen: any = '';
  @Output() removeDevice: EventEmitter<any> = new EventEmitter<any>();
  public removeDeviceDetails: any = {};
  public deviceTypes: any = {
    'mobile': 'mob-device',
    'tablet': 'tab-device',
    'desktop': 'desk-device'
  }
  private browserMap = {
    'Safari': 'safari',
    'Mobile Safari': 'safari',
    'Chrome': 'chrome',
    'Edge': 'edge',
    'Firefox': 'firefox',
    'android': 'android',
    'fallback': 'generic-browser',
  }
  public getBrowserImage(browser, deviceName){
    const android = this.browserMap['android'];
    if(deviceName?.toLowerCase()?.includes(android)){
      return android;
    }
    return this.browserMap[browser] || this.browserMap['fallback'];
  }
  public greenTickAnimationOptions: AnimationOptions = {
    path: '/assets/animation-json/tick.json',
    loop: true,
  };
  public deviceType = {
    DESKTOP: 'desktop'
  }
  public DEVICE_NAME_MAX_SIZE = 23;
  constructor() {}

  ngOnInit(): void {
    this.registeredDevicesData.forEach((device: any) => {
      device.browserImg = `/assets/images/manage-device/${this.getBrowserImage(device?.device?.model, device?.device?.name)}.svg`;
      this.updateLastActive(device);
    });
  }

  updateLastActive(device: any): void {
    if (!device.lastActive) {
      device.lastActive = '';
      return;
    }
    const today: Date = new Date();
    const deviceLastActiveDate: Date = new Date(device.lastActive);
    let diffTimeInHours: number = Math.floor(
      Math.abs(today.getTime() - deviceLastActiveDate.getTime()) /
        (1000 * 60 * 60)
    );
    if (diffTimeInHours > 48) {
      device.updatedLastActive = `Last Active ${this.formatLastActiveDate(device.lastActive)}`;
      return;
    } 
    if (diffTimeInHours < 1) {
      diffTimeInHours = 1;
    }
    device.updatedLastActive = `Active ${diffTimeInHours} hour${diffTimeInHours === 1 ? '' : 's'} ago`;
  }

  formatLastActiveDate(lastActiveDate: Date): string {
    const deviceLastActiveDate: Date = new Date(lastActiveDate);
    const dayOfMonth = deviceLastActiveDate.getDate();
    let daySuffix = 'th';

    if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
      daySuffix = 'st';
    } else if (dayOfMonth === 2 || dayOfMonth === 22) {
      daySuffix = 'nd';
    } else if (dayOfMonth === 3 || dayOfMonth === 23) {
      daySuffix = 'rd';
    }
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    let [day, month, year] = deviceLastActiveDate
      .toLocaleDateString('en-GB', options)
      .split(' ');
    day += daySuffix;
    return `${day} ${month} ${year}`;
  }

  removeDeviceClicked(device: any) {
    this.removeDeviceDetails = device;
  }

  confirmRemoveDevice() {
    this.removeDevice.emit(this.removeDeviceDetails);
  }
}

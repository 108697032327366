import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { map, catchError, switchMap, tap, mergeMap } from 'rxjs/operators';

import * as AuthActions from './auth.actions';
import * as fromApp from '../../stores/app.reducers';
import * as UserProfileActionList from '../profile/user-profile.actions';
import * as ProformaActions from '../proforma/proforma.actions';

import { HttpService } from '../../shared/services/http.service';
import { AlertService } from '../../shared/services/alert.service';
import { UtilityService } from '../../shared/services/utility.service';
import { ExtraEdgeService } from '../../shared/services/extra-edge.service';
import { CleverTapService } from '../../shared/services/clever-tap.service';
import { SignupService } from '../../auth/signup-login-master/shared/services/signup.service';
import { FiitJeeService } from '../../auth/fiit-jee-login/service/fiit-jee.service';
import { environment } from 'src/environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';


interface ApiResponse {
    code: number;
    data: any;
    message: any;
}

interface SocialSignup {
    provider: string;
    authToken: string;
}

declare var $: any;
declare var window: any;
@Injectable()
export class AuthEffects {

    body;
    response;
    id: any;
    extraedgeCampaign: any;
    leadSource: any;
    leadType: any;
    leadName: any;
    profileDataResponse: any;
    profiledataResponseFiitjee: any;
    public signuplognpassedInfo: any;
    constructor(private http: HttpService,
        private action$: Actions, private store: Store<fromApp.AppState>,
        private router: Router, private route: ActivatedRoute,
        private utility: UtilityService, private signUpService: SignupService,
        private alert: AlertService, private cleverTap: CleverTapService,
        private gtmService: GoogleTagManagerService,
        private extraEdge: ExtraEdgeService, private fiitJee: FiitJeeService) { }


    // Registering the effect of verify otp mobile
    @Effect()
    verifyOtpMobile = this.action$.pipe(
        ofType<AuthActions.VerifyOtpMobile>(AuthActions.VERIFY_OTP_MOBILE),
        switchMap((authData) => {
            const payloadToPass = {
                otp: authData.payload.otp.toString(),
                countryCode: authData.payload.countryCode,
                mobile: authData.payload.mobile,
                osType: 'web',
                device: 'desktop'
            };
            if (payloadToPass.otp.length === 4) {
                return this.http.post('v4/signup/verify-otp', false, payloadToPass).pipe(
                    mergeMap((res: ApiResponse) => {
                        if (res.code === 200) {
                            if (res && res.data) {
                                res.data.fromScreen = 'signup';
                                res.data.tag = '';
                                this.cleverTap.eventSignUp({ intent: 'sign-up' });
                                // this.extraEdge.manipulateData({ mobile: payloadToPass.mobile }, 'verify-otp');
                                this.callGTMevent('verify-otp');
                            }
                            localStorage.setItem('authToken', res.data.authToken);
                            this.loginEventGATracking();
                            localStorage.removeItem('lastRequested');
                            return [
                                new AuthActions.SetToken(res.data.authToken),
                                new AuthActions.SetAuthentication(),
                                new UserProfileActionList.GetUserDetail(res.data),
                            ];
                        } else {
                            this.signUpService.otpApiError.next(res.message);
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        }
                    }),
                    catchError((error) => {
                        return [
                            {
                                type: AuthActions.FAIL
                            }
                        ];
                    })
                )
            } else {
                this.alert.showErrorMessage('Please enter correct OTP');
                return [
                    {
                        type: AuthActions.FAIL
                    }
                ];
            }
        }
        ),
    )

    // Registering the effect of verify mobile
    @Effect({ dispatch: false })
    verifyOtpEmail = this.action$.pipe(
        ofType<AuthActions.VerifyOtpEmail>(AuthActions.VERIFY_OTP_EMAIL),
        switchMap((authData) => this.http.post('verifyOtp', false, authData).pipe(
            map((res: ApiResponse) => {
                if (res.code === 200) {
                    localStorage.removeItem('lastRequested');
                }
            }),
            catchError((error) => {
                return [
                    {
                        type: AuthActions.FAIL
                    }
                ];
            })
        )
        ),
    )

    // Registering effects for starting signup
    @Effect({ dispatch: false })
    authSignup = this.action$.pipe(
        ofType<AuthActions.Signup>(AuthActions.SIGNUP),
        map((action: AuthActions.Signup) => {
            return action.payload;
        }),
        switchMap(
            (authData) => {
                if (authData.referralCode === '') {
                    // delete authData.referralCode;
                }
                authData = JSON.parse(JSON.stringify(authData));
                const dataToSend = JSON.parse(JSON.stringify(authData));
                delete authData.str;
                delete authData.className;
                return this.http.post('v4/signup', false, authData).pipe(
                    map(
                        (res: ApiResponse) => {
                            if (res.code === 200) {
                                this.signUpService.signUpVerified.next({ val: true, str: dataToSend.str });
                                this.cleverTap.pushWindowProfile(dataToSend);
                                this.cleverTap.formSubmission({ intent: 'sign up' });
                                // this.extraEdge.manipulateData(dataToSend, 'sign-up');
                                this.alert.showSuccessMessage(res.message);
                            } else {
                                this.signUpService.resetCaptcha.next(true);
                                this.alert.showErrorMessage(res.message);
                                if (res.message == 'This number is already registered! Please login.') {
                                    this.router.navigateByUrl('/');
                                }
                            }
                        }
                    )
                );
            }
        )
    )

    @Effect()
    socialSignup = this.action$.pipe(
        ofType(AuthActions.SOCIAL_SIGNUP),
        map((action: AuthActions.SocialSignup) => {
            return action.payload;
        }),
        switchMap(
            (obj: SocialSignup) => {
                const dataToSend = {
                    socialToken: obj.authToken,
                    osType: 'web',
                    device: 'desktop'
                };
                const apiUrl = 'v4/login/social/' + obj.provider.toLowerCase();
                return this.http.post(apiUrl, false, dataToSend, true).pipe(
                    mergeMap((res: ApiResponse) => {
                        if (res.code === 200) {
                            if (res && res.data) {
                                res.data.fromScreen = 'signup';
                                res.data.tag = '';
                                localStorage.removeItem('lastRequested');
                            }
                            localStorage.setItem('authToken', res.data.authToken);
                            this.loginEventGATracking();
                            return [
                                new AuthActions.SetToken(res.data.authToken),
                                new AuthActions.SetAuthentication(),
                                new UserProfileActionList.GetUserDetail(res.data),
                            ];
                        } else {
                            this.alert.showErrorMessage(res.message);
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        }
                    }),
                    catchError((error) => {
                        return [
                            {
                                type: AuthActions.FAIL
                            }
                        ];
                    })
                );
            }
        )
    )

    @Effect()
    socialSignin = this.action$.pipe(
        ofType(AuthActions.SOCIAL_SIGNIN),
        map((action: AuthActions.SocialSignin) => {
            return action.payload;
        }),
        switchMap(
            (obj: SocialSignup) => {
                const dataToSend = {
                    socialToken: obj.authToken,
                    osType: 'web',
                    device: 'desktop'
                };
                const apiUrl = 'v4/login/social/' + obj.provider.toLowerCase();
                return this.http.post(apiUrl, false, dataToSend, true).pipe(
                    mergeMap((res: ApiResponse) => {
                        if (res.code === 200) {
                            if (res && res.data) {
                                res.data.fromScreen = 'signin';
                                localStorage.removeItem('lastRequested');
                                res.data.tag = '';
                            }
                            localStorage.setItem('authToken', res.data.authToken);
                            this.loginEventGATracking();
                            return [
                                new AuthActions.SetToken(res.data.authToken),
                                new AuthActions.SetAuthentication(),
                                new UserProfileActionList.GetUserDetail(res.data),
                            ];
                        } else {
                            this.alert.showErrorMessage(res.message);
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        }
                    }),
                    catchError((error) => {
                        return [
                            {
                                type: AuthActions.FAIL
                            }
                        ];
                    })
                );
            }
        )
    )

    // Registering effects for starting signin
    @Effect()
    authSignin = this.action$.pipe(
        ofType<AuthActions.Signin>(AuthActions.SIGNIN),
        map((action: AuthActions.Signin) => {
            return action.payload;
        }),
        switchMap(
            (authData) => {
                return this.http.post('login', false, authData, false).pipe(
                );
            }
        )
    )

    // Registering the effect of Signin action
    @Effect({ dispatch: false })
    processAfterSignIn = this.action$.pipe(
        ofType<AuthActions.ProcessAfterSignin>(AuthActions.PROCESS_AFTER_SIGNIN),
        tap(() => {
            this.router.navigate(['/complete-profile']);
        })
    );

    // Registering the effect of Logout action
    @Effect()
    logout = this.action$.pipe(
        ofType<AuthActions.Logout>(AuthActions.LOGOUT),
        map((action: AuthActions.Logout) => {
        }),
        switchMap(
            (authData) => {
                return this.http.post('v4/auth/logout', true, undefined, true, false, false, environment.serverURLv4).pipe(
                    mergeMap((res: ApiResponse) => {
                        if (res.code === 200) {
                            let learnDarkMode = localStorage.getItem('isDarkmodeforid');
                            localStorage.clear();
                            localStorage.setItem('isDarkmodeforid', learnDarkMode)
                            sessionStorage.clear();
                            $('#logoutModal').modal('hide');
                            $('.modal-backdrop').remove();
                            $('body').removeClass('modal-open');
                            this.alert.showSuccessMessage(res.message);
                            this.router.navigate(['/']);
                            return [
                                {
                                    type: AuthActions.LOGOUT_SUCCESS
                                }
                            ];
                        } else {
                            this.alert.showErrorMessage(res.message);
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        }
                    }),
                    catchError((error) => {
                        return [
                            {
                                type: AuthActions.FAIL
                            }
                        ];
                    })
                );
            }
        )
    )

    @Effect()
    getProfileData = this.action$.pipe(
        ofType(AuthActions.GET_PROFILE_DATA),
        map((action: AuthActions.GetProfileData) => {
            return;
        }),
        switchMap(
            () => {
                return this.http.get('profile', true, null, false),
                    catchError((error) => {
                        return [
                            {
                                type: AuthActions.FAIL
                            }
                        ];
                    }),
                    mergeMap(
                        (res: ApiResponse) => {
                            if (res.code === 200) {
                                // get profile data
                                const obj = res.data;
                                this.profileDataResponse = res.data;
                            } else {
                                return [
                                    {
                                        type: AuthActions.FAIL
                                    }
                                ];
                            }
                        }
                    );
            }
        )
    )

    @Effect()
    FiiJeeMyPatLogin = this.action$.pipe(
        ofType(AuthActions.FIITJEE_MYPAT_LOGIN),
        map((action: AuthActions.FiiJeeMyPatLogin) => {
            return action.payload;
        }),
        switchMap(
            (authData) => {
                let dataToSend = {};
                if (authData.type === 'mypat') {
                    dataToSend = {
                        password: authData.password,
                        enrollmentno: authData.enrollmentno,
                        source: 'web',
                        deviceToken: ''
                    };
                    if (localStorage.getItem('userIdcheckEn')) {
                        dataToSend['userId'] = localStorage.getItem('userIdcheckEn');
                    }

                }
                else {
                    dataToSend = {
                        password: authData.password,
                        enrollmentno: authData.enrollmentno,
                        source: 'web',
                        deviceToken: ''
                    };
                }
                dataToSend = {
                    ...dataToSend,
                    fingerprintId: this.signUpService?.getFingerprintID(),
                    authenticateUserDevice: true,
                    deviceType: 'desktop',
                    logoutActiveDevice: authData.logoutActiveDevice || false,
                }
                return this.http.post('partnerLogin', false, dataToSend, false, false, false, environment.serverURLv3).pipe(
                    mergeMap((res: ApiResponse) => {
                        if (res.code === 200 || res.code === 201) {
                            localStorage.setItem('authToken', res.data.authToken);
                            this.loginEventGATracking();
                            return [
                                new AuthActions.SetToken(res.data.authToken),
                                new AuthActions.SetAuthentication(),
                                new UserProfileActionList.GetUserDetail(res.data),
                            ];
                        } else if (res.code === 408) {
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        } else if (res.code === 460) {
                            this.fiitJee.disableBtnSubject.next(false);
                            return [
                                new AuthActions.AlreadyLoggedIn(res.data),
                            ];
                            
                        } else if (res.code === 461) {
                            return [
                                new AuthActions.UnregisterDevice(res.data),
                            ];
                            
                        } else {
                            this.alert.showErrorMessage(res.message);
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        }
                    }),
                    catchError((error) => {
                        return [
                            {
                                type: AuthActions.FAIL
                            }
                        ];
                    }),
                );
            }
        )
    )

    @Effect()
    OtpLoginFiitjee = this.action$.pipe(
        ofType(AuthActions.OTP_LOGIN_FIITJEE),
        map((action: AuthActions.OtpLoginFiitjee) => {
            return action.payload;
        }),
        switchMap(
            (authData) => {
                const dataToSend = {
                    enrollmentno: authData.enrollmentno,
                    otp: authData.otp,
                    source: 'web',
                    deviceToken: '',
                    userId: authData.userId,
                    fingerprintId: this.signUpService.getFingerprintID(),
                    authenticateUserDevice: true,
                    deviceType: 'desktop',
                    logoutActiveDevice: authData.logoutActiveDevice || false,
                };
                if (dataToSend.otp) {
                    return this.http.post('otpPartner', false, dataToSend, false, false, false, environment.serverURLv3).pipe(
                        mergeMap((res: ApiResponse) => {
                            if (res.code === 200 || res.code === 201) {
                                localStorage.setItem('authToken', res.data.authToken);
                                this.loginEventGATracking();
                                sessionStorage.removeItem('otpResendCount');
                                return [
                                    new AuthActions.SetToken(res.data.authToken),
                                    new AuthActions.SetAuthentication(),
                                    new UserProfileActionList.GetUserDetail(res.data),
                                ];
                            } else if (res.code === 408) {
                                return [
                                    {
                                        type: AuthActions.FAIL
                                    }
                                ];
                            } else if (res.code === 460) {
                                return [
                                    new AuthActions.AlreadyLoggedIn(res.data),
                                ];
                                
                            } else if (res.code === 461) {
                                return [
                                    new AuthActions.UnregisterDevice(res.data),
                                ];
                                
                            } else {
                                this.alert.showErrorMessage(res.message);
                                return [
                                    {
                                        type: AuthActions.FAIL
                                    }
                                ];
                            }
    
                        }),
                        catchError((error) => {
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        }),
                    );
                } else {
                    return [
                        {
                            type: AuthActions.FAIL
                        }
                    ];
                }
            }
        )
    )

    @Effect()
    WACLoginFiitjee = this.action$.pipe(
        ofType(AuthActions.WAC_LOGIN_FIITJEE),
        map((action: AuthActions.WACLoginFiitjee) => {
            return action.payload;
        }),
        switchMap(
            (authData) => {
                const dataToSend = {
                    enrollmentno: authData.enrollmentno,
                    wac: authData.wac,
                    source: 'web',
                    deviceToken: '',
                    fingerprintId: this.signUpService.getFingerprintID(),
                    authenticateUserDevice: true,
                    deviceType: 'desktop',
                    logoutActiveDevice: authData.logoutActiveDevice || false,
                };
                if (dataToSend.wac) {
                    return this.http.post('wacPartner', false, dataToSend, false, false, false, environment.serverURLv3).pipe(
                        mergeMap((res: ApiResponse) => {
                            if (res.code === 200 || res.code === 201) {
                                localStorage.setItem('authToken', res.data.authToken);
                                this.loginEventGATracking();   
                                return [
                                    new AuthActions.SetToken(res.data.authToken),
                                    new AuthActions.SetAuthentication(),
                                    new UserProfileActionList.GetUserDetail(res.data),
                                ];
                            } else if (res.code === 408) {
                                return [
                                    {
                                        type: AuthActions.FAIL
                                    }
                                ];
                            } else if (res.code === 460) {
                                return [
                                    new AuthActions.AlreadyLoggedIn(res.data),
                                ];
                                
                            } else if (res.code === 461) {
                                return [
                                    new AuthActions.UnregisterDevice(res.data),
                                ];
                                
                            } else {
                                this.alert.showErrorMessage(res.message);
                                return [
                                    {
                                        type: AuthActions.FAIL
                                    }
                                ];
                            }
    
                        }),
                        catchError((error) => {
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        }),
                    );
                } else {
                    return [
                        {
                            type: AuthActions.FAIL
                        }
                    ];
                }
            }
        )
    )

    @Effect()
    ResetPasswordFiitJee = this.action$.pipe(
        ofType(AuthActions.RESET_PASSWORD_FIITJEE),
        map((action: AuthActions.ResetPasswordFiitJee) => {
            return action.payload;
        }),
        switchMap(
            (authData) => {
                let dataToSend;

                if (this.fiitJee.saveOtp) {
                    dataToSend = {
                        userId: authData.userId,
                        password: authData.password,
                        enrollmentno: authData.enrollmentno,
                        otp: this.fiitJee.saveOtp,
                        source: 'web',
                        deviceToken: ''
                    }
                }
                else if (this.fiitJee.savePassword) {
                    dataToSend = {
                        userId: authData.userId,
                        password: authData.password,
                        enrollmentno: authData.enrollmentno,
                        fjpassword: this.fiitJee.savePassword,
                        source: 'web',
                        deviceToken: ''
                    }
                }
                else if (this.fiitJee.saveWac) {
                    dataToSend = {
                        userId: authData.userId,
                        password: authData.password,
                        enrollmentno: authData.enrollmentno,
                        wac: this.fiitJee.saveWac,
                        source: 'web',
                        deviceToken: ''
                    }
                }
                return this.http.put('mypatLogin', false, null, false, environment.serverURLv3).pipe(
                    mergeMap((res: ApiResponse) => {
                        if (res.code === 200 || res.code === 201) {
                            localStorage.setItem('authToken', res.data.authToken);
                            this.loginEventGATracking();
                            return [
                                new AuthActions.SetToken(res.data.authToken),
                                new AuthActions.SetAuthentication(),
                                new UserProfileActionList.GetUserDetail(res.data),
                            ];
                        } else if (res.code === 408) {
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        } else {
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        }

                    }),
                    catchError((error) => {
                        return [
                            {
                                type: AuthActions.FAIL
                            }
                        ];
                    }),
                );
            }
        )
    )

    callGTMevent(str): void {
        switch (str) {
            case 'verify-otp': {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'mobileSignup',
                });
            }
        }
    }

    isLoginEvenTriggered = false;

    loginEventGATracking(){
        this.store.select('userProfile').subscribe((userProfile) => {
            const userData: any = userProfile.userData || {};
            if(userData.fiitjeeEnrollmentNumber && !this.isLoginEvenTriggered){
                this.isLoginEvenTriggered = true;
                this.pushGTMTag('page_view');
                this.pushGTMTag('custom_event', 'login', '/');
            }
          });
        
       }

       pushGTMTag(event, path = '', url = ''){
        let gtmTag: any = {
          event: event
        };
        if(event === 'custom_event'){
          gtmTag = {
            event: 'custom_event',
            custom_event_name: path,
            url: url,
          };
        }
        gtmTag.userId = window.localStorage.getItem('enrollmentNo') || 'undefined';
          const centreIds =
            window.localStorage.getItem('centres')?.split(',') || [];
          gtmTag.centre1 = centreIds[0] || 'undefined';
          gtmTag.centre2 = centreIds[1] || 'undefined';
          const batchIds = window.localStorage.getItem('batches')?.split(',') || [];
          gtmTag.batch1 = batchIds[0] || 'undefined';
          gtmTag.batch2 = batchIds[1] || 'undefined';
          gtmTag.batch3 = batchIds[2] || 'undefined';
        this.gtmService.pushTag(gtmTag);
        setTimeout(() => {
          window.location.reload();
        },400);
      }
      
}



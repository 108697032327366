import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelpService } from 'src/app/help-and-support/services/help.service';
import { HomeService } from 'src/app/home-page/services/home.service';
import _ from 'lodash';

declare var $: any;
@Component({
  selector: 'app-help-feedback',
  templateUrl: './help-feedback.component.html',
  styleUrls: ['./help-feedback.component.scss']
})
export class HelpFeedbackComponent implements OnInit {
  @Input() public currentHelpObj: any;
  @Input() public helpData: any;
  @Input() type:any;
  @ViewChild('feedbackInput') feedbackInput : ElementRef; 
  public dataToPassForUserCards: any;
  public helpFeedBack: any;
  public lessThanThree: boolean = false;
  public descriptionError : boolean = false;
  selectedValue = 0;
  stars: number[] = [1, 2, 3, 4, 5];
  showDates = false;
  errorMsg = false;
  feedBackObj = {
    issueId: '',
    rating: 1,
    message: '',
    date: new Date(),
  };
  public selectedType:any;
  public descriptionLength = 0;
  public isMobile: boolean = false;
  public isTab:boolean = false;
  public selectedCategory: any;
  public ratingError : boolean = false;
  public hideDescriptionlength : boolean = true;
  constructor(private helpServices: HelpService, private router: Router, private homePageService: HomeService) {
    let regexForTab = new RegExp('Chrome\/[.0-9]* (?!Mobile)');
    let regexForMobile = new RegExp('Chrome/[.0-9]* Mobile');
    if (navigator.userAgent.includes('Android')) {
      if (regexForTab.test(navigator.userAgent)) {
        this.isMobile = false;
        this.isTab = true;
      } else if (regexForMobile.test(navigator.userAgent)) {
        this.isMobile = true;
        this.isTab = false;
      }
    } else if (navigator.userAgent.includes('iPhone')) {
      this.isMobile = true;
      this.isTab = false;
    } else if (navigator.userAgent.includes('iPad')) {
      this.isMobile = false;
      this.isTab = true;
    } else {
      this.isMobile = false;
      this.isTab = false;
    }
   }

  ngOnInit(): void {
  }
@ViewChild('feedBackContainer') feedBackContainer : ElementRef;
  onSubmit() {
    this.feedBackObj.rating = this.selectedValue;
    this.feedBackObj.issueId = this.currentHelpObj._id;
    this.feedBackObj.message = this.currentHelpObj.feedback.message;
    this.feedBackObj.message = this.feedBackContainer.nativeElement.value;
    this.feedBackObj.date = new Date();
    this.descriptionError = false;
    if(this.feedBackObj.rating === 0){
      this.ratingError = true;
      return;
    }
    if(this.feedBackObj.rating !== 0 && (this.feedBackObj.rating <= 3 && this.descriptionLength >= 40)){
      this.descriptionError = false;
      this.hideDescriptionlength = false;
    }
    if(this.feedBackObj.rating !== 0 && (this.feedBackObj.rating <= 3 && this.descriptionLength < 40)){
      this.descriptionError = true;
      this.hideDescriptionlength = false;
      return;
    }
    else {
      this.descriptionError = false;
    }
    this.helpServices.getHelpFeedBack(this.feedBackObj).then((res) => {
      (res) => {
        if (res.code === 200) {
      this.currentHelpObj.feedback.rating = this.selectedValue;
      this.currentHelpObj.feedback.message = this.feedBackObj.message;
      this.currentHelpObj.feedback.createdAt = new Date();
      
        }
      }
    })
    this.helpServices.feedbackModalClose.next(true);
    $(".popup").fadeOut(50);
  }



  initFeedBack(helpReport) {
    this.selectedValue = 0;
    this.showDates = true;
    this.currentHelpObj = helpReport;
    if (this.currentHelpObj?.feedback) {
      this.feedBackObj.rating = this.currentHelpObj.feedback.rating || this.selectedValue;
      this.feedBackObj.message = this.currentHelpObj.feedback.message;
      this.selectedValue = this.feedBackObj.rating;
    } else {
      this.feedBackObj.rating = 0;
      this.feedBackObj.message = '';
      this.selectedValue = this.feedBackObj.rating;
    }
    this.descriptionLength = this.feedBackObj.message.replace(/ /g,'').length;
    this.feedbackInput.nativeElement.focus()
    setTimeout(()=>{
      this.feedbackInput.nativeElement.scrollTop = this.feedbackInput.nativeElement.scrollHeight;
    })
  }

  onKeyUp() {
    const count = this.feedBackObj.message.replace(/\r?\n|\r/g, "");
    this.descriptionLength = count.replace(/ /g, '').length;
      this.descriptionError = false;
      this.hideDescriptionlength = true;
  }

  countStar(star) {
    this.ratingError = false;
    this.selectedValue = star;
    if (this.selectedValue <= 3) {
      this.lessThanThree = true;
    } else if (this.selectedValue > 3) {
      this.lessThanThree = false;
      this.descriptionError = false;
    }
  }

  get getPlaceholderText(){
    let text = 'Rate us and Share your thoughts!';
    switch (this.selectedValue) {
      case 1: {
        text = 'Tell us what can we do better...'
        break;
      }
      case 2: {
        text = 'Tell us what can we do better...'
        break;
      } 
      case 3: {
        text = 'Tell us your suggestions...'
        break;
      } 
      case 4: {
        text = 'Tell us what you liked...'
        break;
      } 
      case 5: {
        text = 'Tell us what you loved...'
        break;
      }
    }
    return text;
  }

  addClass(star): void {
    let ab = '';
    for (let i = 0; i < star; i++) {
      ab = 'starId' + i;
      document.getElementById(ab).classList.add('activeStar');
    }
  }
  removeClass(star): void {
    let ab = '';
    for (let i = star - 1; i >= this.selectedValue; i--) {
      ab = 'starId' + i;
      document.getElementById(ab).classList.remove('activeStar');
    }
  }

  getFormattedDate(date): string {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let currentDatetime = new Date(date)
    return (currentDatetime.getDate() + " " + months[currentDatetime.getMonth()] + " " + currentDatetime.getFullYear())
  }

  getTicketStatusWithDate(): string {
    let result = '';
    let date1;
    let date2;
    let index = this.currentHelpObj.reopenedTickets.length -1
    const len = _.get(this.currentHelpObj, 'reopenedTickets.length', 0);
    const reportedOn =  this.getFormattedDate(this.currentHelpObj.createdAt);
    const pickedAt = this.currentHelpObj?.actionDates?.pickedAt ? this.getFormattedDate(this.currentHelpObj?.actionDates?.pickedAt) : '';
    const originalResolution = len ? this.getFormattedDate(this.currentHelpObj.resolvedAt) : '';
    const reopenedOn = len ? this.getFormattedDate(this.currentHelpObj.reopenedTickets[index].createdAt) : '';
    const resolvedOn = (this.currentHelpObj.status === 'resolved') ? len > 0
                        ? this.currentHelpObj.reopenedTickets[index].resolvedAt != undefined ?this.getFormattedDate(this.currentHelpObj.reopenedTickets[index].resolvedAt) :this.getFormattedDate(this.currentHelpObj.reopenedTickets[index].closedAt)
                        : this.getFormattedDate(this.currentHelpObj.resolvedAt) : '' ;
    const closedAt =  (this.currentHelpObj.status === 'closed') ? len > 0
                        ? this.getFormattedDate(this.currentHelpObj.reopenedTickets[index].closedAt) : this.getFormattedDate(this.currentHelpObj.closedAt)
                        : '' ;
    let date5 = this.currentHelpObj.type === "issue" && this.currentHelpObj.reopenedTickets.length > 0? this.currentHelpObj.reopenedTickets[index]?.actionDates?.find(item => item.type == 'pickedAt')?.date:
    this.currentHelpObj.actionDates?.find(item => item.type == 'pickedAt')?.date;
    switch (this.currentHelpObj?.status) {
      case 'open':
        result = '<span>this.currentHelpObjed on: ' + reportedOn + '</span>';
        break;
      case 'ongoing':
        result = '<span>Reported on: <b>' + reportedOn + '</b></span>' + '<span>Picked on: <b>' + this.getFormattedDate(date5) + '</b></span>';
        break;
      case 'closed':
        if (this.type === 'idea'){
          const closedDate = this.currentHelpObj.actionDates?.find(item => item.type == 'closedAt')?.date; 
          result = '<span>Submitted on: <b>' + reportedOn + '</b></span> ' + '</b></span>' + '<span>Closed on: <b>' + this.getFormattedDate(closedDate) + '</b></span>';
        } else {
          if (this.currentHelpObj.reopenedTickets.length) {
            result = '<span>Re-opened on: <b>' + reopenedOn + '</b></span> ' + '<span>Picked on: <b>' + this.getFormattedDate(date5) + '</b></span>' + '<span>Closed on: <b>' + closedAt + '</b></span>';
          } else {
            result = '<span>Reported on: <b>' + reportedOn + '</b></span> ' + '<span>Picked on: <b>' + this.getFormattedDate(date5) + '</b></span>' + '<span>Closed on: <b>' + closedAt + '</b></span>';
          }
        }
        break;
      case 'resolved':
        if (this.currentHelpObj.reopenedTickets.length) {
        result = '<span>Re-opened on: <b>' + reopenedOn + '</b></span> ' + '<span>Picked on: <b>' + this.getFormattedDate(date5) + '</b></span>' + '<span>Resolved on: <b>' + resolvedOn + '</b></span>';
        } else {
          result = '<span>Reported on: <b>' + reportedOn + '</b></span> ' + '<span>Picked on: <b>' + this.getFormattedDate(date5) + '</b></span>' + '<span>Resolved on: <b>' + resolvedOn + '</b></span>';
        }
        break;
      case 'accepted':
        date2 = this.currentHelpObj.actionDates?.find(item => item.type == 'acceptedAt')?.date;
        result = '<span>Submitted on: <b>' + reportedOn + '</b></span> ' + '<span>Accepted on: <b>' + this.getFormattedDate(date2) + '</b></span>';
        break;
      case 'submitted':
        date1 = this.currentHelpObj.actionDates?.find(item => item.type == 'createdAt')?.date;
        result = '<span>Submitted on: ' + reportedOn + '</span> '
        break;
      case 'completed':
        date2 = this.currentHelpObj.actionDates?.find(item => item.type == 'completedAt')?.date;
        let date4 = this.currentHelpObj.actionDates?.find(item => item.type == 'acceptedAt')?.date;
        result = '<span>Submitted on: <b>' + reportedOn + '</b></span> ' + '<span>Accepted on: <b>' + this.getFormattedDate(date4) + '</b></span> ' + '<span>Picked on: <b>' + this.getFormattedDate(date5) + '</b></span> ' + '<span>Completed on: <b>' + this.getFormattedDate(date2) + '</b></span>';
        break;
      default:
        break;
    }
  
  
    return result;
  }

  closeFeedBack() {
    setTimeout(() => {
      $(".popup").css('display', 'none');
    }, 200);
    $(".popup .modal-dialog").removeClass('activePop');
    this.lessThanThree = false;
    this.feedBackObj.rating = 0;
    this.feedBackObj.message = '';
    this.selectedValue = this.feedBackObj.rating;
    this.descriptionError = false;
  }

  getCategoriesName(report): any {
    let listingItem = [];
    if (report) {
      const result = report.categories.reduce((acc, curr) => {
        if (curr.subCategories.length > 0) {
          curr.subCategories.forEach((sub) => {
            acc.push(sub.name);
          });
        } else {
          acc.push(curr.name);
        }
        return acc;
      }, []);
      this.selectedType = result;
      if (result.length <= 3) {
        listingItem = result;
      }
      if (result.length > 3 && !this.isMobile && !this.isTab) {
        this.selectedCategory = result.slice(3, result.length)
        listingItem = result.slice(0, 3);
      }
      if (result.length > 2 && !this.isMobile && this.isTab) {
        this.selectedCategory = result.slice(2, result.length)
        listingItem = result.slice(0, 2);
      }
      if (result.length > 1 && this.isMobile && !this.isTab) {
        this.selectedCategory = result.slice(1, result.length)
        listingItem = result.slice(0, 1);
      }
      let finalList = '';
      listingItem.forEach((res) => {
        finalList += '<span>' + res + '</span>'
      })
      return finalList;
    }
    return [];
  }
  
  getNumber(data: any) {
    let number = 0;
    if (data?.categoriesObj.length > 3) {
      if (!this.isMobile && !this.isTab) {
        number = this.selectedType.length - 3;
      }
    }
    if (data?.categoriesObj.length > 2) {
      if (!this.isMobile && this.isTab) {
        number = this.selectedType.length - 2;
      }
    }
    if (data?.categoriesObj.length > 1) {
      if (this.isMobile && !this.isTab) {
        number = this.selectedType.length - 1;
      }
    }
    return number;
  }

  checkNumberShow(data){
    let showNumber = false;
    if (!this.isMobile && !this.isTab) {
      if(data?.categoriesObj.length > 3){
        showNumber = true;
      }
     }
    if (!this.isMobile && this.isTab) {
      if(data?.categoriesObj.length > 2){
        showNumber = true;
      }
    }
    if (this.isMobile && !this.isTab) {
      if(data?.categoriesObj.length > 1){
        showNumber = true;
      }
    }
    return showNumber;
  }

  submitDisable() {
    let valueToReturn = true;
    if (this.selectedValue <= 3 && (this.descriptionLength >= 40)) {
      valueToReturn = false;
    }
    if (this.selectedValue === 0) {
      valueToReturn = true;
    }
    if (this.selectedValue > 3) {
      valueToReturn = false;
    }
    return valueToReturn;
  }
  // onFocusDescription(){
  //   this.descriptionError=false;
  //   this.hideDescriptionlength = true;
  // }
}


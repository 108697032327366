import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as AuthActions from '../../../stores/auth/auth.actions';
import * as fromApp from '../../../stores/app.reducers';
import { Store } from '@ngrx/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-logout-confirm',
  templateUrl: './logout-confirm.component.html',
  styleUrls: ['./logout-confirm.component.scss']
})
export class LogoutConfirmComponent implements OnInit {

  @Output() closeLogoutPopup = new EventEmitter<any>();
  constructor(
    private store: Store<fromApp.AppState>,
    private gtmService: GoogleTagManagerService
    ) { }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.closeLogoutPopup.emit();
  }

  logout(): void {
    const gtmTag: any = {
      event: 'custom_event',
      custom_event_name: 'logout',
    };
    gtmTag.userId = window.localStorage.getItem('enrollmentNo') || 'undefined';
    const centreIds = window.localStorage.getItem('centres')?.split(',') || [];
    gtmTag.centre1 = centreIds[0] || 'undefined';
    gtmTag.centre2 = centreIds[1] || 'undefined';
    const batchIds = window.localStorage.getItem('batches')?.split(',') || [];
    gtmTag.batch1 = batchIds[0] || 'undefined';
    gtmTag.batch2 = batchIds[1] || 'undefined';
    gtmTag.batch3 = batchIds[2] || 'undefined';
    this.gtmService.pushTag(gtmTag);
    this.store.dispatch(new AuthActions.Logout());
  }

}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  Inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HelpService } from 'src/app/help-and-support/services/help.service';
import { ViewEncapsulation } from '@angular/core';
import { HelpFeedbackComponent } from '../help-feedback/help-feedback.component';
import { Subscription } from 'rxjs/Subscription';
import { HelpFilterService } from 'src/app/help-and-support/help-filter/help-filter.service';
import { HelpFilterModel } from 'src/app/help-and-support/help-filter/help-filter.model';
import { HelpLightBoxComponent } from '../help-light-box/help-light-box.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import _ from 'lodash';
import { AlertService } from '../../services/alert.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../stores/app.reducers';
import { DatePipe } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-help-report-list',
  templateUrl: './help-report-list.component.html',
  styleUrls: ['./help-report-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HelpReportListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public maxPageSize: any;
  @Input() public issueType: any;
  @Input() public applyfilters: any;
  @Input() public status: any;
  @Input() public searchText: string;
  public helpData: any;
  private currImgcount = 0;
  private totalImgCount = 0;
  public imageShimmer: boolean = true;
  helpIssueType: any;
  currentHelpObj: any;

  subscription: Subscription;
  helpDataFiltered = [];
  tid = '';
  showLoader = true;
  helpStatus: string;

  public isMobile: boolean = false;
  public isMobileFeedback: boolean = false;
  public showReopenPopUp: boolean = false;
  public currentIssueId: string = '';
  @ViewChild(HelpFeedbackComponent)
  helpFeedbackComponent: HelpFeedbackComponent;
  @ViewChild(HelpLightBoxComponent)
  helpLightBoxComponent: HelpLightBoxComponent;
  @Output() openFeedbackPopupHelp = new EventEmitter<any>();
  showTimelinePopUp: boolean = false;
  public currentTimelineData: any = [];
  public filterData: any = [];
  public filterDataEmpty: boolean = false;
  public collapseId: any;
  public isTab: boolean = false;
  public payload: any;
  public notificationPayloadVisited: boolean = false;
  public showCommentModal: boolean = false;
  public displayComments = new Map();
  public photoUrl: string;
  public commentId: string;
  public lastSearchText = '';
  public userInitials = '';
  public isSearchTriggered = false;
  public displayStatus = '';
  public infoPopUpData: any = {};
  public initialsClass;
  public permanentTid = '';
  @Output() statusCountData = new EventEmitter<any[]>();
  @Output() helpListData = new EventEmitter<any[]>();
  public notificationTid: any;
  public currentIssueIndex = -1;
  constructor(
    @Inject(DOCUMENT) private currentDocument: Document,
    private helpServices: HelpService,
    private helpFilterService: HelpFilterService,
    private activatedRoute: ActivatedRoute,
    private alert: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private datePipe:DatePipe
  ) {
    let regexForTab = new RegExp('Chrome\/[.0-9]* (?!Mobile)');
    let regexForMobile = new RegExp('Chrome/[.0-9]* Mobile');
    if (navigator.userAgent.includes('Android')) {
      if (regexForTab.test(navigator.userAgent)) {
        this.isMobile = false;
        this.isTab = true;
      } else if (regexForMobile.test(navigator.userAgent)) {
        this.isMobile = true;
        this.isTab = false;
      }
    } else if (navigator.userAgent.includes('iPhone')) {
      this.isMobile = true;
      this.isTab = false;
    } else if (navigator.userAgent.includes('iPad')) {
      this.isMobile = false;
      this.isTab = true;
    } else {
      this.isMobile = false;
      this.isTab = false;
    }

    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        setTimeout(() => {
          this.router.navigate([], {
            queryParams: { tid: null },
            queryParamsHandling: 'merge',
          });
          if (this.notificationTid || this.notificationTid === null) {
            this.tid = this.permanentTid;
            this.notificationTid = null
          }
        },2000)
      }
    })
  }

  ngOnInit(): void {
    this.helpServices.isModalClosed.subscribe((res) => {
      if (res) {
         this.loadData();
      }
    });
    this.notificationTid = this.getParameterByName('tid')
    this.showLoader = true;
    this.route.queryParams.subscribe((params) => {
      if (params.status) {
        this.helpStatus = params.status;
        const status = this.helpStatus === 'open' || this.filterData.status == 'pending' ? ['open', 'reopened'] : [this.helpStatus];
        this.payload = {
          pageNumber: 1,
          pageSize: 1000,
          pagination: true,
          status: status,
          type: [
            "issue"
          ],
          categoryFilter: {
            issue: {
              categories: []
            },
          }
        }
        this.alert.helpNotifiStatus.next(this.helpStatus);
        if (this.helpStatus === 'resolved') {
          setTimeout(() => {
            this.loadData();
          }, 200);
        } else {
          this.loadData();
        }
      }
      if (params.tid) {
        this.permanentTid = params.tid
        this.tid = params.tid;
        this.notificationPayloadVisited = false;
        setTimeout(() => {
          if (this.notificationTid === null) {
            this.loadData();
          }
        }, 500);
      }
    });
    if (!this.tid && !this.helpStatus) {
      this.payload = {
        pageNumber: 1,
        pageSize: 1000,
        pagination: true,
        type: [
          "issue"
        ],
        categoryFilter: {
          issue: {
            categories: []
          },
        }
      }
    }
    this.helpServices.UpdatedDataHelp.subscribe((res) => {
      if (res && res.type) {
        this.loadData();
      }
    });
    if (!this.tid && !this.helpStatus) {
      this.loadData();
    }
    this.store.select('userProfile').subscribe((userProfile) => {
      if (userProfile.userData) {
        this.photoUrl = userProfile.userData.photoUrl;
        this.userInitials = userProfile.userData?.initials[0] + userProfile.userData?.initials[1];
        this.initialsClass = this.getInitialsClass();
      }
    });

    this.getInfoPopupData();
  }

  getInitialsClass(): string {
    const classArray = ['alphabet-bg1', 'alphabet-bg2', 'alphabet-bg3', 'alphabet-bg4', 'alphabet-bg5'];
    const randomNumber = Math.floor(Math.random() * classArray.length);
    return classArray[randomNumber];
  }

  filteringData() {
    if (this.searchText !== this.lastSearchText){
      this.lastSearchText = this.searchText;
      if ((this.searchText?.trim()?.length >= 2 && parseInt(this.searchText?.trim()) > 0) || this.searchText?.trim()?.length >= 4){
        this.isSearchTriggered = true;
        this.loadData();
      } else {
        if (this.isSearchTriggered){
          this.isSearchTriggered = false;
          this.loadData();
        }
      }
    }
    else {

      if (this.filterData) {
        let issueTypes = [];
        if (this.filterData.issueTypes && this.filterData.issueTypes.length > 0) {
          this.filterData.issueTypes.forEach(element => {
            const obj = {
              name: element,
              isSelected: true
            }
            issueTypes.push(obj)
          });
        } else {
          issueTypes = [];
        }
        if (this.filterData.status != '' && this.filterData.dateFrom === '' && this.filterData.dateTo === '') {
          const status = this.filterData.status === 'open' || this.filterData.status == 'Pending' ? ['open', 'reopened'] :
            [this.filterData.status.toLowerCase()];
          this.payload = {
            pageNumber: 1,
            pageSize: 1000,
            pagination: true,
            status: status,
            type: [
              "issue"
            ],
            categoryFilter: {
              issue: {
                categories: issueTypes
              },
            }
          }
          this.loadData();
        } else if ((this.filterData.dateFrom != '' || this.filterData.dateTo != '') && this.filterData.status != '') {
          let startDateTemp = new Date(this.filterData.dateFrom)
          startDateTemp.setDate(startDateTemp.getDate()-1)
          this.payload = {
            pageNumber: 1,
            pageSize: 1000,
            pagination: true,
            status: [this.filterData.status.toLowerCase()],
            startDate: this.filterData.dateFrom ? this.datePipe.transform(startDateTemp,'yyyy-MM-dd') + 'T18:30:00.000Z' : '',
            endDate: this.filterData.dateTo ?  this.datePipe.transform(this.filterData.dateTo,'YYYY-MM-DD') + 'T18:29:59.000Z': '',
            type: [
              "issue"
            ],
            categoryFilter: {
              issue: {
                categories: issueTypes
              },
            }
          }
          this.loadData();
        } else {
          let startDateTemp = new Date(this.filterData.dateFrom)
          startDateTemp.setDate(startDateTemp.getDate()-1)
          this.payload = {
            pageNumber: 1,
            pageSize: 1000,
            pagination: true,
            startDate: this.filterData.dateFrom ? this.datePipe.transform(startDateTemp,'yyyy-MM-dd') + 'T18:30:00.000Z' : '',
            endDate: this.filterData.dateTo ? this.datePipe.transform(this.filterData.dateTo,'yyyy-MM-dd') + 'T18:29:59.000Z': '',
            type: [
              "issue"
            ],
            categoryFilter: {
              issue: {
                categories: issueTypes
              },
            }
          }
          if (!this.tid) {
            this.loadData();
          }
        }
        if (this.filterData.status) {
          this.tid = '';
        }
      } else {
        // clear messages when empty message received
        this.helpDataFiltered = this.helpData;
      }
      this.filterDataEmpty = !this.helpDataFiltered || !this.helpDataFiltered.length;
      if (this.helpDataFiltered && this.helpDataFiltered.length != this.helpData) {
        if (this.helpDataFiltered.length) {
          this.helpDataFiltered.forEach((res) => {
            if (this.collapseId && res.ticketId === this.collapseId.substring(9)) {
              if (document.getElementById(res.ticketId)) {
                const accordionOpen = document.getElementById(res.ticketId).getAttribute("aria-expanded");
                if (accordionOpen === 'true') {
                  document.getElementById(res.ticketId).setAttribute("aria-expanded", 'false');
                  $('#collapse' + res.ticketId).removeClass('show')
                  $('#collapse' + res.ticketId).addClass('collapse')
                }
              }
            }
          });
        }
      }
    }
  }
  redirectNotification() {
    const cardData = (JSON.parse(localStorage.getItem('openendCardDetails')));
    cardData.utilityCards?.data?.forEach(element => {
      if (element.type === "help") {
        cardData.selectedCard = element;
        localStorage.setItem('openendCardDetails', JSON.stringify(cardData));
      }
    });
    this.collapseId = `#collapse${this.tid}`;
    this.helpFilterService.clearFilters();
    this.scrollToNotificationElement();
  }

  getParameterByName(name, url = window.location.href): any {
    name = name.replace(/[\[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(url)
    if (!results) {
        return null
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

  scrollToNotificationElement() {
    setTimeout(() => {
      if (document.getElementById(this.tid)) {
        document.getElementById(this.tid).setAttribute("aria-expanded", 'true');
        document.getElementById('collapse' + this.tid).classList.remove('collapse');
        document.getElementById('collapse' + this.tid).classList.add('show');
        this.router.navigate([], {
          queryParams: { tid: null },
          queryParamsHandling: 'merge',
        });
        let element = document.getElementById(this.tid);
        let notificationListingTop = document.getElementById("notificationListingTop" + this.tid);
        $(notificationListingTop).addClass('spaceTop5');
        notificationListingTop.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
        this.helpDataFiltered.forEach((res, index) => {
          if (res.ticketId === this.tid) {
            this.helpStatus = res.status;
            this.helpServices.helpStatus.next(res.status);
            this.alert.helpNotifiStatus.next(this.helpStatus);
          }
        });
        this.showLastReopenIssue();
      }
      this.showLoader = false;
      setTimeout(() => {
        let scrollToElement = (document.getElementsByClassName('reportListsHelp') as HTMLCollection)[0];
        scrollToElement.scrollTop -= 4.5;
      }, 1000);
    }, 1200);
    // setTimeout(() => {
    //   this.notificationTid = '';
    // }, 1300)
  }

  ngOnChanges() {
    if (!this.tid  && !this.helpStatus) {
      this.filterData.status = this.status == 'All' ? [] : this.status;
      if (this.applyfilters) {
        this.filterData = this.applyfilters;
        if (this.applyfilters.status === 'Pending'){
          this.filterData.status = 'open';
        }
        this.filteringData();
      }
    } else if (this.status == this.applyfilters.status || (this.status == 'Pending' && this.applyfilters.status == 'open')) {
      this.filterData = this.applyfilters;
      this.filteringData();
    } else if (this.status === 'All' || (this.isMobile && this.helpStatus && this.applyfilters && !this.tid)) {
      this.filterData = this.applyfilters;
      this.filteringData();
    } 
  }
  openNotification() {
    this.showLoader = true;
    this.notificationPayloadVisited = true;
    const status = this.helpStatus === 'open' || this.helpStatus == 'reopened' ? ['open', 'reopened'] : [this.helpStatus];
    this.payload = {
      pageNumber: 1,
      pageSize: 1000,
      pagination: true,
      status: status,
      type: [
        "issue"
      ],
      categoryFilter: {
        issue: {
          categories: []
        },
      }
    }
    this.loadData();
  }

  expandSearchResults() {
    setTimeout(() => {
      //time to write the logic for search expansion
      this.helpDataFiltered.forEach(element => {
        let getComments = false;
        let foundInBody = false;

        const description = element.description.toLowerCase();
        if (description.includes(this.payload.searchQuery.toLowerCase())){
          foundInBody = true;
          getComments = true;
          this.getCommentData(element._id);
          // expanding the ticket
          if (document.getElementById(element.ticketId)) {
            document.getElementById(element.ticketId).setAttribute("aria-expanded", 'true');
            document.getElementById('collapse' + element.ticketId).classList.add('show');
          }

          //expanding the original Issue in ticket
          const newid = 'dataoriginal' + element.ticketId;
          if (document.getElementById(newid)) {
            document.getElementById(newid).setAttribute("aria-expanded", 'true');
            document.getElementById(newid).classList.add('show');
          }
          const expandid = 'dataorig' + element.ticketId;
          if (document.getElementById(expandid)) {
            document.getElementById(expandid).setAttribute("aria-expanded", 'true');
            document.getElementById(expandid).classList.add('show');
          }
        }

        //searching in reopened area of ticket
        for (let i = 0; i < element.reopenedTickets.length; i++){
          const reopenedDescription = element.reopenedTickets[i].description.toLowerCase();
          if (reopenedDescription.includes(this.payload.searchQuery.toLowerCase())) {

            if (!foundInBody){
              if (document.getElementById(element.ticketId)) {
                document.getElementById(element.ticketId).setAttribute("aria-expanded", 'true');
                document.getElementById('collapse' + element.ticketId).classList.add('show');
              }
            }
            if (!getComments){
              getComments = true;
              this.getCommentData(element._id);
            }

            // expand that particular reopened index
            const reopenid = 'reopenData' + element.ticketId + i;
            if (document.getElementById(reopenid)) {
              document.getElementById(reopenid).setAttribute("aria-expanded", 'true');
              document.getElementById(reopenid).classList.add('show');
            }
            const reopenIndexId = 'datacollapse' + element.ticketId + i;
            if (document.getElementById(reopenIndexId)) {
              document.getElementById(reopenIndexId).setAttribute("aria-expanded", 'true');
              document.getElementById(reopenIndexId).classList.add('show');
            }
          }
        }
      })
    }, 100);
  }

  loadData(filterData?: any): void {
    this.showLoader = true;
    if (this.filterData && this.filterData.feedbackProvided !== undefined) {
      this.payload.feedBackGiven = this.filterData.feedbackProvided;
    }
    if ((this.searchText?.trim()?.length >= 2 && parseInt(this.searchText?.trim()) > 0) || this.searchText?.trim()?.length >= 4 || !this.searchText?.length){
      this.payload.searchQuery = this.searchText?.trim();
      this.lastSearchText = this.searchText?.trim();
    } else {
      this.payload.searchQuery = '';
      this.lastSearchText = this.searchText?.trim();
    }
    if (this.filterData.status && this.filterData.status.length){
      this.payload.status = this.filterData.status === 'open' || this.filterData.status == 'Pending' ? ['open', 'reopened'] : [this.filterData.status.toLowerCase()];
    }
    this.helpServices.helpReportList(this.payload).then((res) => {
      this.statusCountData.emit(res.ideaAndSuggestionStatusCount);
      this.helpData = this.maxPageSize
        ? res.ideasAndSuggestions?.slice(0, this.maxPageSize)
        : res.ideasAndSuggestions;
      this.helpListData.emit(res.ideasAndSuggestions);
      this.displayStatus = this.payload.status && this.payload.status[0][0].toUpperCase() + this.payload.status[0].substring(1);
      if (this.displayStatus === 'Open'){
        this.displayStatus = 'Pending';
      }
      if (this.helpData) {
        this.helpData = this.helpData.reduce((acc, curr) => {
          curr.actionDates.forEach((a) => {
            curr[a.type] = a.date;
          });

          if (curr.reopenedTickets) {
            curr.reopenedTickets = curr.reopenedTickets.reverse();
            const tickets = _.get(curr, 'reopenedTickets', []);
            tickets.forEach((t, index) => {
              t.displayName = `Re-open: ${tickets.length - index}`;
              t?.actionDates.forEach((a) => {
                t[a.type] = a.date;
              });
            });
          }
          curr.headerDateString = this.getHeaderDates(curr);
          curr.timeline = this.getIssueTimeline(curr);
          curr.showHistory = false;
          acc.push(curr);
          return acc;
        }, []);
        setTimeout(() => {
          this.helpDataFiltered = this.helpData;
          this.showLoader = false;

          if (this.payload.searchQuery?.length){
            this.expandSearchResults();
          }
          if (this.tid && this.tid != '') {
            this.helpDataFiltered.forEach((res, index) => {
              if (res.ticketId === this.tid) {
                this.helpStatus = res.status;
                this.alert.helpNotifiStatus.next(this.helpStatus);
                this.commentId = res._id;
                this.getCommentData();
                if (!this.notificationPayloadVisited) {
                  this.openNotification();
                }
                this.redirectNotification();
              }
            });
          }
        }, 500);
        if (this.helpDataFiltered.length > 1) {
          const index = this.helpDataFiltered.length - 1;
          // this.helpDataFiltered[index].reopenedTickets.reverse();
          this.helpDataFiltered[index].showHistory = !this.helpDataFiltered[index].showHistory;
        }
      }
    });
  }

  collapseAllReopenIssue() {
    this.helpDataFiltered.forEach((helpData) => {
      if (helpData.reopenedTickets) {
        helpData.reopenedTickets.forEach((_, issueIndex) => {
          if (this.currentDocument.getElementById('datacollapse' + helpData.ticketId + issueIndex)) {

            this.currentDocument.getElementById('datacollapse' + helpData.ticketId + issueIndex).setAttribute("aria-expanded", 'false');
            this.currentDocument.getElementById('reopenData' + helpData.ticketId + issueIndex).setAttribute("aria-expanded", 'false');
            this.currentDocument.getElementById('reopenData' + helpData.ticketId + issueIndex)  .classList.remove('show');
            this.currentDocument.getElementById('reopenData' + helpData.ticketId + issueIndex).classList.add('collapse');
          }
        })
        const origIssueDoc = this.currentDocument.getElementById('dataorig' + helpData.ticketId);
        if (origIssueDoc) {
          origIssueDoc.setAttribute("aria-expanded", 'false');
          origIssueDoc.classList.add('collapse');
          this.currentDocument.getElementById('dataoriginal' + helpData.ticketId).classList.remove('show');
        }
      }
    });
  }

  showLastReopenIssue() {
    this.helpDataFiltered.forEach((helpData) => {
      if (helpData.reopenedTickets) {
        const lastIssueIndex = helpData.reopenedTickets.length - 1;
        //last tab index open
        if (this.currentDocument.getElementById('datacollapse' + helpData.ticketId + lastIssueIndex)) {
          this.currentDocument.getElementById('datacollapse' + helpData.ticketId + lastIssueIndex).setAttribute("aria-expanded", 'true');
          this.currentDocument.getElementById('reopenData' + helpData.ticketId + lastIssueIndex).setAttribute("aria-expanded", 'true');
          this.currentDocument.getElementById('reopenData' + helpData.ticketId + lastIssueIndex).classList.remove('collapse');
          this.currentDocument.getElementById('reopenData' + helpData.ticketId + lastIssueIndex).classList.add('show');
        }
      }
    });
  }


  clickTab(index, helpReport) {

    if (index !== this.currentIssueIndex) {
      this.collapseAllReopenIssue();
      this.showLastReopenIssue();
    }

    this.currentIssueIndex = index;
    this.tid = '';
    if (!this.collapseId) {
      this.collapseId = `#collapse${helpReport.ticketId}`;
    } else {
      if (this.collapseId === `#collapse${helpReport.ticketId}`) {
        if (document.getElementById('collapse' + helpReport.ticketId)) {
          document.getElementById('collapse' + helpReport.ticketId).setAttribute("aria-expanded", 'false');
        }
      } else {
        if (document.getElementById(this.collapseId.substring(9)) && document.getElementById(this.collapseId.substring(1))) {
          document.getElementById(this.collapseId.substring(9)).setAttribute("aria-expanded", 'false');
          document.getElementById(this.collapseId.substring(1)).setAttribute("aria-expanded", 'false');
        }
        $(`${this.collapseId}`).removeClass('show');
        $(`${this.collapseId}`).addClass('collapse');
        this.collapseId = `#collapse${helpReport.ticketId}`;
      }
    }
    const parentAccordion = document.getElementById(helpReport.ticketId).getAttribute("aria-expanded");
    if (parentAccordion === 'false') {
      let element = document.getElementById(`accordion${index}`);
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
      setTimeout(() => {
        let scrollToElement = (document.getElementsByClassName('reportListsHelp') as HTMLCollection)[0];
        scrollToElement.scrollTop -= 4.5;
      }, 1000);
      this.commentId = helpReport._id;
      // this.displayComments.set(helpReport.ticketId, []);
      this.getCommentData();
    }
    if (this.helpDataFiltered[index].reopenedTickets.length > 0) {
      const openIssueIndex = this.helpDataFiltered[index].reopenedTickets.length - 1;
      const parentAccordion = document.getElementById(helpReport.ticketId).getAttribute("aria-expanded");
      if (parentAccordion === 'false') {
        setTimeout(() => {
          if (this.helpDataFiltered[index]?.reopenedTickets?.length > 1) {
            document.getElementById('datacollapse').setAttribute("aria-expanded", 'false');
            $('#originalIssueData').removeClass('show')
            this.helpDataFiltered[index].reopenedTickets.forEach((element, ind) => {
              if (ind !== openIssueIndex) {
                document.getElementById('datacollapse' + helpReport.ticketId + ind).setAttribute("aria-expanded", 'false');
                document.getElementById('reopenData' + helpReport.ticketId + ind).classList.remove('show');
              }
            });
          }
        }, 500)
        //last tab index open
        if (document.getElementById('datacollapse' + helpReport.ticketId + openIssueIndex)) {
          const accordionOpen = document.getElementById('datacollapse' + helpReport.ticketId + openIssueIndex).getAttribute("aria-expanded");
          setTimeout(() => {
            if (openIssueIndex !== -1 && accordionOpen === 'false') {
              document.getElementById('datacollapse' + helpReport.ticketId + openIssueIndex).setAttribute("aria-expanded", 'true');
              document.getElementById('reopenData' + helpReport.ticketId + openIssueIndex).setAttribute("aria-expanded", 'true');
              document.getElementById('reopenData' + helpReport.ticketId + openIssueIndex).classList.remove('collapse');
              document.getElementById('reopenData' + helpReport.ticketId + openIssueIndex).classList.add('show');
            }
          }, 200);
        }
      }
    }
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.alert.helpId.next('');
    this.status = '';
    this.filterData = '';
  }

  getStyle(status): string {
    let className = '';
    switch (status) {
      case 'open':
        className = 'pending';
        break;
      case 'ongoing':
        className = 'ongoing';
        break;
      case 'closed':
        className = 'closed';
        break;
      case 'resolved':
        className = 'resolved';
        break;
      case 'reopened':
        className = 'reopened';
        break;
      default:
        className = '';
        break;
    }
    return className;
  }

  getStausText(status): string {
    let text = '';
    switch (status) {
      case 'open':
        text = 'Pending';
        break;
      case 'ongoing':
        text = 'Ongoing';
        break;
      case 'closed':
        text = 'Closed';
        break;
      case 'resolved':
        text = 'Resolved';
        break;
      case 'reopened':
        text = 'Re-opened';
        break;
      default:
        text = '';
        break;
    }
    return text;
  }

  getFormattedDate(date): string {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let currentDatetime = new Date(date);
    return (
      currentDatetime.getDate() +
      ' ' +
      months[currentDatetime.getMonth()] +
      ' ' +
      currentDatetime.getFullYear()
    );
  }

  getCurrentTicketStatusWithDate(helpReport): string {
    let result = '';
    let date;
    let report = helpReport;
    const len = _.get(helpReport, 'reopenedTickets.length', 0);
    if (helpReport?.status !== 'open' && len > 0) {
      report = helpReport.reopenedTickets[helpReport.reopenedTickets.length - 1];
    }
    let updatedDate = '';
    if (helpReport?.lastActionTaken?.type === 'updatedOn'){
      updatedDate = helpReport?.lastActionTaken?.date;
    }
    result = 'Updated on: ' + '<b class="detailData">' + this.getFormattedDate(updatedDate) + '</b>';
    date = helpReport?.lastActionTaken?.date;
    switch (helpReport?.status) {
      case 'open':
        if (!updatedDate){
          result =
          'Reported on: ' +
          '<b class="detailData">' +
          this.getFormattedDate(date) +
          '</b>';
        }
        break;
      case 'reopened':
        if (!updatedDate){
          result =
          'Re-opened on: ' +
          '<b class="detailData">' +
          this.getFormattedDate(date) +
          '</b>';
        }
        break;
      case 'ongoing':
        if (!updatedDate){
          result =
          'Picked on: ' +
          '<b class="detailData">' +
          this.getFormattedDate(date) +
          '</b>';
        }
        break;
      case 'closed':
        if (!updatedDate){
          result =
          'Closed on: ' +
          '<b class="detailData">' +
          this.getFormattedDate(date) +
          '</b>';
        }
        break;
      case 'resolved':
        if (!updatedDate){
          result =
          'Resolved on: ' +
          '<b class="detailData">' +
          this.getFormattedDate(date) +
          '</b>';
        }
        break;
      default:
        break;
    }

    return result;
  }

  getTicketStatusWithDate(helpReport): string {
    let result = '';
    let date1;
    let date2;
    switch (helpReport?.status) {
      case 'ongoing':
        date1 = helpReport.actionDates?.find(
          (item) => item.type == 'createdAt'
        )?.date;
        result =
          '<span>Reported on: ' + this.getFormattedDate(date1) + '</span>';
        break;
      case 'closed':
        date1 = helpReport.actionDates?.find(
          (item) => item.type == 'createdAt'
        )?.date;
        date2 = helpReport.actionDates?.find(
          (item) => item.type == 'pickedAt'
        )?.date;
        result =
          '<span>Reported on: ' +
          this.getFormattedDate(date1) +
          '</span> ' +
          '<span>Picked on: ' +
          this.getFormattedDate(date2) +
          '</span>';
        break;
      case 'resolved':
        date1 = helpReport.actionDates?.find(
          (item) => item.type == 'createdAt'
        )?.date;
        date2 = helpReport.actionDates?.find(
          (item) => item.type == 'resolvedAt'
        )?.date;
        result =
          '<span>Reported on: ' +
          this.getFormattedDate(date1) +
          '</span> ' +
          '<span>Picked on: ' +
          this.getFormattedDate(date2) +
          '</span>';
        break;
      case 'reopened':
        date1 = helpReport.actionDates?.find(
          (item) => item.type == 'createdAt'
        )?.date;
        date2 = helpReport.actionDates?.find(
          (item) => item.type == 'resolvedAt'
        )?.date;
        result =
          '<span>Reported on: ' +
          this.getFormattedDate(date1) +
          '</span> ' +
          '<span>Original resolution on: ' +
          this.getFormattedDate(date2) +
          '</span>';
        break;
      default:
        break;
    }
    return result;
  }

  getHeaderDates(report) {
    const dates = [];
    const len = _.get(report, 'reopenedTickets.length', 0);
    const reportedOn = this.getFormattedDate(report.createdAt);
    const pickedAt = report.pickedAt ? this.getFormattedDate(report.pickedAt) : '';
    const originalResolution = len > 0 ? this.getFormattedDate(report.resolvedAt ? report.resolvedAt : report.closedAt) : '';
    const reopenedOn = len > 0 ? this.getFormattedDate(report.reopenedTickets[0].createdAt) : '';
    const resolvedOn = (report.status === 'resolved') ? len > 0
      ? this.getFormattedDate(report.reopenedTickets[0].resolvedAt)
      : this.getFormattedDate(report.resolvedAt) : '';
    const closedAt = (report.status === 'closed') ? len > 0
      ? this.getFormattedDate(report.reopenedTickets[0].closedAt) : this.getFormattedDate(report.closedAt)
      : '';

    let dateString = '';
    let dateCount = 1;
    if (report.reopenedTickets.length) {
      if(this.isMobile==true){
        dateString += '<span>Reported on: ' + '<b>' + reportedOn + '</b>' + '</span> ';

      }
      else if(this.isTab==true){
        dateString += '<span>Reported on: ' + '<b>' + reportedOn + '</b>' + '</span> ' + '<span>Orig. Res. on: ' +  '<b>' + originalResolution + '</b>' + '</span>';

      }
      else{
        dateString += '<span>Reported on: ' + '<b>' + reportedOn + '</b>' + '</span> ' + '<span>Original Resolution on: ' +  '<b>' + originalResolution + '</b>' + '</span>' +
          '<span>Re-opened on: ' + '<b>' + reopenedOn + '</b>' + '</span> ';
      }
    }
    else {
        dateString += '<span>Reported on: ' + '<b>' + reportedOn + '</b>' + '</span> ';
        if (pickedAt && report.status !== 'ongoing') {
          dateCount++;
          dateString += '<span> Picked on: ' + '<b>' + pickedAt + '</b>' + '</span> ';
        }
        if (closedAt && report.status !== 'closed') {
          dateCount++;
          dateString += '<span> Closed on: ' + '<b>' + closedAt + '</b>' + '</span> ';
        }
        if (resolvedOn && dateCount < 3 && report.status !== 'resolved') {
          dateCount++;
          dateString += '<span> Resolved on: ' + '<b>' + resolvedOn + '</b>' + '</span> ';
        }

        if (this.isMobile){
          dateString = '<span>Reported on: ' + '<b>' + reportedOn + '</b>' + '</span> ';

          if (dateCount > 1){
            report.displayPlusIcon = true;
          }
        }
    }
    return dateString;
  }

  getIssueTimeline(report): Array<any> {
    const timeline = [];
    report.reopenedTickets.reverse();
    const len = _.get(report, 'reopenedTickets.length', 0);
    const reportedOn = this.getFormattedDate(report.createdAt);
    const pickedAt = report.pickedAt ? this.getFormattedDate(report.pickedAt) : '';
    const resolvedOn = report.resolvedAt ? this.getFormattedDate(report.resolvedAt) : '';
    const closedAt = report.closedAt ? this.getFormattedDate(report.closedAt) : '';
    const obj = { displayName: 'Original', reportedOn, pickedAt, resolvedOn, closedAt };
    timeline.push(obj);
    if (len > 0) {
      [...report.reopenedTickets].forEach((t) => {
        const r = this.getFormattedDate(t.createdAt);
        const p = t.pickedAt ? this.getFormattedDate(t.pickedAt) : '';
        const res = t.resolvedAt ? this.getFormattedDate(t.resolvedAt) : '';
        const c = t.closedAt ? this.getFormattedDate(t.closedAt) : '';
        timeline.push({ displayName: t.displayName, reopenedOn: r, pickedAt: p, resolvedOn: res, closedAt: c });
      });
    }
    return timeline;
  }

  onFeedBackOpen(event, helpReport): void {
    //$(".popup").fadeIn(100);
    $('.popup').css('display', 'block');
    $('.popup .modal-dialog').addClass('activePop');
    this.currentHelpObj = helpReport;
    this.helpFeedbackComponent?.initFeedBack(helpReport);
    //$('#leaveFeedbackModal').modal('show');
    //event.stopPropagation();
  }

  modalPopOpen(event, attachments, index): void {
    $('#previewModal').modal('show');
    setTimeout(() => {
      event.stopPropagation();
      this.helpLightBoxComponent.loadData(attachments, index);
    }, 500);

  }
  handleReopenPopUp(issueId: string) {
    this.currentIssueId = issueId;
    this.showReopenPopUp = !this.showReopenPopUp;
  }

  getSolutionDate(data: any) {
    let dateString = '';
    if (data.status === 'closed') {
      if (data.reopenedTickets.length > 0) {
        dateString = data.reopenedTickets[data?.reopenedTickets?.length - 1]?.actionDates?.find(
          (item) => item.type === 'closedAt')?.date;
      } else {
        dateString = data.closedAt;
      }
    } else {
      if (data.reopenedTickets.length > 0) {
        dateString = data.reopenedTickets[data?.reopenedTickets?.length - 1]?.actionDates?.find(
          (item) => item.type === 'resolvedAt')?.date;
        if (!dateString) {
          dateString = data.reopenedTickets[data?.reopenedTickets?.length - 1]?.actionDates?.find(
            (item) => item.type === 'closedAt')?.date;
        }
      } else {
        dateString = data.resolvedAt;
      }
      if (!dateString) {
        dateString = data.resolvedAt;
      }
    }
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec',];
    const date: Date = new Date(dateString);
    const day = new Date(dateString).getDate();
    const month = new Date(dateString).getMonth();
    const year = new Date(dateString).getFullYear();
    const onlyTime = this.datePipe.transform(date, "h:mm a");
    return (day + ' ' + months[month] + ' ' + year + ', ' + onlyTime);
  }

  getSolutionDateformat(dateString: string) {
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec',];
    const date: Date = new Date(dateString);
    const day = new Date(dateString).getDate();
    const month = new Date(dateString).getMonth();
    const year = new Date(dateString).getFullYear();
    const onlyTime = this.datePipe.transform(date, "h:mm a");
    return (day + ' ' + months[month] + ' ' + year + ', ' + onlyTime);

  }

  toogleTimelinePopUp(report: any = []) {
    this.showTimelinePopUp = !this.showTimelinePopUp;
    if (report.timeline) {
      report?.timeline.forEach((element, index) => {
        if (element.displayName === 'Original') {
          element.reportedonTime = report.createdAt;
          element.pickedTime = report.pickedAt;
          element.resolvedTime = report.resolvedAt || report.closedAt;
          element.resolvedOn = report.closedAt ? this.getFormattedDate(report.closedAt) : this.getFormattedDate(report.resolvedAt);
        } else {
          element.reportedOn = this.getFormattedDate(report.reopenedTickets[index - 1].actionDates[0].date);
          element.reportedonTime = report.reopenedTickets[index - 1].actionDates[0].date;
          element.pickedAt = report.reopenedTickets[index - 1].actionDates[1]?.type === "pickedAt" ? this.getFormattedDate(report.reopenedTickets[index - 1].actionDates[1].date) : '';
          element.pickedTime = element.pickedAt != '' ? report.reopenedTickets[index - 1].actionDates[1].date : '';
          element.closedAt = report.reopenedTickets[index - 1]?.actionDates[2]?.type === 'closedAt' ? this.getFormattedDate(report.reopenedTickets[index - 1].actionDates[2].date) : '';
          element.resolvedOn = report.reopenedTickets[index - 1].actionDates[2]?.type === "resolvedAt" || report.reopenedTickets[index - 1]?.actionDates[2]?.type === 'closedAt'
            ? this.getFormattedDate(report.reopenedTickets[index - 1].actionDates[2].date) : '';
          element.resolvedTime = element.closedAt || element.resolvedOn != '' ? report.reopenedTickets[index - 1].actionDates[2].date : '';
        }
      });
      this.currentTimelineData = report.timeline;
    }
    // $('#timelinePopup').modal('show');
  }

  isMatchWithFilter(item) {
    if (!this.filterData.status || !this.filterData.status.length) {
      return true;
    }
    const statusArray = [this.filterData.status];

    if (this.filterData.status === 'open') {
      statusArray.push('reopened');
    }
    return statusArray.includes(item.status);
  }

  getText() {

    if (this.payload.searchQuery){
      return 'Please try searching with a different ID or keyword'
    }
    let feedbackText = '', str = '';
    if (this.filterData.issueTypes.length > 0 && (this.filterData.dateFrom.length === 0 && this.filterData.dateTo.length === 0)) {
      str = 'No issues have been reported by you in the selected Modules';
    } else if (this.filterData.issueTypes.length === 0 && (this.filterData.dateFrom.length > 0 && this.filterData.dateTo.length > 0)) {
      str = 'No issues have been reported or worked upon or closed in the selected Date Range';
    } else if ((this.filterData.issueTypes.length === 0 && !(this.filterData.dateFrom.length > 0 || this.filterData.dateTo.length > 0)) && this.filterData.feedbackProvided !== undefined) {
      str = 'No issues have been reported or worked upon or closed'
    } else {
      str = 'No issues have been reported or worked upon or closed in the selected Modules within the selected Date Range';
    }

    if (this.filterData.feedbackProvided !== undefined) {
      feedbackText = ' with' + (this.filterData.feedbackProvided ? '' : 'out') + ' Feedback given!';
    } else {
      feedbackText = '!';
    }
    return str + feedbackText;
  }

  getSolutionImage(status){
    if(status === 'closed'){
      return '../../../../assets/images/help/remarksIcon.svg';
    }
    return '../../../../assets/images/help/solution-icon.svg';
  }

  getOriginalSolutionImage(report) {
    if (report.closedAt) {
      return '../../../../assets/images/help/remarksIcon.svg';
    }
    return '../../../../assets/images/help/solution-icon.svg';
  }

  openCommentModal(helpReport){
    this.commentId = helpReport._id;
    this.showCommentModal = true;
  }

  getCommentData(commentId?){
    this.displayComments.set(commentId || this.commentId, []);
    this.helpServices.getIssueComments(commentId ? commentId : this.commentId).then((res) => {
      this.totalImgCount=0;
      this.currImgcount=0;
      this.imageShimmer=true;
      res.commentsData?.comments.forEach((item)=>{
        this.totalImgCount+=item.attachments.length
      })
      if (commentId){
        this.displayComments.set(commentId, res.commentsData?.comments)
      } else {
        this.displayComments.set(this.commentId, res.commentsData?.comments)
      }
    });
  }

  onClosePopUp(){
    this.showCommentModal = false;
  }

  onSuccessfulComment(){
    this.getCommentData();
    this.onClosePopUp();
    const element = this.helpDataFiltered.find(data => data._id === this.commentId);
    element.lastActionTaken = {};
    element.lastActionTaken.date = new Date();
    element.lastActionTaken.type = 'updatedOn';
  }
// Responsive modal
  getInfoPopupData(): void {
    this.infoPopUpData = {
      All: {
        iconUrl: '',
        heading: '',
        text: '',
      },
      Pending: {
        iconUrl: 'pending-info.svg',
        heading: 'Pending',
        text: 'The issue(s) which are yet to be picked by our Support Team are available here',
      },
      Ongoing: {
        iconUrl: 'ongoing-info.svg',
        heading: 'Ongoing',
        text: 'The issue(s) that our Support Team has started working on are available here',
      },
      Resolved: {
        iconUrl: 'resolve-info.svg',
        heading: 'Resolved',
        text: 'The issue(s) that have been resolved are available here',
      },
      Closed: {
        iconUrl: 'closed-info.svg',
        heading: 'Closed',
        text: 'The issue(s) that are not found to be relevant are available here',
      },
    };
  }
  openInfo(event): void {
    $("#helpSupportMobileStatusInfoPopup").modal('show');
    event.stopPropagation();
  }
  onImageLoad(){
    this.currImgcount++;
    if(this.currImgcount === this.totalImgCount){
      this.imageShimmer=false;
    }
  }
  resetImageLoaded(){
    this.imageShimmer=true;
    this.currImgcount=0;
    this.totalImgCount=0;
  }
}

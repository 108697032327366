import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../stores/app.reducers';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';
import { RouteDataService } from '../services/route-data.service';

declare var $: any;

@Component({
  selector: 'app-serious-attempt-warning',
  templateUrl: './serious-attempt-warning.component.html',
  styleUrls: ['./serious-attempt-warning.component.css']
})

export class SeriousAttemptWarningComponent implements OnInit, OnDestroy {

  constructor(private routeDataService: RouteDataService, private store: Store<AppState>, private router: Router) { }

  @Input() dataRecieved: any = {};
  public testTemplate: string;
  public sub: Subscription;
  @Output() submitAttempt = new EventEmitter<any>();
  @Output() resumedTest = new EventEmitter<any>();
  @Input() pageLoaded: string;

  ngOnInit() {
    this.sub = this.store.select('userProfile').subscribe(res => {
      if (res.selectedGoal) {
        if (res.selectedGoal['name'].toLowerCase().replace(' ', '').includes('jee(m')) {
          this.testTemplate = 'jee-main';
        } else if (res.selectedGoal['name'].toLowerCase().replace(' ', '').includes('jee(a')) {
          this.testTemplate = 'jee-advanced';
        } else if (res.selectedGoal['name'].toLowerCase().replace(' ', '').includes('kvpy')) {
          this.testTemplate = 'kvpy';
        } else {
          this.testTemplate = 'others';
        }
      }
    });
  }

  resumeTest() {
    if (this.pageLoaded === 'test') {
      this.resumedTest.emit();
    } else {
      this.closePopup();
    }
    // this.resumedTest.emit();
  }

  submitTest() {
    this.submitAttempt.emit();
  }

  closePopup() {
    localStorage.removeItem('dataForSeriousAttempt');
    $('#warn-serious').modal('hide');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    const url = this.routeDataService.moduleName + '/test-results/' + this.dataRecieved['testId'] + '/' + this.dataRecieved['attemptId']
    + '/' + this.dataRecieved['testType'] + '/' + this.dataRecieved['courseId'] + '/overview';
    this.router.navigateByUrl(url);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

}

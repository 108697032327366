import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { ProgressIndexService } from 'src/app/home-page/services/progress-index.service';
import { SuccessIndexService } from 'src/app/home-page/services/success-index-service';
import { AlertService } from './alert.service';
import { HttpService } from './http.service';
import { forkJoin } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromApp from '../../stores/app.reducers';
import * as LoaderAction from '../../stores/loader/loader.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class RouteDataService {

  public lastUrl = '';
  public homeCards = new BehaviorSubject<any>([]);
  public selectedCard = new BehaviorSubject<any>({});
  public additionalCardData = new BehaviorSubject<any>({});
  public  moduleName = '';
  private moduleNames = ['assigned-tests', 'test-history', 'upcoming-tests', 'myplan', 'previous-year-papers'];
  private cachedcardData: any;

  constructor(
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private alertService: AlertService,
    private progressService: ProgressIndexService,
    private successIndexService: SuccessIndexService,
    private store: Store<fromApp.AppState>,
  ) {
    setTimeout(() => {
      this.getTestDetailsModule(this.router.url);
    }, 400)
    this.location.onUrlChange((url) => {
      this.getTestDetailsModule(url);
    });
  }

  getTestDetailsModule(url:  any){
    if(url.includes('test-details') || url.includes('test-results')){
      let module = url.split(url.includes('test-details') ? 'test-details' : 'test-results')[0];
      module = module.replace(/^\/|\/$/g, '');
      if(module && this.moduleNames.includes(module)){
        this.moduleName = module;
      }
    }
  }

  getCards(){
    this.fetchHomeCardsData().then((cards) => {
      if(cards.userCards){
        this.homeCards.next(cards.userCards);
      }
    });
  }

  selectCard(card: any){
    this.selectedCard.next(card);
  }

  fetchHomeCardsData(): any {
    return new Promise((resolve, reject) => {
      if (!this.cachedcardData) {
        this.http.get('v4/auth/users/cards', true, null, true).subscribe((res) => {
          if (res && res.code === 200) {
            this.cachedcardData = res.data;
            resolve(res.data);
          } else {
            this.alertService.showErrorMessage(res.message);
          }
        });
      } else {
        resolve(this.cachedcardData);
      }
    });
  }

  fetchAdditionalCardsData(){
    this.progressService.fetchMyGoalData().then((res) => {
      if (res.courses) {
        this.store.dispatch(new LoaderAction.SetLoader(false));
        const courseObj = res.courses.find((item) => item.isPrimary);
        if(courseObj) {
          const progressIndex = this.progressService.getProgressIndex(courseObj.courseId)
          const successIndex = this.successIndexService.getSuccessIndexCard(courseObj.courseId)

          forkJoin([progressIndex, successIndex]).subscribe((result: any) => {
            const progressIndexRes = result[0];
            const successIndexRes = result[1];
            const additionalData = {
              progIndex: {
                value: progressIndexRes?.hasProgressIndex? progressIndexRes?.progressIndex.toFixed() : 0
              },
              successIndex: {
                value:(successIndexRes?.successIndex?.value).toFixed(3),
                hasSuccessIndex: (successIndexRes?.successIndex?.hasSuccessIndex)
              },
            }
            this.additionalCardData.next(additionalData);
          })

        }
      }
    });
  }

}

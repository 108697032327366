import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGaurd } from './auth-guard.service';
import { VerifyPasscodeComponent } from './auth/verify-passcode/verify-passcode.component';
import { UnderDevelopmentComponent } from './shared/components/under-development/under-development.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('src/app/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    canActivate: [AuthGaurd],
    data: { isLoginPage: true },
    loadChildren: () => import('src/app/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'home',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'parent-proforma/:userId',
    component: VerifyPasscodeComponent,
  },
  {
    path: 'teacher-proforma/:userId',
    component: VerifyPasscodeComponent,
  },
  {
    path: 'foreward-and-fups',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/foreward-fup/foreward-fup.module').then(m => m.ForewardFupModule)
  },
  {
    path: 'complete-profile',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/user-onboarding/user-onboarding.module').then(m => m.UserOnboardingModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'refer-and-earn',
    loadChildren: () => import('src/app/refer-and-earn/refer-and-earn.module').then(m => m.ReferAndEarn)
  },
  {
    path: 'view-schedule/:packageId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/view-schedule/view-schedule.module').then(m => m.ViewScheduleModule)
  },
  {
    path: 'community',
    loadChildren: () => import('src/app/new-community/new-community.module').then(m => m.NewCommunityModule)
  },
  {
    path: 'goal-setting',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/goal-setting/goal-setting.module').then(m => m.GoalSettingModule)
  },
  {
    path: 'concept-history/:syllabusId/:conceptId/:title',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/concept-history/concept-history.module').then(m => m.ConceptHistoryModule)
  },
  {
    path: 'learn/note/:noteId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/learn/learn.module').then(m => m.LearnModule)
  },
  {
    path: 'learn/pdf/:pdfId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/learn/learn.module').then(m => m.LearnModule)
  },
  {
    path: 'learn/video/:videoId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/learn/learn.module').then(m => m.LearnModule)
  },
  {
    path: 'learn/audio/:audioId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/learn/learn.module').then(m => m.LearnModule)
  },
  {
    path: 'live-classes',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/live-classes/live-classes.module').then(m => m.LiveClassesModule)
  },
  {
    path: 'my-revision-plan',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/myRevision-plan/myRevision-plan.module').then(m => m.MyRevisionPlanModule)
  },
  {
    path: 'revision/my-revision-plan',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/myRevision-plan/myRevision-plan.module').then(m => m.MyRevisionPlanModule)
  },
  {
    path: 'test',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test/test.module').then(m => m.TestModule)
  },
  // Routes for new result analysis
  {
    path: 'test-results',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: 'test-results/:testId/:attemptId/:testType',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: 'test-results/:testId/:attemptId/:testType/:testName',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: 'test-results/:testId/:attemptId/:testType/:testName/:authToken',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: 'test-results/:testId/:attemptId/:testType/:courseId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: 'test-results/:testId/:attemptId/:testType/:testName/:courseId/:authToken',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: ':module/test-results',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: ':module/test-results/:testId/:attemptId/:testType',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: ':module/test-results/:testId/:attemptId/:testType/:testName',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: ':module/test-results/:testId/:attemptId/:testType/:testName/:authToken',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: ':module/test-results/:testId/:attemptId/:testType/:courseId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: ':module/test-results/:testId/:attemptId/:testType/:testName/:courseId/:authToken',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: 'revision/question-details',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/revision-question-details/revision-question-details.module').then(m => m.RevisionQuestionDetailsModule)
  },
  // Routes for new result analysis ends

  { // TODO: insert in some module later
    path: 'dashboard',
    canActivate: [AuthGaurd],
    component: UnderDevelopmentComponent
  },
  {
    path: 'test-details/course/:courseId/test/:testId/package/:packageId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule)
  },
  {
    path: 'test-details/course/:courseId/test/:testId/:isCustomTest',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule)
  },
  {
    path: 'test-details/course/:courseId/test/:testId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule)
  },
  {
    path: 'test-details/course/:courseId/assignment/:assignmentId/test/:testId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule)
  },
  {
    path: ':module/test-details/course/:courseId/test/:testId/package/:packageId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule)
  },
  {
    path: ':module/test-details/course/:courseId/test/:testId/:isCustomTest',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule)
  },
  {
    path: ':module/test-details/course/:courseId/test/:testId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule)
  },
  {
    path: ':module/test-details/course/:courseId/assignment/:assignmentId/test/:testId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule)
  },
  {
    path: 'cpp-subjective-file-upload',
    loadChildren: () => import('src/app/upload-file-subjective/upload-file-subjective.module').then(m => m.UploadFileSubjectiveModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('src/app/about-us/about-us.module').then(m => m.aboutUsModule)
  },
  {
    path: 'm.about-us',
    loadChildren: () => import('src/app/about-us-mobile/about-us-mobile.module').then(m => m.AboutUsMobileModule)
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('src/app/terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule)
  },
  {
    path: 'm.terms-of-use',
    loadChildren: () => import('src/app/terms-of-use-mobile/terms-of-use-mobile.module').then(m => m.TermsOfUseMobileModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('src/app/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'm.privacy-policy',
    loadChildren: () => import('src/app/privacy-policy-mobile/privacy-policy-mobile.module').then(m => m.PrivacyPolicyMobileModule)
  },
  {
    path: 'copyright',
    loadChildren: () => import('src/app/copyright/copyright.module').then(m => m.CopyrightModule)
  },
  {
    path: 'disclaimer',
    loadChildren: () => import('src/app/disclaimer/disclaimer.module').then(m => m.DisclaimerModule)
  },
  {
    path: 'exam-temperament-questions/:qIds/:type/:tempId/:attemptId',
    canActivate: [AuthGaurd],
    // tslint:disable-next-line: max-line-length
    loadChildren: () => import('src/app/exam-temparament-questions/exam-temparament-questions.module').then(m => m.ExamTemparamentQuestionsModule)
  },
  {
    path: 'fiitjee-recorded-lectures-privacy-policy',
    loadChildren: () => import('src/app/lecture-privacy-policy/lecture-privacy-policy.module').then(m => m.LecturePrivacyPolicyModule)
  },
  {
    path: 'm.fiitjee-recorded-lectures-privacy-policy',
    loadChildren: () => import('src/app/mobile-lecture-privacy-policy/mobile-lecture-privacy-policy.module').then(m => m.MobileLecturePrivacyPolicyModule)
  },
  {
    path: 'manage-devices',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/manage-device/manage-device.module').then(m => m.ManageDeviceModule)
  },
  {
    path: 'help',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/help-and-support/help-and-support.module').then(m => m.HelpAndSupportModule)
  },
  {
    path: 'idea',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/idea-and-support/idea-and-support.module').then(m => m.IdeaAndSupportModule)
  },
  {
    path: 'my-teachers-feedback',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/feedback-form/feedback-form.module').then(m => m.FeedbackFormModule)
  },
  {
    path: 'remedial-questions',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/remedial-questions/remedial-questions.module').then(m => m.RemedialQuestionsModule)
  },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }

import { Component, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { HelpService } from 'src/app/help-and-support/services/help.service';
import { ViewEncapsulation } from '@angular/core';
import { HelpFeedbackComponent } from '../help-feedback/help-feedback.component';
import { Subscription } from 'rxjs/Subscription';
import { HelpFilterService } from 'src/app/help-and-support/help-filter/help-filter.service';
import { HelpLightBoxComponent } from '../help-light-box/help-light-box.component';
import _ from 'lodash';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../stores/app.reducers';
import { DatePipe } from '@angular/common';
import { STATUS_VALUE } from '../../constants/help-idea.constant';
import  MEDIA_TYPE  from './MEDIA_TYPE.constants';
import USER_TYPE from './USER_TYPE.constants';
import HELP_REPORT_STATUS from './HELP_REPORT_STATUS.constants';
import { ChangeDetectorRef } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-idea-report-list',
  templateUrl: './idea-report-list.component.html',
  styleUrls: ['./idea-report-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IdeaReportListComponent implements OnInit {

  @Input() public maxPageSize: any;
  @Input() public issueType: any;
  @Input() public loadListData?: any;
  @Input() public searchText: string;
  public ideaData: any;
  helpIssueType: any;
  currentHelpObj: any;
  userProfile:any ;
  newImage = false;
  status = '';
  subscription: Subscription;
  private activeSubscriptions = new Subscription();
  ideaDataFiltered = [];
  public isOtherFilterSelected: boolean = false;
  showLoader = true;
  public filterData: any = [];
  public collapseId: any;
  public index: any;
  public isMobile: boolean = false;
  public isTab: boolean = false;
  public suggestionItemsShow: boolean = false;
  public filterpayload: any;
  public showCommentModal: boolean = false;
  public displayComments = {};
  public commentId: string;
  public photoUrl: string;
  public searchHighlightStr: string;
  public payload: any;
  public dataLoaded = false;
  public isSearchTriggered: boolean = false;
  public initialsClass;
  @ViewChild(HelpFeedbackComponent) helpFeedbackComponent: HelpFeedbackComponent;
  @ViewChild(HelpLightBoxComponent) helpLightBoxComponent: HelpLightBoxComponent;
  @Output() openFeedbackPopupIdea = new EventEmitter<any>();
  @Output() statusCountData = new EventEmitter<any[]>();
  @Output() ideaListData = new EventEmitter<any[]>();
  showNewIdeaPopUp: boolean = false;
  tid: string = '';
  public selectedType: any;
  public selectedCategory: any;
  public infoPopUpData: any = {};
  public notificationTid;
  private currImgcount = 0;
  private totalImgCount = 0;
  public imageShimmer = [];
  public userInitials = '';
  constructor(private helpServices: HelpService,
    private helpFilterService: HelpFilterService,
    private router: Router,
    private datePipe:DatePipe,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private cd: ChangeDetectorRef) {
    let regexForTab = new RegExp('Chrome\/[.0-9]* (?!Mobile)');
    let regexForMobile = new RegExp('Chrome/[.0-9]* Mobile');
    if (navigator.userAgent.includes('Android')) {
      if (regexForTab.test(navigator.userAgent)) {
        this.isMobile = false;
        this.isTab = true;
      } else if (regexForMobile.test(navigator.userAgent)) {
        this.isMobile = true;
        this.isTab = false;
      }
    } else if (navigator.userAgent.includes('iPhone')) {
      this.isMobile = true;
      this.isTab = false;
    } else if (navigator.userAgent.includes('iPad')) {
      this.isMobile = false;
      this.isTab = true;
    } else {
      this.isMobile = false;
      this.isTab = false;
    }
    this.subscription = this.helpFilterService.onFilter().subscribe((filterData: any) => {
      if (filterData) {
        this.isOtherFilterSelected = false;
        if (filterData.dateFrom || filterData.dateTo || (filterData.type && filterData.type.length) || (filterData.issueTypes && filterData.issueTypes.length) || filterData.feedbackProvided !== undefined) {
          this.isOtherFilterSelected = true;
        }
        if (!filterData.type || !filterData.type.length) {
          filterData.type = ['']
        }
        if (filterData.type && filterData.type.length == 1 && filterData.type[0] == '') {
          filterData.type.push('idea');
          filterData.type.push('suggestion');
        }
        if (this.tid && this.filterData.status && this.filterData.status != filterData.status) {
          this.tid = '';
        }
        this.status = filterData.status;
        this.loadData(filterData);
        if (this.status) {
          this.tid = '';
        }
        // this.filterpayload = filterData;
        // this.filterData = filterData;
        // this.filteringData();
        // if (filterData.type && filterData.type[1] == 'idea' && filterData.type.length == 2){
        //   filterData.type.push('suggestion');
        // }
        // if (filterData.type && filterData.type[1] == 'suggestion' && filterData.type.length == 2){
        //   filterData.type.push('idea');
        // }
        // this.ideaDataFiltered.forEach((res) => {
        //   if (document.getElementById(res.ticketId)) {
        //     const accordionOpen = document.getElementById(res.ticketId).getAttribute("aria-expanded");
        //     if (accordionOpen === 'true') {
        //       document.getElementById(res.ticketId).setAttribute("aria-expanded", 'false');
        //       $('#collapse' + res.ticketId).removeClass('show')
        //       $('#collapse' + res.ticketId).addClass('collapse')
        //     }
        //   }
        // });
      } 
    });
    
    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        setTimeout(() => {
          this.router.navigate([], {
            queryParams: { tid: null },
            queryParamsHandling: 'merge',
          });
          if (this.notificationTid) {
            this.tid = '';
            this.notificationTid = ''
          }
        },5000)
      }
    })
  }

  get STATUS_VALUE(){
    return STATUS_VALUE;
  }  

  public get MEDIA_TYPE(){
       return MEDIA_TYPE;
    }
  
  public get HELP_REPORT_STATUS(){
    return HELP_REPORT_STATUS;
  }

  ngOnInit(): void {
    this.helpServices.isModalClosed.subscribe((res) => {
      if (res) {
         this.loadData();
      }
    });
    window.scroll(0, 0);
    this.helpServices.UpdatedDataHelp.subscribe((res) => {
      if (res && res.type) {
         this.loadData();
      }
    });
    if (this.ideaData && this.ideaData.length) {

    }
    const url = this.router.url.split('/');
    if (url[1].includes('idea')) {
      this.newImage = true;
      this.helpServices.status.subscribe((res) => {
        this.status = res;

      });
    }
    if (this.searchText === undefined){
      this.loadData();
    }
    this.getInfoPopupData();
    this.notificationTid = this.getParameterByName('tid');
    this.activeSubscriptions.add(this.store.select('userProfile').subscribe((userProfile) => {
      if (userProfile.userData) {
        this.userProfile = userProfile;
        this.photoUrl = userProfile.userData.photoUrl;
        this.initialsClass = this.getInitialsClass();
      }
    }));
  }

  getInitialsClass(): string {
    
    const classArray = Array.from({length: 5}, (_, i) => 'alphabet-bg'+( i + 1))
    const randomNumber = Math.floor(Math.random() * classArray.length);
    return classArray[randomNumber];
  }


  public get USER_TYPE(){

      return USER_TYPE;
    }

  ngOnChanges() {
    const cardData = (JSON.parse(localStorage.getItem('openendCardDetails')));
    cardData.utilityCards?.data?.forEach(element => {
      if (element.type === "ideasAndSuggestions") {
        cardData.selectedCard = element;
        localStorage.setItem('openendCardDetails', JSON.stringify(cardData));
      }
    });
    if ((this.dataLoaded && ((this.searchText?.trim()?.length >= 2 && parseInt(this.searchText?.trim()) > 0) || this.searchText.trim().length >= 4))){
      this.isSearchTriggered = true;
      this.loadData(this.payload);
    } else {
      if (this.isSearchTriggered){
        this.isSearchTriggered = false;
        this.loadData(this.payload);
      }
    }
    this.alertService.issueId.subscribe((res) => {
      if (res) {
        this.showLoader = true;
        this.tid = res;
        this.collapseId = res;
        this.loadData();
      }
    });
  }
  tooltipshow() {
    this.suggestionItemsShow = !this.suggestionItemsShow;
  }
  getParameterByName(name, url = window.location.href): any {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  expandSearchResults(searchQuery){
    setTimeout(() => {
      this.ideaDataFiltered.forEach(element => {
        let getComments = false;
        const description = element.description.toLowerCase();
        if (description.includes(searchQuery.toLowerCase())){
          getComments = true;
          this.getCommentData(element._id);
          document.getElementById(element.ticketId).setAttribute('aria-expanded', 'true');
          $('#collapse' + element.ticketId).addClass('show')
        }
      })
    }, 100);  
  }

  loadData(filterData?: any,): void {
    this.showLoader = true;
    if (this.status.includes('All')){
      this.status = ""
    }
    const payload:any = {
      pageNumber: 1,
      pageSize: 1000,
      pagination: true,
      status: this.status != '' ? [this.status]:'',
      type: [
        "idea",
        "suggestion"
      ],
      categoryFilter: {
        idea: {
          categories: []
        },
        suggestion: {
          categories: []
        }
      },
    }
    const ideaTypeFilters = ['Business Idea', 'Product/ Platform Idea', 'Feature Idea', 'Other'];
    const suggestionAnalyticsFilters = ['Progress Tracker', 'Exam Temperament', 'Effort Rating', 'SWOT'];
    if (filterData) {
      if (filterData.dateFrom && filterData.dateTo){
        payload.startDate = filterData.dateFrom;
        payload.endDate = filterData.dateTo;
      }
      const modelGoalTrackingData = {
        name : 'Goal Tracking Panel',
        isSelected: true,
        subCategories : []
      }
      const modelAnalyticsData = {
        name : 'Analytics',
        isSelected: true,
        subCategories : []
      }

      // inserting all the subfilters in the payload
      filterData.issueTypes?.forEach(issue => {
        const filterData = {
          name : issue,
          isSelected : true,
        }
        if (ideaTypeFilters.includes(issue)){
          payload.categoryFilter.idea.categories.push(filterData);
        } else {
          if (issue === 'General'){
            payload.categoryFilter.suggestion.categories.push(filterData);
          } else if (suggestionAnalyticsFilters.includes(issue)) {
            const analyticsArr =  payload.categoryFilter.suggestion.categories.find(subCategory => subCategory.name === 'Analytics')
            if (!analyticsArr){
              payload.categoryFilter.suggestion.categories.push(modelAnalyticsData);
              payload.categoryFilter.suggestion.categories[payload.categoryFilter.suggestion.categories.length - 1].subCategories.push(filterData);
            } else {
              analyticsArr.subCategories.push(filterData);
            }
          } else {
            const goalTrackingArr =  payload.categoryFilter.suggestion.categories.find(subCategory => subCategory.name === 'Goal Tracking Panel')
            if (!goalTrackingArr){
              payload.categoryFilter.suggestion.categories.push(modelGoalTrackingData);
              payload.categoryFilter.suggestion.categories[payload.categoryFilter.suggestion.categories.length - 1].subCategories.push(filterData);
            } else {
              goalTrackingArr.subCategories.push(filterData);
            }
          }
        }
      })
      if (filterData.feedbackProvided !== undefined){
        payload.feedBackGiven = filterData.feedbackProvided;
      }
    }
    if ((this.searchText?.trim()?.length >= 2 && parseInt(this.searchText?.trim()) > 0) || this.searchText?.trim()?.length >= 4 || !this.searchText?.length){
      payload.searchQuery = this.searchText?.trim();
      this.searchHighlightStr = this.searchText?.trim();
    } else {
      payload.searchQuery = '';
      this.searchHighlightStr = '';
    }
    this.payload = filterData;
    this.helpServices.helpReportList(payload).then((res) => {
      if (res) {
        this.statusCountData.emit(res.ideaAndSuggestionStatusCount);
        this.ideaData = JSON.parse(JSON.stringify(this.maxPageSize ? res.ideasAndSuggestions?.slice(0, this.maxPageSize) : res.ideasAndSuggestions));
        this.ideaListData.emit(res.ideasAndSuggestions);
        this.ideaData.forEach((report) => {
          report.categoriesObj = this.getCategoriesName(report);
          report.categoriesString = report.categoriesObj.join(', ');
        });
        this.showLoader = false;
        this.ideaDataFiltered = JSON.parse(JSON.stringify(this.ideaData));
        for(let i =0; i<this.ideaDataFiltered.length; i++)
        {
          this.ideaDataFiltered[i]['htmlContent'] = this.getTicketStatusWithDate(this.ideaDataFiltered[i]);
        }
        this.dataLoaded = true;
        if (payload.searchQuery?.length){
          this.expandSearchResults(payload.searchQuery); 
        }
        if (this.tid) {
          const tidIndex = this.ideaDataFiltered.findIndex((obj) => obj.ticketId === this.tid);
          if (tidIndex != -1) {
            this.alertService.IdeaStatus.next(this.ideaDataFiltered[tidIndex].status);
          }
          setTimeout(() => {
            this.tid = this.collapseId;
            this.commentId = res?.ideasAndSuggestions?.[tidIndex]?._id;
            this.getCommentData();
            if (document.getElementById(this.collapseId)) {
              // const accoridion = document.getElementById(this.collapseId).getAttribute('aria-expanded');
              // if (accoridion === 'false') {
              //   document.getElementById(this.collapseId).setAttribute('aria-expanded', 'true');
              //   $('#collapse' + this.collapseId).addClass('show')
              // }
              document.getElementById(this.collapseId).setAttribute("aria-expanded", 'true');
              document.getElementById('collapse' + this.collapseId).classList.remove('collapse');
              document.getElementById('collapse' + this.collapseId).classList.add('show');
              this.router.navigate([], {
                queryParams: { tid: null },
                queryParamsHandling: 'merge',
              });
              let element = document.getElementById(this.tid);
              let notificationListingTop = document.getElementById('notificationListingTop' + this.tid);
              $(notificationListingTop).addClass('spaceTop5');
              notificationListingTop.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
              });
             // this.tid = '';
              setTimeout(() => {
                let scrollToElement = document.getElementsByClassName('reportLists')[0] as HTMLElement;
                scrollToElement.scrollTop -= 4.5;
              }, 1000);
            }
          }, 1000);
        }
        if (this.filterData.type && this.tid == '') {
          this.filteringData();
        }
        else if (this.tid) {
          this.filteringData();
        }
        if (localStorage.getItem('lastRequested') === '/home') {
          document.getElementById(this.collapseId)?.setAttribute("aria-expanded", 'false');
          document.getElementById('collapse' + this.collapseId)?.classList.add('collapse');
          document.getElementById('collapse' + this.collapseId)?.classList.remove('show');
        }
      }else{
        this.showLoader = false;
      }
    });
  }
  filteringData() {
    if (this.ideaData && this.ideaData.length) {
      this.ideaDataFiltered = this.ideaData.filter((item) => {
        if (this.filterData.status && this.filterData.issueTypes.length && this.filterData.type && this.filterData.type.length) {
          return item.status == this.filterData.status &&
            this.filterData.issueTypes.some(issue => item.categoriesObj.indexOf(issue) >= 0);
        } else if (this.filterData.status && this.filterData.type && this.filterData.type.length) {
          return item.status == this.filterData.status;
        } else if (this.filterData.issueTypes?.length && this.filterData.type?.length) {
          return this.filterData.issueTypes.some(issue => item.categoriesObj.indexOf(issue) >= 0);
        }
        else if (this.filterData.status && this.filterData.issueTypes.length) {
          return item.status == this.filterData.status &&
            this.filterData.issueTypes.some(issue => item.categoriesObj.indexOf(issue) >= 0);
        } else if (this.filterData.status) {
          return item.status == this.filterData.status;
        } else if (this.filterData.issueTypes && this.filterData.issueTypes.length) {
          return this.filterData.issueTypes.some(issue => item.categoriesObj.indexOf(issue) >= 0);
        } else if (this.filterData.type && this.filterData.type.length) {
          return this.filterData.type.indexOf(item.type) != -1;
        }
        return true;
      }).filter((item) => {
        if (this.filterData.dateFrom && this.filterData.dateTo) {
          if (item.actionDates.length > 1) {
            let value;
            item.actionDates.forEach(element => {
              if (
                new Date(element.date.split('T')[0]).getTime() >=
                new Date(this.filterData.dateFrom).getTime() &&
                new Date(element.date.split('T')[0]).getTime() <=
                new Date(this.filterData.dateTo).getTime()
              ) {
                value = item;
              }
            });
            if (value) {
              return value;
            }
          } else {
            if (
              new Date(item.createdAt.split('T')[0]).getTime() >=
              new Date(this.filterData.dateFrom).getTime() &&
              new Date(item.createdAt.split('T')[0]).getTime() <=
              new Date(this.filterData.dateTo).getTime()
            ) {
              return item;
            }
          }

        } else {
          return item;
        }
      });
    }
  }


  // similar function written as a promise to cater async code in this file

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.alertService.issueId.next('');
    this.subscription.unsubscribe();
    this.activeSubscriptions.unsubscribe();
  }

  getStyle(status): string {
    let className = '';
    switch (status) {
      case 'submitted':
        className = 'pending';
        break;
      case 'ongoing':
        className = 'ongoing';
        break;
      case 'accepted':
        className = 'accepted';
        break;
      case 'completed':
        className = 'resolved';
        break;
      case 'closed':
        className = 'closed';
        break;
      default:
        className = '';
        break;
    }
    return className;
  }

  getStausText(status): string {
    let text = '';
    switch (status) {
      case 'accepted':
        text = 'Accepted';
        break;
      case 'ongoing':
        text = 'Ongoing';
        break;
      case 'submitted':
        text = 'Submitted';
        break;
      case 'completed':
        text = 'Completed';
        break;
      case 'closed':
        text = 'Closed';
        break;
      default:
        text = '';
        break;
    }
    return text;
  }

  getFormattedDate(date): string {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const currentDatetime = new Date(date);
    return (currentDatetime.getDate() + ' ' + months[currentDatetime.getMonth()] + ' ' + currentDatetime.getFullYear());
  }

  getCurrentTicketStatusWithDate(helpReport): string {
    let result = '';
    let date;
    switch (helpReport?.status) {
      case 'accepted':
        date = helpReport.actionDates?.find(item => item.type == 'acceptedAt')?.date;
        result = 'Accepted on: ' + '<b class="detailData">' + this.getFormattedDate(date) + '</b>';
        break;
      case 'ongoing':
        date = helpReport.actionDates?.find(item => item.type == 'pickedAt')?.date;
        result = 'Picked on: ' + '<b class="detailData">' + this.getFormattedDate(date) + '</b>';
        break;
      case 'submitted':
        date = helpReport.actionDates?.find(item => item.type == 'createdAt')?.date;
        result = 'Submitted on: ' + '<b class="detailData">' + this.getFormattedDate(date) + '</b>';
        break;
      case 'completed':
        date = helpReport.actionDates?.find(item => item.type == 'completedAt')?.date;
        result = '<span>Completed on: ' + '<b class="detailData">' + this.getFormattedDate(date) + '</b></span> ';
        break;
      case 'closed':
        date = helpReport.actionDates?.find(item => item.type == 'closedAt')?.date;
        result = '<span>Closed on: ' + '<b class="detailData">' + this.getFormattedDate(date) + '</b></span> ';
        break;
      default:
        break;
    }

    return result;
  }

  getTicketStatusWithDate(helpReport): string {
    let result = '';
    let date1;
    let date2;
    let date3;
    switch (helpReport?.status) {
      case 'ongoing':
        date1 = helpReport.actionDates?.find(item => item.type == 'createdAt')?.date;
        date2 = helpReport.actionDates?.find(item => item.type == 'acceptedAt')?.date;
        result = '<span>Submitted on: <b>' + this.getFormattedDate(date1) + '</b></span>' + '<span>Accepted on: <b>' + this.getFormattedDate(date2) + '</b></span>';
        break;
      case 'accepted':
        date1 = helpReport.actionDates?.find(item => item.type == 'createdAt')?.date;
        date2 = helpReport.actionDates?.find(item => item.type == 'acceptedAt')?.date;
        result = '<span>Submitted on: <b>' + this.getFormattedDate(date1) + '</b></span> ';
        break;
      case 'completed':
        date1 = helpReport.actionDates?.find(item => item.type == 'createdAt')?.date;
        date2 = helpReport.actionDates?.find(item => item.type == 'completedAt')?.date;
        date3 = helpReport.actionDates?.find(item => item.type == 'acceptedAt')?.date;
        const date4 = helpReport.actionDates?.find(item => item.type == 'pickedAt')?.date;
        result = '<span>Submitted on: <b>' + this.getFormattedDate(date1) + '</b></span> ' + '</b></span> ' + '<span>Accepted on: <b>' + this.getFormattedDate(date3) + '</b></span>' + '<span>Picked on: <b>' + this.getFormattedDate(date4) ;
        break;
      case 'closed':
        date1 = helpReport.actionDates?.find(item => item.type == 'createdAt')?.date;
        result = '<span>Submitted on: <b>' + this.getFormattedDate(date1) + '</b></span>';
      default:
        break;
    }
    return result;
  }


  onFeedBackOpen(helpReport) {
    $('.popup').css('display', 'block');
    $('.popup .modal-dialog').addClass('activePop');
    this.currentHelpObj = helpReport;
    this.helpFeedbackComponent?.initFeedBack(helpReport);
    this.openFeedbackPopupIdea.emit(helpReport);
  }

  modalPopOpen(event, helpReport, index): void {
    $('#previewModal').modal('show');
    setTimeout(() => {
      event.stopPropagation();
      this.helpLightBoxComponent.loadData(helpReport?.attachments, index);
      
    }, 500);
  }

  isMatchWithFilter(item) {
    const types = _.get(this.filterData, 'type', []);
    if (!(this.filterData.status || types.length > 0)) {
      return true;
    }
    if (this.filterData.status && this.filterData.status !== item.status) {
      return false;
    }
    if (this.filterData.type && (!types.includes(item.type))) {
      return false;
    }
    return true;
  }
  toggleIssuePopUp() {
    this.showNewIdeaPopUp = !this.showNewIdeaPopUp;
  }

  getCategoriesName(report): [] {
    const result = report.categories.reduce((acc, curr) => {
      if (curr.subCategories.length > 0) {
        curr.subCategories.forEach((sub) => {
          acc.push(sub.name);
        });
      } else {
        acc.push(curr.name);
      }
      return acc;
    }, []);
    return result;
  }

  getCategories(reqObj) {
    const result = reqObj.categories.reduce((acc, curr) => {
      if (curr.subCategories.length > 0) {
        curr.subCategories.forEach((sub) => {
          acc.push(sub.name);
        });
      } else {
        acc.push(curr.name);
      }
      return acc;
    }, []);
    this.selectedType = result;
    if (result.length > 5 && !this.isMobile && !this.isTab) {
      this.selectedCategory = result.slice(5, result.length)
      return result.slice(0, 5).join(', ');
    }
    if (result.length > 2 && !this.isMobile && this.isTab) {
      this.selectedCategory = result.slice(2, result.length)
      return result.slice(0, 2).join(', ');
    }
    if (result.length > 1 && this.isMobile && !this.isTab) {
      this.selectedCategory = result.slice(1, result.length)
      return result.slice(0, 1).join(', ');
    }
    return result.join(', ');
  }

  getNumber(data: any) {
    let number;
    if (data.categoriesObj.length > 5) {
      if (!this.isMobile && !this.isTab) {
        number = this.selectedType.length - 5;
      }
    }
    if (data.categoriesObj.length > 2) {
      if (!this.isMobile && this.isTab) {
        number = this.selectedType.length - 2;
      }
    }
    if (data.categoriesObj.length > 1) {
      if (this.isMobile && !this.isTab) {
        number = this.selectedType.length - 1;
      }
    }
    return number;
  }
  getSolutionDate(data: any) {
    let dateString = data.actionDates?.find(
      (item) => item.type == 'closedAt' || 'pickedAt'
    )?.date;
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec',];
    const date: Date = new Date(dateString);
    const day = new Date(dateString).getDate();
    const month = new Date(dateString).getMonth();
    const year = new Date(dateString).getFullYear();
    const onlyTime = this.datePipe.transform(date, "h:mm a");
    return (day + ' ' + months[month] + ' ' + year + ', ' + onlyTime);

  }
  clickTab(index, helpItem) {
    this.tid = '';
    const accordionOpen = document.getElementById(helpItem.ticketId).getAttribute("aria-expanded");
    // let element = document.getElementById(this.tid);
    // $(element).addClass('spaceTop5');
    if (accordionOpen === 'false') {
      this.suggestionItemsShow = false;
      setTimeout(() => {
        let element1 = document.getElementById(`accordion${index}`);
        element1.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
        setTimeout(() => {
          let scrollToElement = document.getElementsByClassName('reportLists')[0] as HTMLElement;
          scrollToElement.scrollTop -= 4.5;
        },1000)
      },500);
      this.commentId = helpItem._id;
      this.getCommentData();
    }
  }

  openCommentModal(ticketId){
    this.commentId = ticketId._id; 
    this.showCommentModal = true;
  }

  getCommentData(commentId?){
    const isCommentId =commentId || this.commentId; 
    this.displayComments[isCommentId] =  [];
    this.helpServices.getIssueComments(isCommentId).then((res) => {
      this.totalImgCount=0;
      this.currImgcount=0;
      this.imageShimmer=[];
      res.commentsData?.comments.forEach((item)=>{
        this.totalImgCount+=item.attachments.length;
      })
      
      this.displayComments[isCommentId] =  res.commentsData?.comments;
      
    });
  }

  onClosePopUp(){
    this.showCommentModal = false;
  }

  onSuccessfulComment(){
    this.getCommentData();
    this.onClosePopUp();
    const element = this.ideaDataFiltered.find(data => data._id === this.commentId);
    if(element)
    {
      element.lastActionTaken = {};
      element.lastActionTaken.date = new Date();
      element.lastActionTaken.type = 'updatedOn';
    }
    
  }

  getInfoPopupData(): void {
    this.infoPopUpData = {
      All: {
        iconUrl: '',
        heading: '',
        text: '',
      },
      submitted: {
        iconUrl: 'submitted-info.svg',
        heading: 'Submitted',
        text: 'The Ideas/Suggestions you have submitted which are yet to be reviewed by our team are available here',
      },
      accepted: {
        iconUrl: 'accepted-info.svg',
        heading: 'Accepted',
        text: 'The Ideas/Suggestions you have submitted which are reviewed and accepted to work upon are available here',
      },
      ongoing: {
        iconUrl: 'ongoing-info.svg',
        heading: 'Ongoing',
        text: 'The Ideas/Suggestions submitted by you that are being implemented by our team are available here',
      },
      completed: {
        iconUrl: 'completed-info.svg',
        heading: 'Completed',
        text: 'The Ideas/Suggestions submitted by you that have been implemented and released are available here',
      },
      closed: {
        iconUrl: 'closed-info.svg',
        heading: 'Closed',
        text: 'The Ideas/Suggestions that are reviewed and declined by our team are available here',
      },
    };
  }
  openInfo(event): void {
    $("#ideaSuggestionStatusInfoPopup").modal('show');
    event.stopPropagation();
  }

  onImageLoad(i:number){

    this.imageShimmer[i] = true;
    this.cd.detectChanges();
  }
  
}

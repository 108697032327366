<div class="user-detail">
    <h4 class="user-name">Hello, {{getName(userData.name)}}</h4>
    <div class="user-enrollment-sec">
      <span class="first class-name">En. No. {{getEnNo()}}</span>
      <span class="class-name text-truncate" title="{{userData.userClass.name}}">Class {{userData.userClass.name}}</span>
    </div>
    <div class="user-enrollment-sec">
      <span class="class-name school-name d-inline-block text-truncate ml-0" *ngIf="primaryGoalName" title="{{primaryGoalName}}">{{primaryGoalName}}</span>
    </div>
    <div *ngIf="assignedBatched?.length">
      <span class="batches-view">
        {{assignedBatched[0]?.name}}
        <ng-container *ngIf="assignedBatched?.length > 1">
          <span class="batch-detail">+{{assignedBatched?.length - 1}} <svg width="12" height="7" viewBox="0 0 12 7" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.5759 0.252705C10.8927 -0.0750661 11.4165 -0.0853601 11.7461 0.229851C11.915 0.391472 12 0.607446 12 0.823626C12 1.0291 11.9234 1.23457 11.7691 1.39434L6.59667 6.74738C6.44067 6.90879 6.22508 7 5.99998 7C5.77508 7 5.55949 6.90879 5.40349 6.74738L0.231056 1.39434C-0.0857035 1.06657 -0.0755655 0.545063 0.254022 0.229851C0.58361 -0.0853601 1.10747 -0.0750661 1.42423 0.252705L5.99998 4.98829L10.5759 0.252705Z" fill="#1D7DEA" /></svg></span>
          <ul class="batches-view-block">
            <li *ngFor="let batch of assignedBatched">{{batch?.name}}</li>
          </ul>
        </ng-container>
      </span>
    </div>
    <div *ngIf="userData?.userCentres?.length">
      <span class="centres-view">
        {{userData?.userCentres[0]?.name}}
        <ng-container *ngIf="userData?.userCentres?.length > 1">
          <span class="batch-detail">+{{userData?.userCentres?.length - 1}} <svg width="12" height="7" viewBox="0 0 12 7" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.5759 0.252705C10.8927 -0.0750661 11.4165 -0.0853601 11.7461 0.229851C11.915 0.391472 12 0.607446 12 0.823626C12 1.0291 11.9234 1.23457 11.7691 1.39434L6.59667 6.74738C6.44067 6.90879 6.22508 7 5.99998 7C5.77508 7 5.55949 6.90879 5.40349 6.74738L0.231056 1.39434C-0.0857035 1.06657 -0.0755655 0.545063 0.254022 0.229851C0.58361 -0.0853601 1.10747 -0.0750661 1.42423 0.252705L5.99998 4.98829L10.5759 0.252705Z" fill="#1D7DEA" /></svg></span>
          <ul class="batches-view-block">
            <li *ngFor="let centre of userData?.userCentres">{{centre?.name}}</li>
          </ul>
        </ng-container>
      </span>
    </div>
  </div>
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpGuideInfoService {
  public pdfId: string = '';
  constructor() { }

}

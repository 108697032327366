import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ALL_CONCEPTS, DATE_FORMAT, FEEDBACK_STATUS, TAB, VALID_ROUTES } from 'src/app/home-page/cards-detail/right-selected-card/my-teachers-feedback/my-teachers-feedback.constant';
import { AlertService } from '../../services/alert.service';
import { EventDataBusService } from '../../services/event-data-bus.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../../app/stores/app.reducers';
declare var $: any;

@Component({
  selector: 'app-feedback-popup',
  templateUrl: './feedback-popup.component.html',
  styleUrls: ['./feedback-popup.component.scss']
})
export class FeedbackPopupComponent implements OnInit {

  private activeSubscription: Subscription = new Subscription();
  public feedbackPopupData: any = {};
  @ViewChild('feedbackModal') modal: ElementRef;
  public formType: string = 'Give';
  public phaseData: any = {};
  public batchId: any = {};
  public description: string = "";
  public currentAlertIndex: number = 0;
  constructor(
    private router: Router,
    private alert: AlertService,
    private eventDataBusService: EventDataBusService,
    private store: Store<fromApp.AppState>,
  ) { }

  ngOnInit(): void {
    this.store.select('userProfile').subscribe((userProfile) => {
      this.batchId = userProfile?.userData?.batchId;
    });
    this.activeSubscription.add(
      this.eventDataBusService.feedbackFormInfo.subscribe((res: any) => {
        if(res?.length){
          res = res.filter(element => !this.eventDataBusService.selectedPopForm.submissionFormId.includes(element?.assignedFormId));
          this.feedbackPopupData = res;
          this.checkAlert();
        }
      })
    )
  }

  checkAlert() {
    const currentUrl = this.router?.url;
    if (VALID_ROUTES?.includes(currentUrl) && this.currentAlertIndex < this.feedbackPopupData?.length) {
      $('#feedbackModal').modal('show');
      if (this.feedbackPopupData[this.currentAlertIndex]?.formType === TAB.phase) {
        this.getMyPlanData();
        this.description = "Phase " + this.feedbackPopupData[this.currentAlertIndex]?.phaseNo;
      } else if (this.feedbackPopupData[this.currentAlertIndex].formType === TAB.month) {
        const date = new Date();
        this.feedbackPopupData[this.currentAlertIndex].startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        this.feedbackPopupData[this.currentAlertIndex].endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        date.setDate(0);
        this.description = date.toLocaleString('en-US', { month: 'long' });
      } else {
        const date = new Date();
        this.feedbackPopupData[this.currentAlertIndex].startDate = new Date(this.feedbackPopupData[this.currentAlertIndex]?.weekStartDate);
        this.feedbackPopupData[this.currentAlertIndex].endDate = new Date(this.feedbackPopupData[this.currentAlertIndex]?.weekEndDate);
        this.description = ALL_CONCEPTS;
      }
    }
  }

  getMyPlanData() {
    this.activeSubscription.add(
      this.eventDataBusService.getMyPlanData().subscribe((res) => {
        const response: any = this.eventDataBusService.parseResponse(res?.data?.myPlan);
        this.getRecentPhaseAndWeek(response);
    }));
  }

  getRecentPhaseAndWeek(myPlanData: any) {
    const phases = myPlanData?.phases;
    for (const phase in phases) {
      if((+phase)+1 === this.feedbackPopupData[this.currentAlertIndex]?.phaseNo) {
        this.feedbackPopupData[this.currentAlertIndex].startDate = phases[phase]?.startDate;
        this.feedbackPopupData[this.currentAlertIndex].endDate = phases[phase]?.endDate;
      }
    }
  }

  getFeedbackForm() {
    if (this.feedbackPopupData[this.currentAlertIndex]?.formType === TAB?.week) {
      this.activeSubscription.add(
        this.eventDataBusService.updateFeedbackPopupSeen(
          this.feedbackPopupData[this.currentAlertIndex]?.formType,
          this.feedbackPopupData[this.currentAlertIndex]?.startDate,
          this.feedbackPopupData[this.currentAlertIndex]?.endDate
        ).subscribe((res: any) => {
          if (res?.code !== 200) {
            this.alert.showErrorMessage(res?.message)
          }
        }, (err: any) => {
          this.alert.showErrorMessage(err?.message);
        }
        )
      );
    }
    let queryParamObj = {
      formType: encodeURI(this.feedbackPopupData[this.currentAlertIndex]?.formType),
      startDate: (new Date(this.feedbackPopupData[this.currentAlertIndex].startDate)).toISOString(),
      endDate: (new Date(this.feedbackPopupData[this.currentAlertIndex].endDate)).toISOString(),
    }
    if(this.feedbackPopupData[this.currentAlertIndex]?.phaseNo) {
      queryParamObj['phaseNo'] = this.feedbackPopupData[this.currentAlertIndex]?.phaseNo
    }
    if (this.feedbackPopupData[this.currentAlertIndex]?.formId) {
      queryParamObj['id'] = this.feedbackPopupData[this.currentAlertIndex]?.formId;
    }
    if(this.feedbackPopupData[this.currentAlertIndex]?.formLayoutId) {
      queryParamObj['formLayoutId'] = this.feedbackPopupData[this.currentAlertIndex]?.formLayoutId;
    }
    if(this.feedbackPopupData[this.currentAlertIndex]?.assignedFormId) {
      queryParamObj['assignedFormId'] = this.feedbackPopupData[this.currentAlertIndex]?.assignedFormId;
    }
    queryParamObj['batchId'] = this.batchId; 

    this.router.navigate([`/my-teachers-feedback`], {
      queryParams: queryParamObj
    });
  }

  closefeedbackPopupData() {
    $('#feedbackModal').modal('hide');
    if (this.feedbackPopupData[this.currentAlertIndex]?.formType === TAB?.week) {
      this.activeSubscription.add(
        this.eventDataBusService.updateFeedbackPopupSeen(
          this.feedbackPopupData[this.currentAlertIndex]?.formType,
          this.feedbackPopupData[this.currentAlertIndex]?.startDate,
          this.feedbackPopupData[this.currentAlertIndex]?.endDate
        ).subscribe((res: any) => {
          if (res?.code !== 200) {
            this.alert.showErrorMessage(res?.message)
          }
        }, (err: any) => {
          this.alert.showErrorMessage(err?.message);
        }
        )
      );
    }
    this.currentAlertIndex++;
    setTimeout(() => {
      this.checkAlert();
    }, 500)
  }

  public get feedbackStatus() {
    return FEEDBACK_STATUS;
  }

  public get tab() {
    return TAB;
  }

  public get dateFormat() {
    return DATE_FORMAT;
  }

  ngOnDestroy(): void {
    this.activeSubscription.unsubscribe();
  }
}

<!--preview modal-->
<div class="modal fade small-modal" tabindex="-1" id="previewModal">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Preview</h3>
        <!-- <img src="/assets/images/help/white-cross.svg" class="cross" /> -->
        <button type="button" class="close zoom-init" (click)="zoomImage.resetZoom()"><img (click)="closeModal()" src="/assets/images/help/white-cross.svg" /></button>
      </div>
      <div class="modal-body">
        <div class="preview zoom-image zoom3">
          <img [hidden]="!imageData" [src]="currentData?.url" #zoomImage="zoom-image" zoom-image class="cross" />
          <video [hidden]="!videoData" [src]="currentData?.url" controls></video>
        </div>
      </div>
      <div class="modal-footer">
        <div class="image-gallery" *ngIf="hasAttachments">
          <div class="owl-carousel owl-theme">
            <div class="item" *ngFor="let attchedImg of helpDataImage; let i=index" [class.active]="currentIndex == i">
              <p (click)="zoomImage.resetZoom()"> 
                <img (click)="previewAttach(attchedImg)" *ngIf="attchedImg?.type === 'image'" [src]="attchedImg?.url">
                <img (click)="previewAttach(attchedImg)" *ngIf="attchedImg?.type === 'video'" src="../../../../../../assets/images/help/bgwith-play.png">
              </p>
            </div>
        </div>            
      </div>
      </div>
      <div [hidden]="!imageData" class="zoomInOut">
        <img class="zoom zoom-in" [ngClass]="zoomImage?.zoomCurrent === zoomImage?.zoomMin ? 'disabled' : ''" (click)="zoomImage.zoomOut()" src="/assets/images/help/zoom-out.svg">
        <span>|</span>
        <img class="zoom zoom-out" [ngClass]="zoomImage?.zoomCurrent === zoomImage?.zoomMax ? 'disabled' : ''" (click)="zoomImage.zoomIn()" src="/assets/images/help/zoom-in.svg">
      </div>
    </div>
  </div>
</div>
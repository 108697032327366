import * as TestOngoingActionList from './test-ongoing.actions';
import {question} from '../../test/test-ongoing/model/question.model';
import * as fromApp from '../app.reducers';
import { Router } from '@angular/router';
declare var window: any;

export interface FeatureState extends fromApp.AppState {
  testOngoing: State
}

export interface State {
    paperTitle: string,
    attemptNo: any,
    questions: {},
    timeLeft: any,
    duration: any;
    startTime: any;
    progress: number,
    testType: any,
    currentSectionId: string,
    currentTypeId: string,
    currentQuestionId: string,
    questionIds: string[],
    minQuestions: number,
    questionsAttempted: number;
    questionsNotAttempted: number;
    questionsVisited: number;
    questionsNotVisited: number;
    questionsReviewed: number;
    numQuestions: number;
    questionsAnsweredReviewed: number;
    paperDetails: any,
    isBackDisable: boolean,
    isSkipDisable: boolean,
    isNextDisable: boolean,
    question_array: any;
    attemptId: string,
    testId: any;
    testStartDate:Date;
    testEndDate:Date;
    serverTimeInitially: number,
    questionImages : any[],
    sectionWiseNumbering : boolean;
    totalSessionTime: string | number;
    showNewInstruction: boolean;
    isAutoProctoring: boolean;
}

const intialState: State = {
    paperTitle: "",
    attemptNo: 0,
    questions: {},
    timeLeft: 0.0,
    duration: 0.0,
    startTime: 0.0,
    progress: 0,
    testType: "",
    currentSectionId: "",
    currentTypeId: "",
    currentQuestionId: "",
    questionIds: [], 
    minQuestions: 0,
    questionsAttempted: 0,
    questionsNotAttempted: 0,
    questionsVisited: 0,
    questionsNotVisited: 0,
    questionsReviewed: 0,
    questionsAnsweredReviewed: 0,
    numQuestions: 0,
    paperDetails: [],
    isBackDisable: false,
    isSkipDisable: false,
    isNextDisable: false,
    question_array: [],
    attemptId: "",
    testId: "",
    testStartDate:new Date(),
    testEndDate:new Date(),
    serverTimeInitially:0,
    questionImages:[],
    sectionWiseNumbering : false,
    totalSessionTime: '0',
    showNewInstruction: false,
    isAutoProctoring: false
};

//function to get first type
function getQuestionType(questions, sectionKey) {
    let qtype = "";
    let flag = -1;
    
    for(let type in questions[sectionKey]) {
        qtype = type;
        flag = flag + 1;

        if(flag == 1) {
            break;
        }
    }
    return qtype;
}

//change visited questions on the basis of count of current question
function visitQuestion(countOfQuestion, visitedQuestions, notVisitedQuestions, notAttempted) {
    let noOfVisitQuestions = visitedQuestions;
    let noOfNotVisitQuestions = notVisitedQuestions;
    let countQuestion = countOfQuestion;

    countQuestion = countQuestion + 1;
    if(countQuestion == 1) {
        noOfVisitQuestions = noOfVisitQuestions + 1;
        noOfNotVisitQuestions = noOfNotVisitQuestions - 1;
        notAttempted = notAttempted + 1;
    }
    return [countQuestion, noOfVisitQuestions, noOfNotVisitQuestions, notAttempted];
}

//update time
function updateTime(stateTimeLeft, timeLeft,  stateQuestionTimeSpent) {
    stateTimeLeft = timeLeft;
    stateQuestionTimeSpent = stateQuestionTimeSpent + stateTimeLeft - timeLeft;
    return [stateTimeLeft, stateQuestionTimeSpent];
}

// mark and review without answer
function markAndReviewWithoutAnswer(isAttempted, isAnsweredReviewed, isMarked, questionsReviewed,
     questionsAnsweredReviewed, questionsNotAttempted, questionsAttempted) {
    if (isAttempted) {
        isAttempted = false;
        questionsAttempted = questionsAttempted - 1;
        questionsNotAttempted = questionsNotAttempted + 1;
    }

    if (isAnsweredReviewed) {
        isAnsweredReviewed = false;
        questionsAnsweredReviewed = questionsAnsweredReviewed - 1;
        questionsNotAttempted = questionsNotAttempted + 1;
    }

    if (!isMarked) {
        isMarked = true;
        questionsReviewed = questionsReviewed + 1;
        questionsNotAttempted = questionsNotAttempted - 1;
    } else if (isMarked) {
        isMarked = false;
        questionsReviewed -= 1;
        if (!isAnsweredReviewed && !isAttempted) {
            questionsNotAttempted += 1;
        }
    }
    return [isAttempted, isAnsweredReviewed, isMarked, questionsReviewed,
        questionsAnsweredReviewed, questionsNotAttempted, questionsAttempted];
}

// mark and review with answer
function markAndReviewWithAnswer(isAttempted, isAnsweredReviewed, isMarked, questionsReviewed,
    questionsAnsweredReviewed, questionsNotAttempted, questionsAttempted) {

    if (isAttempted && !isAnsweredReviewed) {
        isAttempted = false;
        questionsAttempted = questionsAttempted - 1;
        questionsNotAttempted = questionsNotAttempted + 1;
    }

    if (isMarked) {
        isMarked = false;
        questionsReviewed = questionsReviewed - 1;
        questionsNotAttempted = questionsNotAttempted + 1;
    }

    if (!isAnsweredReviewed) {
        isAnsweredReviewed = true;
        questionsAnsweredReviewed = questionsAnsweredReviewed + 1;
        questionsNotAttempted = questionsNotAttempted - 1;
    }
    return [isAttempted, isAnsweredReviewed, isMarked, questionsReviewed, questionsAnsweredReviewed,
         questionsNotAttempted, questionsAttempted];
}

function answeredMarkedCpp(isAttempted, isAnsweredReviewed, isMarked, questionsReviewed,
    questionsAnsweredReviewed, questionsNotAttempted, questionsAttempted) {
    if (isAnsweredReviewed) {
        isAttempted = true;
        isAnsweredReviewed = false;
        questionsAnsweredReviewed -= 1;
        questionsAttempted += 1;
    } else if (isAttempted) {
        isAttempted = false;
        isAnsweredReviewed = true;
        questionsAttempted = questionsAttempted - 1;
        questionsAnsweredReviewed = questionsAnsweredReviewed + 1;
    } else if (isMarked) {
        isMarked = false;
        questionsReviewed -= 1;
        questionsNotAttempted += 1;
    }
    return [isAttempted, isAnsweredReviewed, isMarked, questionsReviewed, questionsAnsweredReviewed,
         questionsNotAttempted, questionsAttempted];
}

// mark and review with answer
function saveUpdationsCpp(isAttempted, isAnsweredReviewed, isMarked, questionsReviewed,
    questionsAnsweredReviewed, questionsNotAttempted, questionsAttempted) {
    if (isAttempted) {
        isAttempted = false;
        questionsAttempted = questionsAttempted - 1;
        questionsNotAttempted = questionsNotAttempted + 1;
    } else if (isMarked) {
        isMarked = false;
        isAnsweredReviewed = true;
        questionsAnsweredReviewed += 1;
        questionsReviewed -= 1;
    } else if (!isAttempted && !isMarked) {
        isAttempted = true;
        isMarked = false;
        isAnsweredReviewed = false;
        questionsAttempted += 1;
    }

    return [isAttempted, isAnsweredReviewed, isMarked, questionsReviewed, questionsAnsweredReviewed,
         questionsNotAttempted, questionsAttempted];
}


//save question
function saveQuestion(isAttempted, questionsNotAttempted, questionsAttempted) {
    if (!isAttempted) {
        isAttempted = true;
        questionsAttempted = questionsAttempted + 1;
        questionsNotAttempted = questionsNotAttempted - 1;
    }
    return [isAttempted, questionsNotAttempted, questionsAttempted];
}

//not save question
function notSaveQuestion(isAttempted, questionsNotAttempted, questionsAttempted) {
    if(isAttempted ) {
        isAttempted = false;
        questionsAttempted = questionsAttempted - 1;
        questionsNotAttempted = questionsNotAttempted + 1;

    }
    return [isAttempted, questionsNotAttempted, questionsAttempted];
}

//remove mark state
function removeMarkState(isAnsweredReviewed, isMarked, questionsReviewed, questionsAnsweredReviewed, questionsNotAttempted) {
    if(isAnsweredReviewed ) {
        isAnsweredReviewed = false;
        questionsAnsweredReviewed = questionsAnsweredReviewed - 1;
        questionsNotAttempted = questionsNotAttempted + 1;
    } else if(isMarked ) {
        isMarked = false;
        questionsReviewed = questionsReviewed - 1;
        questionsNotAttempted = questionsNotAttempted + 1;
    }
    return [isAnsweredReviewed, isMarked, questionsReviewed, questionsAnsweredReviewed, questionsNotAttempted];
}

export function testOngoingReducer(state = intialState, action: TestOngoingActionList.TestOngoingActionList) {
    switch (action.type) {
        case TestOngoingActionList.START_TEST_DETAILS:
            //change count of current question
            action.payload["questions"][action.payload["currentSectionId"]][action.payload["currentTypeId"]][action.payload["currentQuestionId"]].countSeen = 1;
            return {
                ...state,
                startTime: action.payload["startTime"],
                duration: action.payload["totalDuration"],
                attemptId: action.payload["attemptId"],
                attemptNo: action.payload["attemptNo"],
                question_array: action.payload["question_array"],
                timeLeft: action.payload["totalDuration"],
                minQuestions: action.payload["minQuestions"],
                paperDetails: action.payload["details"],
                paperTitle: action.payload["paperTitle"],
                questions: action.payload["questions"],
                progress: action.payload["progress"],
                questionIds: action.payload["questionIds"],
                testType: action.payload["testType"],
                currentSectionId: action.payload["currentSectionId"],
                currentTypeId: action.payload["currentTypeId"],
                currentQuestionId: action.payload["currentQuestionId"],
                numQuestions: action.payload["questionsNotAttempted"],
                questionsAttempted: action.payload["questionsAttempted"],
                questionsNotAttempted: action.payload["questionsNotAttempted"],
                questionsVisited: action.payload["questionsVisited"],
                questionsNotVisited: action.payload["questionsNotVisited"],
                questionsReviewed: action.payload["questionsReviewed"],
                questionsAnsweredReviewed: action.payload["questionsAnsweredReviewed"],
                testStartDate:action.payload['testStartDate'],
                testEndDate:action.payload['testEndDate'],
                isBackDisable: true,
                isSkipDisable: false,
                isNextDisable: true,
                serverTimeInitially:action.payload["serverTimeInitially"],
                questionImages : action.payload["questionImages"],
                sectionWiseNumbering : action.payload["sectionWiseNumbering"],
                totalSessionTime: 0,
                showNewInstruction: action.payload['showNewInstruction'],
                isAutoProctoring: action.payload['isAutoProctoring']
            }
        case TestOngoingActionList.GET_QUESTIONS: 
            return {
                ...state,
                isBackDisable: true,
                isSkipDisable: false,
                isNextDisable: true
            }
        case TestOngoingActionList.UPDATE_SECTION_TYPE:
            //update time
            // let currentTime = parseFloat(state.timeLeft) - parseFloat(action.payload["timeSpent"]);
            state.timeLeft = parseFloat(action.payload["timeSpent"]);
            state.testId = String(action.payload["testId"]);
            
            //update question
            state.currentTypeId = action.payload["currentTypeId"];
            state.currentQuestionId = state.questions[state.currentSectionId][action.payload["currentTypeId"]]["questionTracker"][0];

            //changes to be done on visiting a question
            let pquestion = state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId];
            if(state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId]["countSeen"] == 0) {
                state.questions[state.currentSectionId][state.currentTypeId]['notVisited'] = state.questions[state.currentSectionId][state.currentTypeId]['notVisited'] - 1;
            }

            if(pquestion.countSeen===0  &&pquestion.isAttempted == false) {
                state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
            }
            //changes to be done on visiting a question
            [state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId].countSeen, state.questionsVisited, state.questionsNotVisited,state.questionsNotAttempted] = visitQuestion(state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId].countSeen, state.questionsVisited, state.questionsNotVisited, state.questionsNotAttempted);
            return {
                ...state,     
                isBackDisable: (state.questionIds[0] === state.questions[state.currentSectionId][action.payload["currentTypeId"]]["questionTracker"][0])?true: false,
                isSkipDisable: (state.questionIds[state.questionIds.length - 1] === state.questions[state.currentSectionId][action.payload["currentTypeId"]]["questionTracker"][0])?true: false,
                isNextDisable: true
            }
        case TestOngoingActionList.UPDATE_SECTION:
            //update time
            // currentTime = parseFloat(state.timeLeft) - parseFloat(action.payload["timeSpent"]);
            state.timeLeft = parseFloat(action.payload["timeSpent"]);
            state.testId = String(action.payload["testId"]);

            //update question
            let qtype = getQuestionType(state.questions, action.payload["currentSectionId"]);
            state.currentSectionId = action.payload["currentSectionId"];

            //get section type on the basis of paper
            if(action.payload["type"] == 2) {
                state.currentTypeId = action.payload["currentTypeId"];
            } else {
                state.currentTypeId = qtype;
            }
            
            state.currentQuestionId = state.questions[action.payload["currentSectionId"]][state.currentTypeId]["questionTracker"][0];
            let cquestion = state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId];
            if(state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId]["countSeen"] == 0) {
                state.questions[state.currentSectionId][state.currentTypeId]['notVisited'] = state.questions[state.currentSectionId][state.currentTypeId]['notVisited'] - 1;
            }

            if(cquestion.countSeen===0  && cquestion.isAttempted == false) {
                state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
            }
            //changes to be done on visiting a question
            [state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId].countSeen, state.questionsVisited, state.questionsNotVisited,state.questionsNotAttempted] = visitQuestion(state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId].countSeen, state.questionsVisited, state.questionsNotVisited, state.questionsNotAttempted);            
            return {
                ...state,
                isBackDisable: (state.questionIds[0] === state.questions[action.payload["currentSectionId"]][qtype]["questionTracker"][0])?true: false,
                isSkipDisable: false,
                isNextDisable: true
            }
        case TestOngoingActionList.UPDATE_QUESTION:
            // currentTime = parseFloat(state.timeLeft) - parseFloat(action.payload["timeSpent"]);
            state.timeLeft = parseFloat(action.payload["timeSpent"]);
            state.testId = String(action.payload["testId"]);

            //update question
            state.currentSectionId = action.payload["currentSectionId"];
            state.currentTypeId = action.payload["currentTypeId"];
            state.currentQuestionId = action.payload["currentQuestionId"];

            let question = state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId];
            if(state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId].countSeen == 0) {
                state.questions[state.currentSectionId][state.currentTypeId]['notVisited'] = state.questions[state.currentSectionId][state.currentTypeId]['notVisited'] - 1;
            }

            if(question.countSeen===0  && question.isAttempted == false) {
                state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
            }

            //changes to be done on visiting a question
            [state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId].countSeen, state.questionsVisited, state.questionsNotVisited, state.questionsNotAttempted] = visitQuestion(state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId].countSeen, state.questionsVisited, state.questionsNotVisited, state.questionsNotAttempted);    
            return {
                ...state,
                isBackDisable: 
                state.questionIds[0] === action.payload["currentQuestionId"]?true: false,
                isSkipDisable: 
                (state.questionIds[state.questionIds.length - 1] === 
                    action.payload["currentQuestionId"])?true: false,
                isNextDisable: true                
            }
        case TestOngoingActionList.UPDATE_QUESTION_TIME:
            state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId].timeSpent = parseFloat(action.payload['timeSpent']);
            state.timeLeft = parseFloat(state.timeLeft) - parseFloat(action.payload["time"]);

            return {
                ...state
            }
        case TestOngoingActionList.MARK_REVIEW:
            //update time
            state.timeLeft = parseFloat(action.payload["timeSpent"]);
            
            //mark question
            state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId].isMarkedForReview = true;
            return {
                ...state
            }
        case TestOngoingActionList.UNMARK_FOR_REVIEW:
            //update time
            state.timeLeft = parseFloat(action.payload["timeSpent"]);
            
            //unmark question
            state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId].isMarkedForReview = false;
            return {
                ...state
            }
        case TestOngoingActionList.UPDATE_PROGRESS: {
            //update time
            
            state.timeLeft = parseFloat(action.payload["timeSpent"]);
            state.testId = String(action.payload["testId"]);

            let progress = state.progress;
            let numQues = state.questionIds.length;
            let options = action.payload["options"];
            let currentQuestion = state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId];

            if (options.length > 0 && (currentQuestion.questionCode == 6 || currentQuestion.questionCode == 7 || currentQuestion.questionCode == 3)) {
                currentQuestion.answerKey = options[0];
                if (options[0] != null && options[0] !== "" && currentQuestion.isAttempted == false && !currentQuestion.isAnsweredMarkedForReview) {
                    //update progress
                    if (currentQuestion.isAnsweredMarkedForReview == false) {
                        progress = ((progress * numQues) + 100) / (numQues);
                    }
                    //save question and increase progress
                    if (!currentQuestion.isAttempted && !currentQuestion.isMarkedForReview && !currentQuestion.isAnsweredMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }
                    if (!currentQuestion.isAttempted && currentQuestion.isMarkedForReview && !(window.location.pathname.includes('jee-main'))) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }

                    if(!window.location.pathname.includes('jee-main') || !currentQuestion.isAnsweredMarkedForReview) {
                        let value = saveQuestion(currentQuestion?.isAttempted, state.questionsNotAttempted, state.questionsAttempted);
                        currentQuestion.isAttempted = value[0];
                        state.questionsNotAttempted = value[1];
                        state.questionsAttempted = value[2];
                        // [currentQuestion?.isAttempted, state.questionsNotAttempted, state.questionsAttempted] = saveQuestion(currentQuestion?.isAttempted, state.questionsNotAttempted, state.questionsAttempted);
                    }

                    //state.questions[state.currentSectionId][state.currentTypeId]['notVisited'] = state.questions[state.currentSectionId][state.currentTypeId]['notVisited'] - 1;
                } else if ((options[0] == null || options[0] === "") && (currentQuestion.isAttempted && !currentQuestion.isAnsweredMarkedForReview) ) {
                    //unsave question and decrease progress
                    [currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted] = notSaveQuestion(currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted);

                    if (currentQuestion.isAttempted == false) {
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                    }

                    progress = ((progress * numQues) - 100) / (numQues);
                } else if (options.length === 0 && (currentQuestion.isAnsweredMarkedForReview) ) {
                    // unsave question and decrease progress
                    currentQuestion.isAnsweredMarkedForReview = !currentQuestion.isAnsweredMarkedForReview;
                    currentQuestion.isAttempted = false;
                    state.questionsNotAttempted += 1;
                    state.questionsAnsweredReviewed -= 1;

                    if (currentQuestion.isAnsweredMarkedForReview === false) {
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
                    }

                    progress = ((progress * numQues) - 100) / (numQues);
                }
            } else if (currentQuestion.questionCode == 0 || currentQuestion.questionCode == 8 || currentQuestion.questionCode == 1 || currentQuestion.questionCode == 5 || currentQuestion.questionCode == 2) {
                //updating progress if not attempted before
                if (options.length > 0 && currentQuestion.isAttempted == false && !currentQuestion.isAnsweredMarkedForReview) {
                    //update progress
                    if (currentQuestion.isAnsweredMarkedForReview == false) {
                        progress = ((progress * numQues) + 100) / (numQues);
                    }

                    //save question and increase progress
                    if (!currentQuestion.isAttempted && !currentQuestion.isMarkedForReview && !currentQuestion.isAnsweredMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }
                    if (!currentQuestion.isAttempted && currentQuestion.isMarkedForReview && !(window.location.pathname.includes('jee-main'))) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }

                    if(!window.location.pathname.includes('jee-main') || !currentQuestion.isAnsweredMarkedForReview) {
                        let value = saveQuestion(currentQuestion?.isAttempted, state.questionsNotAttempted, state.questionsAttempted);
                        currentQuestion.isAttempted = value[0];
                        state.questionsNotAttempted = value[1];
                        state.questionsAttempted = value[2];
                        // [currentQuestion?.isAttempted, state.questionsNotAttempted, state.questionsAttempted] = saveQuestion(currentQuestion?.isAttempted, state.questionsNotAttempted, state.questionsAttempted);
                    }
                } else if (options.length == 0 && (currentQuestion.isAttempted && !currentQuestion.isAnsweredMarkedForReview) ) {
                    //unsave question and decrease progress
                    [currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted] = notSaveQuestion(currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted);

                    if (currentQuestion.isAttempted == false) {
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                    }

                    progress = ((progress * numQues) - 100) / (numQues);
                } else if (options.length === 0 && (currentQuestion.isAnsweredMarkedForReview) ) {
                    // unsave question and decrease progress
                    currentQuestion.isAnsweredMarkedForReview = !currentQuestion.isAnsweredMarkedForReview;
                    currentQuestion.isAttempted = false;
                    state.questionsNotAttempted += 1;
                    state.questionsAnsweredReviewed -= 1;

                    if (currentQuestion.isAnsweredMarkedForReview === false) {
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
                    }

                    progress = ((progress * numQues) - 100) / (numQues);
                }
            }

            if (currentQuestion.isMarkedForReview ) {
                state.questions[state.currentSectionId][state.currentTypeId]['marked'] = state.questions[state.currentSectionId][state.currentTypeId]['marked'] - 1;
                if (!window.location.pathname.includes('jee-main')) {
                    state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                } else {
                    state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] + 1;
                }
            }

            if (currentQuestion.isAnsweredMarkedForReview && !(window.location.pathname.includes('jee-main'))) {
                if (options && options.length) {
                    state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
                    state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] + 1;
                }
            } else if(currentQuestion.isAnsweredMarkedForReview && (window.location.pathname.includes('jee-main'))) {
                state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
                state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] + 1;
            }

            if (currentQuestion.isAnsweredMarkedForReview && options && options.length) {
                currentQuestion.isAnsweredMarkedForReview = false;
                currentQuestion.isAttempted = true;
                state.questionsAttempted += 1;
                state.questionsAnsweredReviewed -= 1;
            } else {
                // change marked state
                [currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted] = removeMarkState(currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted);
            }
            return {
                ...state,
                progress: progress
            }
        }
        case TestOngoingActionList.MARK_PROGRESS: {
            //update time
            let progressVal = state.progress;
            let numQuesVal = state.questionIds.length;
            state.timeLeft = parseFloat(action.payload["timeSpent"]);
            state.testId = String(action.payload["testId"]);
            let options = action.payload["options"];
            let currentQuestion = state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId];

            if (options.length > 0 && (currentQuestion.questionCode == 6 || currentQuestion.questionCode == 7 || currentQuestion.questionCode == 3)) {
                currentQuestion.answerKey = options[0];
                if (options[0] != null) {
                    //update progress
                    if (currentQuestion.isAttempted == false && currentQuestion.isAnsweredMarkedForReview == false) {
                        progressVal = ((progressVal * numQuesVal) + 100) / (numQuesVal);
                    }

                    if (currentQuestion.isMarkedForReview ) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] = state.questions[state.currentSectionId][state.currentTypeId]['marked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAttempted  && !currentQuestion.isAnsweredMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAnsweredMarkedForReview == false) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }

                    //call mark and review with answer function
                    [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] = markAndReviewWithAnswer(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted);
                } else {
                    //decrease progress
                    if (currentQuestion.isAnsweredMarkedForReview  || currentQuestion.isAttempted ) {
                        progressVal = ((progressVal * numQuesVal) - 100) / (numQuesVal);
                    }

                    if (currentQuestion.isAnsweredMarkedForReview ) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAttempted ) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isMarkedForReview == false) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] = state.questions[state.currentSectionId][state.currentTypeId]['marked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }

                    //call mark and review without answer function
                    [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] = markAndReviewWithoutAnswer(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted);
                }
            } else if (currentQuestion.questionCode == 0 || currentQuestion.questionCode == 8 || currentQuestion.questionCode == 1 || currentQuestion.questionCode === 5 || currentQuestion.questionCode === 2) {
                if (options.length > 0) {
                    //update progress
                    if (currentQuestion.isAttempted == false && currentQuestion.isAnsweredMarkedForReview == false) {
                        progressVal = ((progressVal * numQuesVal) + 100) / (numQuesVal);
                    }

                    if (currentQuestion.isMarkedForReview ) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] = state.questions[state.currentSectionId][state.currentTypeId]['marked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAttempted  && !currentQuestion.isAnsweredMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAnsweredMarkedForReview == false) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }

                    //call mark and review with answer function
                    [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] = markAndReviewWithAnswer(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted);
                } else {
                    //decrease progress
                    if (currentQuestion.isAnsweredMarkedForReview  || currentQuestion.isAttempted ) {
                        progressVal = ((progressVal * numQuesVal) - 100) / (numQuesVal);
                    }

                    if (currentQuestion.isAnsweredMarkedForReview ) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAttempted ) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isMarkedForReview == false) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] = state.questions[state.currentSectionId][state.currentTypeId]['marked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }

                    //call mark and review without answer function
                    [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] = markAndReviewWithoutAnswer(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted);
                }
            }
            return {
                ...state,
                progress: progressVal
            }
        }
        case TestOngoingActionList.UPDATE_PROGRESS_CPP: {
            // update time
            state.timeLeft = parseFloat(action.payload['timeSpent']);
            state.testId = String(action.payload['testId']);

            let progress = state.progress;
            const numQues = state.questionIds.length;
            const options = action.payload['options'];
            const currentQuestion = state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId];

            if (options.length > 0 && (currentQuestion.questionCode === 6 || currentQuestion.questionCode === 7 ||
                 currentQuestion.questionCode === 3)) {
                currentQuestion.answerKey = options[0];
                if (options[0] != null && options[0] !== '' &&
                !(currentQuestion.isAttempted || currentQuestion.isAnsweredMarkedForReview)) {
                    // update progress
                    if (!currentQuestion.isAnsweredMarkedForReview) {
                        progress = ((progress * numQues) + 100) / (numQues);
                    }
                    // save question and increase progress
                    if (!currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] =
                         state.questions[state.currentSectionId][state.currentTypeId]['answered'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                         state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }
                    if (currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['marked'] - 1;
                    }
                    // call mark and review with answer function
                    [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview,
                        state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] =
                        saveUpdationsCpp(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview,
                            currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed,
                            state.questionsNotAttempted, state.questionsAttempted);
                } else if ((options[0] == null || options[0] === '') &&
                    (currentQuestion.isAttempted || currentQuestion.isAnsweredMarkedForReview)) {
                    // unsave question and decrease progress
                    [currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted] =
                     notSaveQuestion(currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted);

                    if (!currentQuestion.isAttempted) {
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                         state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] =
                         state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                    }

                    progress = ((progress * numQues) - 100) / (numQues);
                }
            } else if (currentQuestion.questionCode === 0 || currentQuestion.questionCode === 8 ||
                currentQuestion.questionCode === 1 || currentQuestion.questionCode === 5 || currentQuestion.questionCode === 2
                || currentQuestion.questionCode === 4) {
                // updating progress if not attempted before
                if (options.length > 0 && !(currentQuestion.isAttempted || currentQuestion.isAnsweredMarkedForReview)) {
                    // update progress
                    if (!currentQuestion.isAnsweredMarkedForReview) {
                        progress = ((progress * numQues) + 100) / (numQues);
                    }

                    // save question and increase progress
                    if (!currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] =
                         state.questions[state.currentSectionId][state.currentTypeId]['answered'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                         state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }
                    if (currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['marked'] - 1;
                    }
                    // call mark and review with answer function
                    [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview,
                        state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] =
                        saveUpdationsCpp(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview,
                            currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed,
                            state.questionsNotAttempted, state.questionsAttempted);
                } else if (options.length === 0 && (currentQuestion.isAttempted || currentQuestion.isAnsweredMarkedForReview)) {
                    // unsave question and decrease progress
                    [currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted] =
                    notSaveQuestion(currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted);

                    if (!currentQuestion.isAttempted) {
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                         state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] =
                         state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                    }

                    progress = ((progress * numQues) - 100) / (numQues);
                }
            }
            return {
                ...state,
                progress: progress
            };
        }
        case TestOngoingActionList.MARK_PROGRESS_CPP: {
            // update time
            let progressVal = state.progress;
            const numQuesVal = state.questionIds.length;
            state.timeLeft = parseFloat(action.payload['timeSpent']);
            state.testId = String(action.payload['testId']);
            const options = action.payload['options'];
            const currentQuestion = state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId];

            if (options.length > 0 && (currentQuestion.questionCode === 6 || currentQuestion.questionCode === 7 ||
                 currentQuestion.questionCode === 3)) {
                currentQuestion.answerKey = options[0];
                if (options[0] != null) {
                    // update progress
                    if (!currentQuestion.isAttempted && !currentQuestion.isAnsweredMarkedForReview) {
                        progressVal = ((progressVal * numQuesVal) + 100) / (numQuesVal);
                    }

                    if (currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAttempted && !currentQuestion.isAnsweredMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] + 1;
                    }

                    if (currentQuestion.isAnsweredMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] + 1;
                    }
                    if (!currentQuestion.isAnsweredMarkedForReview && !currentQuestion.isAttempted && !currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }

                    if (!currentQuestion.isAnsweredMarkedForReview && !currentQuestion.isAttempted && !currentQuestion.isMarkedForReview) {
                        // call mark and review without answer function
                    [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview,
                        state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] =
                            markAndReviewWithoutAnswer(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview,
                                currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed,
                                state.questionsNotAttempted, state.questionsAttempted);
                    } else {
                        // call mark and review with answer function
                        [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview,
                        state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] =
                            answeredMarkedCpp(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview,
                                currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed,
                                state.questionsNotAttempted, state.questionsAttempted);
                    }
                } else {
                    // decrease progress
                    if (currentQuestion.isAnsweredMarkedForReview || currentQuestion.isAttempted) {
                        progressVal = ((progressVal * numQuesVal) - 100) / (numQuesVal);
                    }

                    if (currentQuestion.isAnsweredMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAttempted) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['marked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }
                    if (!currentQuestion.isAnsweredMarkedForReview && !currentQuestion.isAttempted && !currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }


                    // call mark and review without answer function
                    [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview,
                    state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] =
                        markAndReviewWithoutAnswer(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview,
                            currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed,
                            state.questionsNotAttempted, state.questionsAttempted);
                }
            } else if (currentQuestion.questionCode === 0 || currentQuestion.questionCode === 8 || currentQuestion.questionCode === 1 ||
                 currentQuestion.questionCode === 5 || currentQuestion.questionCode === 2 || currentQuestion.questionCode === 4) {
                if (options.length > 0) {
                    // update progress
                    if (currentQuestion.isAttempted === false && currentQuestion.isAnsweredMarkedForReview === false) {
                        progressVal = ((progressVal * numQuesVal) + 100) / (numQuesVal);
                    }

                    if (currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAttempted && !currentQuestion.isAnsweredMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] + 1;
                    }

                    if (currentQuestion.isAnsweredMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] + 1;
                    }
                    if (!currentQuestion.isAnsweredMarkedForReview && !currentQuestion.isAttempted && !currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }

                    if (!currentQuestion.isAnsweredMarkedForReview && !currentQuestion.isAttempted && !currentQuestion.isMarkedForReview) {
                        // call mark and review without answer function
                    [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview,
                        state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] =
                            markAndReviewWithoutAnswer(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview,
                                currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed,
                                state.questionsNotAttempted, state.questionsAttempted);
                    } else {
                        // call mark and review with answer function
                        [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview,
                        state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] =
                            answeredMarkedCpp(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview,
                                currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed,
                                state.questionsNotAttempted, state.questionsAttempted);
                    }
                } else {
                    // decrease progress
                    if (currentQuestion.isAnsweredMarkedForReview || currentQuestion.isAttempted) {
                        progressVal = ((progressVal * numQuesVal) - 100) / (numQuesVal);
                    }

                    if (currentQuestion.isAnsweredMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAttempted) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['marked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }
                    if (!currentQuestion.isAnsweredMarkedForReview && !currentQuestion.isAttempted && !currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }


                    // call mark and review without answer function
                    [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview,
                    state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] =
                        markAndReviewWithoutAnswer(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview,
                            currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed,
                            state.questionsNotAttempted, state.questionsAttempted);
                }
            }
            return {
                ...state,
                progress: progressVal
            }
        }

        case TestOngoingActionList.UPDATE_PROGRESS_JEEADV: {
            // update time
            state.timeLeft = parseFloat(action.payload['timeSpent']);
            state.testId = String(action.payload['testId']);

            // tslint:disable-next-line: no-shadowed-variable
            let progress = state.progress;
            // tslint:disable-next-line: no-shadowed-variable
            const numQues = state.questionIds.length;
            // tslint:disable-next-line: no-shadowed-variable
            const options = action.payload['options'];
            // tslint:disable-next-line: no-shadowed-variable
            const currentQuestion = state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId];

            if (options.length > 0 && (currentQuestion.questionCode === 6 || currentQuestion.questionCode === 7 ||
                currentQuestion.questionCode === 3)) {
                currentQuestion.answerKey = options[0];
                if (options[0] != null && options[0] !== '' && !currentQuestion.isAttempted) {
                    // update progress
                    if (!currentQuestion.isAnsweredMarkedForReview) {
                        progress = ((progress * numQues) + 100) / (numQues);
                    }
                    if (!currentQuestion.isAttempted && !currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['answered'] + 1;
                        if (currentQuestion.isAnsweredMarkedForReview) {
                            state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] =
                                state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
                        } else {
                            state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                                state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                        }
                    }
                    if (!currentQuestion.isAttempted && currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }

                    if (!currentQuestion.isAnsweredMarkedForReview) {

                        [currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted] = saveQuestion(currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted);
                    } else {
                        currentQuestion.isAttempted = true;
                        currentQuestion.isAnsweredMarkedForReview = false;
                        state.questionsAnsweredReviewed--;
                        state.questionsAttempted++;
                    }

                    //state.questions[state.currentSectionId][state.currentTypeId]['notVisited'] = state.questions[state.currentSectionId][state.currentTypeId]['notVisited'] - 1;
                } else if ((options[0] == null || options[0] === "") && currentQuestion.isAttempted ) {
                    //unsave question and decrease progress
                    [currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted] = notSaveQuestion(currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted);

                    if (currentQuestion.isAttempted == false) {
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                    }

                    progress = ((progress * numQues) - 100) / (numQues);
                }
            } else if (currentQuestion.questionCode === 0 || currentQuestion.questionCode === 8 ||
                currentQuestion.questionCode === 1 || currentQuestion.questionCode === 5 || currentQuestion.questionCode === 2) {
                // updating progress if not attempted before
                if (options.length > 0 && !currentQuestion.isAttempted) {
                    // update progress
                    if (!currentQuestion.isAnsweredMarkedForReview) {
                        progress = ((progress * numQues) + 100) / (numQues);
                    }

                    // save question and increase progress
                    if (!currentQuestion.isAttempted && !currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['answered'] + 1;
                        if (currentQuestion.isAnsweredMarkedForReview) {
                            currentQuestion.isAnsweredMarkedForReview = false;
                            state.questionsAnsweredReviewed--;
                            state.questionsNotAttempted++;
                            state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] =
                                state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
                        } else {
                            state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] =
                                state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                        }

                        [currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted] =
                            saveQuestion(currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted);
                    }
                    if (!currentQuestion.isAttempted && currentQuestion.isMarkedForReview) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] =
                            state.questions[state.currentSectionId][state.currentTypeId]['marked'] - 1;
                        // call mark and review with answer function
                        [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview,
                        state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted]
                            = markAndReviewWithAnswer(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview,
                                currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed,
                                state.questionsNotAttempted, state.questionsAttempted);
                    }

                } else if (options.length == 0 && currentQuestion.isAttempted ) {
                    //unsave question and decrease progress
                    [currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted] = notSaveQuestion(currentQuestion.isAttempted, state.questionsNotAttempted, state.questionsAttempted);

                    if (currentQuestion.isAttempted == false) {
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                    }

                    progress = ((progress * numQues) - 100) / (numQues);
                }
            }

            // if (currentQuestion.isMarkedForReview ) {
            //     state.questions[state.currentSectionId][state.currentTypeId]['marked'] = state.questions[state.currentSectionId][state.currentTypeId]['marked'] - 1;
            //     if (!window.location.pathname.includes('jee-main')) {
            //         state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
            //     } else {
            //         state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] + 1;
            //     }
            // }

            // if (currentQuestion.isAnsweredMarkedForReview ) {
            //     state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
            //     state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
            // }

            // [currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted] = removeMarkState(currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted);
            return {
                ...state,
                progress: progress
            }
        }

        case TestOngoingActionList.MARK_PROGRESS_JEEADV: {
            //update time
            let progressVal = state.progress;
            let numQuesVal = state.questionIds.length;
            state.timeLeft = parseFloat(action.payload["timeSpent"]);
            state.testId = String(action.payload["testId"]);
            let options = action.payload["options"];
            let currentQuestion = state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId];

            if (options.length > 0 && (currentQuestion.questionCode == 6 || currentQuestion.questionCode == 7 || currentQuestion.questionCode == 3)) {
                currentQuestion.answerKey = options[0];
                if (options[0] != null) {
                    //update progress
                    if (currentQuestion.isAttempted == false && currentQuestion.isAnsweredMarkedForReview == false) {
                        progressVal = ((progressVal * numQuesVal) + 100) / (numQuesVal);
                    }

                    if (currentQuestion.isMarkedForReview ) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] = state.questions[state.currentSectionId][state.currentTypeId]['marked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAttempted ) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAnsweredMarkedForReview == false) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }

                    //call mark and review with answer function
                    [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] = markAndReviewWithAnswer(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted);
                } else {
                    //decrease progress
                    if (currentQuestion.isAnsweredMarkedForReview  || currentQuestion.isAttempted ) {
                        progressVal = ((progressVal * numQuesVal) - 100) / (numQuesVal);
                    }

                    if (currentQuestion.isAnsweredMarkedForReview ) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAttempted ) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isMarkedForReview == false) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] = state.questions[state.currentSectionId][state.currentTypeId]['marked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }

                    //call mark and review without answer function
                    [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] = markAndReviewWithoutAnswer(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted);
                }
            } else if (currentQuestion.questionCode == 0 || currentQuestion.questionCode == 8 || currentQuestion.questionCode == 1 || currentQuestion.questionCode === 5 || currentQuestion.questionCode === 2) {
                if (options.length > 0) {
                    //update progress
                    if (currentQuestion.isAttempted == false && currentQuestion.isAnsweredMarkedForReview == false) {
                        progressVal = ((progressVal * numQuesVal) + 100) / (numQuesVal);
                    }

                    if (currentQuestion.isMarkedForReview ) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] = state.questions[state.currentSectionId][state.currentTypeId]['marked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAttempted ) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAnsweredMarkedForReview == false) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }

                    //call mark and review with answer function
                    [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] = markAndReviewWithAnswer(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted);
                } else {
                    //decrease progress
                    if (currentQuestion.isAnsweredMarkedForReview  || currentQuestion.isAttempted ) {
                        progressVal = ((progressVal * numQuesVal) - 100) / (numQuesVal);
                    }

                    if (currentQuestion.isAnsweredMarkedForReview ) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isAttempted ) {
                        state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                    }

                    if (currentQuestion.isMarkedForReview == false) {
                        state.questions[state.currentSectionId][state.currentTypeId]['marked'] = state.questions[state.currentSectionId][state.currentTypeId]['marked'] + 1;
                        state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] - 1;
                    }

                    //call mark and review without answer function
                    [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted] = markAndReviewWithoutAnswer(currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview, state.questionsReviewed, state.questionsAnsweredReviewed, state.questionsNotAttempted, state.questionsAttempted);
                }
            }
            return {
                ...state,
                progress: progressVal
            }
        }

        case TestOngoingActionList.CLEAR_RESPONSE: {
            //update time
            let progressVal = state.progress;
            let numQuesVal = state.questionIds.length;
            state.timeLeft = parseFloat(action.payload["timeSpent"]);
            state.testId = String(action.payload["testId"]);
            let options = action.payload["options"];
            let newNotAnswered, newMarked, newAnsweredMarked, newAnswered;
            let currentQuestion = state.questions[state.currentSectionId][state.currentTypeId][state.currentQuestionId];
            if(currentQuestion.questionCode === 6 || currentQuestion.questionCode === 7 || currentQuestion.questionCode === 3) {
                currentQuestion.answerKey = null;
            }
            //decrease progress
            if (currentQuestion.isAnsweredMarkedForReview  || currentQuestion.isAttempted ) {
                progressVal = ((progressVal * numQuesVal) - 100) / (numQuesVal);
            }

            if (currentQuestion.isAnsweredMarkedForReview) {
                state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] = state.questions[state.currentSectionId][state.currentTypeId]['answeredAndMarked'] - 1;
                state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                newAnsweredMarked = state.questionsAnsweredReviewed - 1;
                newNotAnswered = state.questionsNotAttempted + 1;
            }

            if (currentQuestion.isAttempted && !currentQuestion.isAnsweredMarkedForReview) {
                state.questions[state.currentSectionId][state.currentTypeId]['answered'] = state.questions[state.currentSectionId][state.currentTypeId]['answered'] - 1;
                state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                newAnswered = state.questionsAttempted - 1;
                newNotAnswered = state.questionsNotAttempted + 1;
            }

            if (currentQuestion.isMarkedForReview) {
                state.questions[state.currentSectionId][state.currentTypeId]['marked'] = state.questions[state.currentSectionId][state.currentTypeId]['marked'] - 1;
                state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] = state.questions[state.currentSectionId][state.currentTypeId]['notAnswered'] + 1;
                newMarked = state.questionsReviewed - 1;
                newNotAnswered = state.questionsNotAttempted + 1;
            }

            //call mark and review without answer function
            [currentQuestion.isAttempted, currentQuestion.isAnsweredMarkedForReview, currentQuestion.isMarkedForReview] = [false, false, false]

            return {
                ...state,
                progress: progressVal,
                questionsAttempted: (newAnswered != undefined) ? newAnswered : state.questionsAttempted,
                questionsNotAttempted: (newNotAnswered != undefined) ? newNotAnswered : state.questionsNotAttempted,
                questionsReviewed: (newMarked != undefined) ? newMarked : state.questionsReviewed,
                questionsAnsweredReviewed: (newAnsweredMarked != undefined) ? newAnsweredMarked : state.questionsAnsweredReviewed,
            }
        }

        case TestOngoingActionList.GET_RESULT:
            return {
                ...state
            }
        case TestOngoingActionList.SEND_RESPONSE:
            return {
                ...state,
                timeLeft: action.payload['timeLeft'],
                totalSessionTime: action.payload['totalSessionTime']
            }
        default:
            return state;
    }
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bindFunctionToTemplate'
})
export class BindFunctionToTemplate implements PipeTransform {

  transform<T>(value: T, func: (...x: any[]) => any, ...args: any[]): ReturnType<typeof func> {
    return func(value, ...args);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { Router } from '@angular/router';
import { AlertService} from '../../../services/alert.service';
import { HomeService } from 'src/app/home-page/services/home.service';
import { Location } from '@angular/common';
import { MYCLASSES_VIEW, TIMETABLE_VIEW } from 'src/app/home-page/cards-detail/right-selected-card/my-classes/my-classes.constant';
import { DEFAULT_FILTER_VALUE, FORM_TYPE, ROUTES, TAB, WEEKLY_FORM_NOTIFICATION } from 'src/app/home-page/cards-detail/right-selected-card/my-teachers-feedback/my-teachers-feedback.constant';
import { EventDataBusService } from 'src/app/shared/services/event-data-bus.service';
import { Subscription } from 'rxjs';
import * as JSLZString from 'lz-string';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../../../app/stores/app.reducers';
import { MODULE, NOTIFICATION_TYPE } from './notification-constants';
interface ResponseRecieved {
  data: any;
  count: number;
  code: number;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss', '../header-after-login.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() userData: any;
  public recentWeekData: any = {};
  private firstWeekOfThePhase: number = 0;
  public countToDisplay = 0;
  public notificationsCurrentPage = 1;
  public notificationList: any = [];
  public totalNotificationsLoaded = 0;
  public totalNotificationsCount = 0;
  public showChallengePopUP = false;
  public dataToPass: any;
  public loader = false;
  public noData: boolean = true;
  public newNotificationsActions=[ 'openTestDetails','openWeaknessesTabUnderSWOTBoard','openOpportunitiesTabUnderSWOTBoard','openCurrentPhaseInMyPlan','openCurrentWeekOfMyPlan','openMyPATHome','help','help-issue','help-suggestion','help-idea','resultUploaded', 'markedAbsent', 'openMygoal', 'myClassDetails', 'myClasses'];
  public batchId: any = {};
  public firstDay: any = '';
  public lastDay: any = '';
  public actionFeedback=['weekly-form-open','monthly-form-open','phase-form-open'];
  private activeSubscription: Subscription = new Subscription();
  public phaseData: any = {};
  public defaultIcon: string = '/assets/images/default.png';
  public defaultImageModules: string[] = [
    MODULE.COMMUNITY_ANSWER_VOTE,
    MODULE.COMMUNITY_QUESTION_VOTE,
    MODULE.COMMUNITY_ANSWER,
    MODULE.COMMUNITY_MARK_BEST_ANSWER,
    MODULE.QUESTION_DELETED,
    MODULE.QUESTION_APPROVED,
    MODULE.ANSWER_DELETED,
    MODULE.UBER_BLOCKED_FOR_QUESTION,
    MODULE.ANSWER_APPROVED,
    MODULE.USER_BLOCKED_FOR_ANSWER,
    MODULE.USER_UNBLOCKED,
  ];
  constructor(
    private notification: NotificationService,
    private router: Router,
    private alert: AlertService,
    private homePageService:HomeService,
    private location: Location,
    private eventDataBusService: EventDataBusService,
    private store: Store<fromApp.AppState>,
  ) { }

  ngOnInit(): void {
    this.getNotificationCount();
    this.activeSubscription.add(
      this.store.select('userProfile').subscribe((userProfile) => {
      this.batchId = userProfile?.userData?.batchId;
    }));
  }
  ngOnChanges() {
    this.alert.notificationCountUpdate.subscribe((res) => {
      if (res !== 0) {
        this.getNotificationCount();
      }
    })
  }

  getRecentPhaseAndWeek(myPlanData: any) {
    let phaseViewRecentPhase: any = DEFAULT_FILTER_VALUE;
    let weekViewRecentPhase: any = DEFAULT_FILTER_VALUE;
    let weekViewRecentWeek: any = DEFAULT_FILTER_VALUE;

    const phases = myPlanData?.phases;
    const currentDate = new Date();
    for (const phase in phases) {
      const phaseStartDate = new Date(phases[phase]?.startDate);
      const phaseEndDate = new Date(phases[phase]?.endDate);
      this.phaseData[(+phase) + 1] = {
        startDate: phases[phase]?.startDate,
        endDate: phases[phase]?.endDate
      };
      if (currentDate >= phaseStartDate && phaseEndDate >= currentDate) {
        phaseViewRecentPhase = phase;
        const weeks = phases[phase]?.weeks;
        for (const week in weeks) {
          const weekStartDate = new Date(weeks[week]?.startDate);
          const weekEndDate = new Date(weeks[week]?.endDate);
          if (currentDate >= weekStartDate && weekEndDate >= currentDate) {
            if (+week === this.firstWeekOfThePhase) {
              weekViewRecentPhase = phase;
              weekViewRecentWeek = phases[+phase - 1]?.weeks?.length;
            } else {
              weekViewRecentPhase = +phase + 1;
              weekViewRecentWeek = week;
            }
            break;
          }
        }
      }
    }
    this.recentWeekData = {
      phaseViewRecentPhase,
      weekViewRecentPhase,
      weekViewRecentWeek,
    }
    const date = new Date();
    this.firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    this.lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
  }


  getNotificationCount(): void {
    this.notification.getNotificationNumber().then((res: ResponseRecieved) => {
      this.countToDisplay = res.data;
    });
  }

  fetchAllNotification(): void {
    this.loader = true;
    this.getNotificationViaPagination();
    this.getPhasePlan();
  }

  getPhasePlan(){
    this.activeSubscription.add(
      this.eventDataBusService.getMyPlanData().subscribe((res) => {
        if (res?.data?.isMyPlanSet && res?.data?.myPlan) {
          const response: any = JSON.parse(JSLZString.decompressFromEncodedURIComponent(res?.data?.myPlan));
          this.getRecentPhaseAndWeek(response);
        }
      }, (err) => {
        this.alert.showErrorMessage(err.message);
      }));
  }

  getNotificationViaPagination(): void {
    this.notificationList = [];
    this.notification.getAllNotificationFunc(this.notificationsCurrentPage).then((res: ResponseRecieved) => {
      if (res && res.code === 200 && res.data && res.data.data && res.data.data.length) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.data.data.length; i++) {
          this.notificationList.push(res.data.data[i]);
        }
        if (this.notificationsCurrentPage === 1) {
          this.totalNotificationsCount = res.count;
        }
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.notificationList.length; i++) {
          let timeDiff = new Date().getTime() - new Date(this.notificationList[i].createdAt).getTime();
          timeDiff = timeDiff / 1000
          this.notificationList[i].timePassed = this.timeSince(timeDiff);
        }
        if (this.notificationList.length === 0) {
          this.noData = true;
        } else {
          this.noData = false;
        }
      }
      this.loader = false;
    });

    this.getNotificationCount();

  }

  setNotification(notification): void {
    this.notification.setNotificationToRead(notification._id).then(res => {
      if (res) {
        this.notificationAction(notification);
      }
    });
  }

  notificationAction(notification): void {
    if (notification.action === 'community-question-vote' || notification.action === 'community-answer'
      || notification.action === '"community-answer-vote') {
      const quesData = notification.body.split(': ');
      const name = quesData[1];
      var title = '';
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < name.length; i++) {
        const val = name[i];
        if ((val >= 'A' && val <= 'Z') || (val >= 'a' && val <= 'z') || val == ' ') {
          title = title + val;
        }
      }
      if (title.length > 100) {
        title = title.slice(0, 99);
      }
      if (title === '') {
        title = '000000000000000000000';
      }

      title = title.replace(/\//g, '');
      title = title.replace(/[!@#$%^&*?+-.;,><`=]/g, '-');
      title = title.replace('/-+/', '-');
      title = title.replace(/ /g, '-');
      title = title.replace(/\-\-+/g, '-');
      title = title.toLowerCase().substr(0, 99);
      title = title.replace(/^\s+/g, '');
    }
  if(notification.action==='openCurrentPhaseInMyPlan'|| notification.action==='openCurrentWeekOfMyPlan'){
    this.alert.updateWorkingOnHomePage.next({update: 'openMyPlan'});;
  }else{
    switch (notification.action) {
      case 'purchaseCongratulations': {
        this.router.navigateByUrl('courses');
        break;
      }

      case 'challengee-notification': {
        this.dataToPass = notification;
        this.showChallengePopUP = true;
        break;
      }

      case 'welcome': {
        this.showChallengePopUP = false;
        this.router.navigateByUrl('dashboard');
        break;
      }

      case 'community-question-vote': {
        this.showChallengePopUP = false;
        this.router.navigateByUrl('/community/question-detail/' + title + '?qId=' + notification.data.questionId);
        break;
      }

      case 'community-answer': {
        this.showChallengePopUP = false;
        this.router.navigateByUrl('/community/question-detail/' + title + '?qId=' + notification.data.questionId);
        break;
      }

      case 'community-answer-vote': {
        this.showChallengePopUP = false;
        this.router.navigateByUrl('/community/question-detail/' + title + '?qId=' + notification.data.questionId);
        break;
      }
      case 'community-mark-best-answer': {
        this.showChallengePopUP = false;
        this.router.navigateByUrl('/community/question-detail/' + title + '?qId=' + notification.data.questionId);
        break;
      }

      case 'challenger-notification': {
        this.dataToPass = notification;
        this.showChallengePopUP = true;
        break;
      }

      case 'questionDeleted': {
        this.showChallengePopUP = false;
        this.router.navigateByUrl('/community/view/my-questions/ask-by-me');
        break;
      }

      case 'questionApproved': {
        this.showChallengePopUP = false;
        this.router.navigateByUrl('/community/question-detail/' + title + '?qId=' + notification.data.questionId);
        break;
      }

      case 'answerDeleted': {
        this.showChallengePopUP = false;
        this.router.navigateByUrl('/community/question-detail/' + title + '?qId=' + notification.data.questionId);
        break;
      }

      case 'userBlockedForQuestion': {
        this.showChallengePopUP = false;
        this.router.navigateByUrl('/community/view/my-questions/ask-by-me');
        break;
      }

      case 'userBlockedForAnswer': {
        this.showChallengePopUP = false;
        this.router.navigateByUrl('/community/view/my-answers');
        break;
      }

      case 'answerApproved': {
        this.showChallengePopUP = false;
        this.router.navigateByUrl('/community/question-detail/' + title + '?qId=' + notification.data.questionId);
        break;
      }
      case 'resultUploaded':
      case 'resultRegeneration':
      case 'resultGenerated': {
        this.showChallengePopUP = false;
        this.router.navigate(['/test-results/' + notification.data.testId + '/' + notification.data.attemptId + '/' +
          notification.data.testType + '/overview']);
        break;
      }
      case 'openCalendar': {
        if(this.router.url != "home"){
          this.router.navigateByUrl('/home/myplan/all');
        }
       this.alert.updateWorkingOnHomePage.next({update: 'openMyPlanCalender'});
       break;
      }
      case 'markedAbsent':
      case 'openTestDetails': {
        const {assignmentId,courseId,packageId,id}=notification.data;
       if(assignmentId){
        this.router.navigate(['test-details/course/'+courseId+'/assignment/'+assignmentId+'/test/'+id+'/']);
       }else{
        this.router.navigate(['test-details/course/'+courseId+'/test/'+id+ (packageId?'/package/'+packageId+'/':'')]); 
       }
       break;    
      }
      case 'openWeaknessesTabUnderSWOTBoard': {
        let {courseId,subjectId="",subject="All Subjects"}=notification.data;
        let typename = notification.action.includes('Weakness')?'weakness':'';
        this.alert.updateWorkingOnHomePage.next({update: 'openSwotPage'});
        this.alert.swotType.next(typename) 
        this.location.replaceState('/home/swot?cid=' + courseId + '&sid=' +subjectId + '&sname=' +subject);
        break;
      }
      case 'openOpportunitiesTabUnderSWOTBoard': {
        let {courseId,subjectId="",subject="All Subjects"}=notification.data;
        this.alert.updateWorkingOnHomePage.next({update: 'openSwotPage'});
        this.alert.swotType.next('Opportunity')
        this.location.replaceState('/home/swot?cid=' + courseId + '&sid=' +subjectId + '&sname=' +subject);
        break;
      }
      case 'help-idea':{
        this.alert.issueId.next(notification.data?.ticketId);
        this.router.navigate(['/idea'],{
          queryParams: {
            tid: notification.data?.ticketId
          }
        });
        break;
      }
      case 'help-suggestion':{
      this.alert.issueId.next(notification.data?.ticketId);
      this.router.navigate(['/idea'],{
          queryParams: {
            tid: notification.data?.ticketId
          }
        });
        break;
      }
      case 'help-issue': {
        this.alert.helpId.next(notification.data?.ticketId);
        this.router.navigate(['/help'],{
          queryParams: {
            tid: notification.data?.ticketId
          }
        });
        break;
      }
      case 'openMygoal': {
        this.router.navigate(['home/mygoals']);
        break;
      }
      case 'myClassDetails': {
        const { classId } = notification.data;
        const queryParams = {
          tab: 'viewdetails',
          class: classId,
        };
        if ([MYCLASSES_VIEW.markAttendance, MYCLASSES_VIEW.attendanceUpdate].includes(notification.type)) {
          queryParams['markAttendance'] = true;
        }
        this.router.navigate(['/home/myclasses'], {queryParams});
        break;
      }
      case 'myClasses': {
        const { startTime } = notification.data;
        const selectedDate = new Date(startTime).setHours(0, 0, 0);
        this.router.navigate(['/home/myclasses'], {
          queryParams: {
            tab: MYCLASSES_VIEW.timetable,
            view: TIMETABLE_VIEW.daily,
            selectedDate,
          },
        });
        break;
      }  
      case 'myteachersfeedbackweeklyform':
      case 'myteachersfeedbackphaseform':
      case 'myteachersfeedbackmonthlyform': {
        const today = new Date().toISOString;
        let route = `/${ROUTES.HOME}/${ROUTES.FEEDBACK}`;
        let queryParamObj = {};
        if (notification?.data?.endTime < today) {
          if (notification?.data?.form?.formType === TAB.week) {
            queryParamObj = {
              tab: TAB?.week,
              subject: DEFAULT_FILTER_VALUE,
              phase: this.recentWeekData?.weekViewRecentPhase,
              week: this.recentWeekData?.weekViewRecentWeek,
            }
          } else if (notification?.data?.form?.formType === TAB.month) {
            queryParamObj = {
              tab: TAB?.month,
              subject: DEFAULT_FILTER_VALUE,
              monthStart: DEFAULT_FILTER_VALUE,
              monthEnd: DEFAULT_FILTER_VALUE,
            }
          } else if (notification?.data?.form?.formType === TAB.phase) {
            queryParamObj = {
              tab: TAB?.phase,
              subject: DEFAULT_FILTER_VALUE,
              phase: this.recentWeekData?.phaseViewRecentPhase,
            }
          }
        } else {
          queryParamObj = {
            formType: encodeURI(notification?.data?.form?.formType || ''),
            startDate: notification?.data?.form?.formType === TAB?.phase ? this.phaseData[notification?.data?.phaseNo]?.startDate : (notification?.data?.form?.formType === TAB?.week ? notification?.data?.weekStartDate : this.firstDay.toISOString()),
            endDate: notification?.data?.form?.formType === TAB?.phase ? this.phaseData[notification?.data?.phaseNo]?.endDate : (notification?.data?.form?.formType === TAB?.week ? notification?.data?.weekEndDate : this.lastDay.toISOString()),
          }
          if (notification?.data?.phaseNo) {
            queryParamObj['phaseNo'] = notification?.data?.phaseNo
          }
          if (notification?.data?.form?.id) {
            queryParamObj['id'] = notification?.data?.form?.id;
          }
          if (notification?.data?.formLayoutId) {
            queryParamObj['formLayoutId'] = notification?.data?.formLayoutId;
          }
          if (notification?.data?.assignedFormId) {
            queryParamObj['assignedFormId'] = notification?.data?.assignedFormId;
          }
          if (notification?.data?.batchData?._id) {
            queryParamObj['batchId'] = notification?.data?.batchData?._id;
          }
          queryParamObj['batchId'] = this.batchId;
          route = `/${ROUTES.FEEDBACK}`;
        }
        this.router.navigate([route], { queryParams: queryParamObj });
        break;
      }
    }
   } 
    this.getNotificationCount();
  }

  getAnswerIcoCondition(notification): any {
    return (notification.action === 'community-answer' || notification.action === 'community-mark-best-answer'
      || notification.action === 'questionDeleted' || notification.action === 'questionApproved'
      || notification.action === 'answerDeleted' || notification.action === 'userBlockedForQuestion'
      || notification.action === 'userBlockedForAnswer' || notification.action === 'answerApproved'
      || notification.action === 'userUnblocked'|| notification.action === 'openOpportunitiesTabUnderSWOTBoard'||notification.action === 'openWeaknessesTabUnderSWOTBoard'
      ||notification.action === 'openCurrentPhaseInMyPlan'||notification.action === 'openCurrentWeekOfMyPlan'
      );
  }

  markAllNotificationRead(): void {
    const notificationIds = this.notificationList.filter(notif => !notif.isViewed).map(notif => notif._id);
    this.notification.markAllNotificationRead(notificationIds).then(res => {
      if (res) {
        this.countToDisplay = 0;
        this.notificationList.forEach((notif, i) => {
          if (!notif.isViewed) {
            this.notificationList[i].isViewed = true;
          }
        });
      }
    });
  }

  get weeklyFormNotification() {
    return WEEKLY_FORM_NOTIFICATION;
  }

  onScroll(): void {
    if (this.notificationList.length  < this.totalNotificationsCount) {
      this.notificationsCurrentPage++;
      this.fetchAllNotification();
    }
  }
  ngOnDestroy(): void {
    this.alert.updateWorkingOnHomePage.next({});
  }
  getIconImage(notification):String{
    let src="/assets/images/default.png";
     switch(notification.type){
       case'home':{
        src='/assets/images/notification/new/glorifire-notification.svg';
        break;
       }
       case'goalsetting':{
        src='/assets/images/notification/new/goalSetting.svg';
        break;
      }
      case'swot':{
        if(notification.action==='openWeaknessesTabUnderSWOTBoard'){
            src="/assets/images/notification/new/weekness.svg";
         }
         else{
           src='/assets/images/notification/new/opportunity.svg';
         }
        break; 
      }
      case'pendingTopics':{
        src='/assets/images/notification/new/pending-topics.svg';
        break;
      }
      case'lowProficiency':{
        src='/assets/images/notification/new/proficiency.svg';
        break;
      }
      case'phaseStart':{
        src='/assets/images/notification/new/phasestart.svg';
        break;
      }
      case'testAssigned':{
        src='/assets/images/notification/new/test-assigned.svg';
        break;
      }
      case'testReminder':{
        src='/assets/images/notification/new/test-live.svg';
        break;
      }
      case 'custom': {
        src='/assets/images/notification/new/custom-notification.png';
        break;
      }
      case 'issue-open': {
        src='/assets/images/notification/issuePendingIcon.svg';
        break;
      }
      case 'issue-ongoing': {
        src='/assets/images/notification/issueOngoingIcon.svg';
        break;
      }
      case 'idea-comment':
      case 'suggestion-comment':
      case 'issue-comment': {
        src='/assets/images/notification/commentSupportTeam-icon.svg';
        break;
      }
      case 'issue-resolved': {
        src='/assets/images/notification/issueResolvedIcon.svg';
        break;
      }
      case 'issue-closed': {
        src='/assets/images/notification/issueClosedIcon.svg';
        break;
      }
      case 'suggestion-closed': {
        src='/assets/images/notification/idea-suggestion-closedIcon.svg';
        break;
      }
      case 'idea-closed': {
        src='/assets/images/notification/idea-suggestion-closedIcon.svg';
        break;
      }
      case 'issue-reopened': {
        src='/assets/images/notification/issueReopenedIcon.svg';
        break;
      }
      case 'idea-submitted':{
        src='/assets/images/notification/ideaSubmitIcon.svg';
        break;
      }
      case 'idea-accepted':{
        src='/assets/images/notification/ideaAcceptedIcon.svg';
        break;
      }
      case 'idea-ongoing':{
        src='/assets/images/notification/ideaOngoingIcon.svg';
        break;
      }
      case 'idea-completed':{
        src='/assets/images/notification/ideaCompletedIcon.svg';
        break;
      }
      case 'suggestion-submitted':{
        src='/assets/images/notification/ideaSubmitIcon.svg';
        break;
      }
      case 'suggestion-accepted':{
        src='/assets/images/notification/ideaAcceptedIcon.svg';
        break;
      }
      case 'suggestion-ongoing':{
        src='/assets/images/notification/ideaOngoingIcon.svg';
        break;
      }
      case 'suggestion-completed':{
        src='/assets/images/notification/ideaCompletedIcon.svg';
        break;
      }
      case 'offlineResult':{
        src='/assets/images/notification/offlineResult.png';
        break;
      }  
      case 'suggestion-transferred':
      case 'idea-transferred':
      case 'issue-transfered':{
        src='/assets/images/notification/transferTicket.svg';
        break;
      }
      case 'suggestion-alreadyAvailable': 
      case 'idea-alreadyAvailable': {
        src='/assets/images/notification/alreadyAvailable.svg';
        break;
      }
      case 'idea-partiallyAccepted':
      case 'suggestion-partiallyAccepted':{
        src='/assets/images/notification/partiallyAccepted.svg';
        break;
      }
      case 'goalSetAfterPhase1':{
        src = '/assets/images/notification/phase-test-1.svg';
        break;
      }
      case 'markedAbsent': {
        src='/assets/images/notification/markedAbsent.svg';
        break;
      }
      case 'allowAttendanceMarking': {
        src='/assets/images/notification/allowAttendanceMarking.svg';
        break;
      }
      case 'newClassesAdded': {
        src='/assets/images/notification/newClassesAdded.svg';
        break;
      }
      case 'classStartsInTenMinutes': {
        src='/assets/images/notification/classStartsInTenMinutes.svg';
        break;
      }
      case this.notificationType.PTM_SCHEDULED.type:
        src = this.notificationType.PTM_SCHEDULED.src
        break;
      case this.notificationType.PTM_SCHEDULED_DELETED.type:
        src = this.notificationType.PTM_SCHEDULED_DELETED.src
        break;
      case this.notificationType.PTM_SCHEDULED_UPDATED.type:
        src = this.notificationType.PTM_SCHEDULED_UPDATED.src
        break;
      case this.notificationType.PTM_SCHEDULED_LIVE.type:
        src = this.notificationType.PTM_SCHEDULED_LIVE.src
        break;
      case this.notificationType.PTM_SCHEDULED_TOMORROW.type:
        src = this.notificationType.PTM_SCHEDULED_TOMORROW.src
        break;
      case this.notificationType.CALENDAR_EVENT.type:
        src = this.notificationType.CALENDAR_EVENT.src
        break;
      default:{
        src="/assets/images/default.png";
      }
    }
   return src;
  }


  timeSince(timeDiff){
    if (timeDiff < 60) { // 1 min
      return 'Just Now'
    } else if (timeDiff < 120) { // 2min
      return '1 minute ago';
    } else if (timeDiff < 60 * 60) { // 60 min
      return `${Math.ceil(timeDiff / 60)} minutes ago`;
    } else if (timeDiff < 60 * 60 * 24) { // 24 hour
      return `${Math.ceil(timeDiff / (60 * 60))} hours ago`;
    } else if (timeDiff < 60 * 60 * 24 * 2) { // 48 hour
      return "1 day Ago";
    } else if (timeDiff < 60 * 60 * 24 * 30) { // 1 month
      return `${Math.ceil(timeDiff / (60 * 60 * 24))} days ago`;
    } else if (timeDiff < 60 * 60 * 24 * 30 * 2) { // 2 month
      return "1 month ago";
    } else if (timeDiff < 60 * 60 * 24 * 365) {
      return `${Math.ceil(timeDiff / (2629746))} months ago`; // 2,629,746
    } else {
      return 'More than an year ago';
    }
  }

  get notificationType() {
    return NOTIFICATION_TYPE;
  }
}

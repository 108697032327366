<header>
  <div class="container">
    <nav>
      <!--===============================================
        Desktop Navigation starts here
      =================================================-->
      <div class="desktop-navigation">
        <div class="nav-left">
          <ul>
            <li>
              <a routerLinkActive="active" [routerLink]="['/']">
                <!-- <img
                  class="logo"
                  [src]="'/assets/images/glorifire-logo.svg' "
                  alt="logo"
                  width="150"
                /> -->
                <img
                  class="logo"
                  *ngTemplateOutlet="glorifireLogo"
                  alt="logo"
                  width="150"
                />
              </a>
            </li>
            <!-- <li class="dropdown">
              <a
                routerLinkActive="active"
                class="dropdown-toggle"
                
              >
                Courses
              </a>
            </li>

            <li class="dropdown">
              <a
                href="#"
                class="dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                My Exams
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <ul
                  class="dropdown-menu new-nav arrow-up-drobox ml-50"
                  role="menu"
                >
                  <li><a routerLinkActive="active" [routerLink]="['/exam','jee-main']">JEE Main</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','jee-advanced']">JEE Advanced</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','bitsat']">BITSAT</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','ntse']">NTSE</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','kvpy']">KVPY</a></li>
                  <li class="dropdown-submenu">
                    <a
                      class="other-exams"
                      tabindex="-1"
                      href="javascript:void(0);"
                      >Other Exams</a
                    >
                    <ul class="dropdown-menu">
                      <li><a routerLinkActive="active" [routerLink]="['/exam','viteee']">VITEEE</a></li>
                      <li><a routerLinkActive="active" [routerLink]="['/exam','comedk-uget']">COMEDK UGET</a></li>
                      <li><a routerLinkActive="active" [routerLink]="['/exam','mhtcet']">MHTCET</a></li>
                      <li><a routerLinkActive="active" [routerLink]="['/exam','wbjee']">WBJEE</a></li>
                      <li><a routerLinkActive="active" [routerLink]="['/exam','upsee']">UPSEE</a></li>
                      <li><a routerLinkActive="active" [routerLink]="['/exam','ts-eamcet']">TS EAMCET</a></li>
                      <li><a routerLinkActive="active" [routerLink]="['/exam','keam']">KEAM</a></li>
                      <li><a routerLinkActive="active" [routerLink]="['/exam','kcet']">KCET</a></li>
                      <li><a routerLinkActive="active" [routerLink]="['/exam','ipu-cet']">IPU CET</a></li>
                      <li><a routerLinkActive="active" [routerLink]="['/exam','lpu-nest']">LPU NEST</a></li>
                      <li><a routerLinkActive="active" [routerLink]="['/exam','kiitee']">KIITEE</a></li>
                      <li><a routerLinkActive="active" [routerLink]="['/exam','srm-jee']">SRM JEE</a></li>
                      <li><a routerLinkActive="active" [routerLink]="['/exam','amity-jee']">AMITY JEE</a></li>
                    </ul>
                  </li>
                </ul>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="8"
                fill="none"
              >
                <mask
                  id="a"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="13"
                  height="8"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.475.508v6.57H.628V.507h11.847z"
                    fill="#fff"
                  />
                </mask>
                <g mask="url(#a)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M.75 1.242A.433.433 0 01.628.934c0-.116.04-.222.122-.307a.409.409 0 01.593 0L6.55 6.03 11.76.627c.164-.17.43-.17.593 0a.446.446 0 010 .615L6.848 6.95a.409.409 0 01-.593 0L.75 1.242z"
                    fill="#333"
                  />
                </g>
              </svg>
            </li>

            <li class="dropdown">
              <a
                href="#"
                class="dropdown-toggle"
                id="navbarDropdown1"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Resources
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
                <ul
                  class="dropdown-menu new-nav arrow-up-drobox ml-50"
                  role="menu"
                >
                  <li>
                    <a href="http://mypat.in/blog/" target="_blank">Blog </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCHNzbB0yeaP0q8Zm3Ais2AQ"
                      target="_blank"
                      >Webinar</a
                    >
                  </li>

                  <li class="drphed">
                    PREVIOUS YEAR PAPERS
                    <ul class="submenu">
                      <li>
                        <a routerLinkActive="active"  [routerLink]="['/previous-year-papers','iit-jee']">JEE Main/Advanced</a>
                      </li>
                      <li><a routerLinkActive="active" [routerLink]="['/previous-year-papers','ntse']">NTSE</a></li>
                      <li><a routerLinkActive="active" [routerLink]="['/previous-year-papers','kvpy']">KVPY</a></li>
                      <li class="dropdown-submenu">
                        <a
                          class="sample-paper"
                          tabindex="-1"
                          href="javascript:void(0);"
                          >Board Sample paper</a
                        >
                        <ul class="dropdown-menu pa-10 sample-paper-menu">
                          <li>
                            <a routerLinkActive="active" [routerLink]="['/sample-paper/board']">All Sample Papers</a>
                          </li>
                          <li>
                            <a routerLinkActive="active" [routerLink]="['/cbse-sample-papers-for-class-10-science']">CBSE 10th Science</a>
                          </li>
                          <li>
                            <a routerLinkActive="active" [routerLink]="['/cbse-sample-papers-for-class-10-maths']">CBSE 10th Maths</a>
                          </li>
                          <li>
                            <a
                            routerLinkActive="active" [routerLink]="['/cbse-sample-papers-for-class-12-chemistry']">CBSE 12th Chemistry</a
                            >
                          </li>
                          <li>
                            <a routerLinkActive="active" [routerLink]="['/cbse-sample-papers-for-class-12-maths']">CBSE 12th Maths</a>
                          </li>
                          <li>
                            <a routerLinkActive="active" [routerLink]="['/cbse-sample-papers-for-class-12-physics']">CBSE 12th Physics</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li class="drphed">
                    Tools
                    <ul class="submenu">
                      <li>
                        <a routerLinkActive="active" [routerLink]="['/prep-tracker','jee-main']">JEE (M) Prep Tracker</a>
                      </li>
                      <li>
                        <a routerLinkActive="active" [routerLink]="['/prep-tracker','jee-advanced']">JEE (A) Prep Tracker</a>
                      </li>
                      <li>
                        <a routerLinkActive="active" [routerLink]="['/prep-tracker','bitsat']">BITSAT Prep Tracker</a>
                      </li>
                      <li>
                        <a routerLinkActive="active" [routerLink]="['/prep-tracker','kvpy']">KVPY Prep Tracker</a>
                      </li>
                      <li>
                        <a routerLinkActive="active" [routerLink]="['/prep-tracker','ntse']">NTSE Prep Tracker</a>
                      </li>
                      <li>
                        <a routerLinkActive="active" [routerLink]="['/college-predictor','jee-advanced']"
                          >JEE (A) College Predictor</a
                        >
                      </li>
                      <li>
                        <a routerLinkActive="active" [routerLink]="['/college-predictor','jee-main']"
                          >JEE (M) College Predictor</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="8"
                fill="none"
              >
                <mask
                  id="a"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="13"
                  height="8"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.475.508v6.57H.628V.507h11.847z"
                    fill="#fff"
                  />
                </mask>
                <g mask="url(#a)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M.75 1.242A.433.433 0 01.628.934c0-.116.04-.222.122-.307a.409.409 0 01.593 0L6.55 6.03 11.76.627c.164-.17.43-.17.593 0a.446.446 0 010 .615L6.848 6.95a.409.409 0 01-.593 0L.75 1.242z"
                    fill="#333"
                  />
                </g>
              </svg>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="['/community']"> Community </a>
            </li>
            <li>
              <a routerLinkActive="active" [routerLink]="['/refer-and-earn']"> Refer & Earn </a>
            </li> -->
          </ul>
        </div>
        <div class="nav-right">
          <ul class="button-group">
            <li><a href="javascript:void(0)" (click)="navigateToSignIn()" class="btn btn-primary"> Login </a></li>
            <!-- <li>
              <a href="javascript:void(0)" (click)="navigateToSignUp()" class="btn btn-primary">sign up</a>
            </li> -->
          </ul>
        </div>
      </div>

      <!--===============================================
        Mobile Navigation starts here
      =================================================-->
      <div class="mobile-navigation row">
        <div class="mobile-top-nav">
          <!-- <div class="mobile-top-nav-left">
            <a href="#" (click)="showSidebar()">
              <img
                src="../../../../assets/images/hamburger-icon.svg"
                alt="hamburger-icon"
              />
            </a>
            <a href="#">
              <img
                src="/assets/images/glorifire-logo.svg"
                alt="small logo glorifire"
              />
            </a>
          </div> -->

          <div class="nav-left">
            <!-- <img
              [src]="'/assets/images/glorifire-logo.svg' "
              alt=""
              width="120"
            /> -->
            <img
              *ngTemplateOutlet="glorifireLogo"
              alt=""
              width="120"
            />
          </div>
          <div class="nav-right">
            <ul class="button-group" style="list-style: none; margin-bottom: 0;">
              <li><a href="javascript:void(0)" class="btn btn-primary" (click)="navigateToSignIn()"> Login </a></li>
              <!-- <li>
                <a href="javascript:void(0)" (click)="navigateToSignUp()" class="btn btn-primary">sign up</a>
              </li> -->
            </ul>
          </div>

          <!-- <div class="mobile-top-nav-right">
            <a href="#" class="notification-bell">
              <img
                src="../../../../assets/images/notification-bell.svg"
                alt=""
              />
            </a>

            <a href="#" class="user-container">
              <img src="../../../../assets/images/dummy-user.png" alt="" />
            </a>
          </div> -->
        </div>

        <div
          class="sidebarOverlay"
          (click)="hideSidebar()"
          [hidden]="!sidebarOverlay"
        ></div>

        <div class="sidebar" #sideBar [hidden]="!sidebarOverlay">
          <div class="sidebar-header">
            <div class="nav-left">
              <!-- <img
                [src]="'/assets/images/glorifire-logo.svg' "
                alt=""
                width="71"
              /> -->
              <img
                *ngTemplateOutlet="glorifireLogo"
                alt=""
                width="71"
              />
            </div>
            <div class="nav-right">
              <ul class="button-group">
                <li><a href="javascript:void(0)" class="btn btn-primary" (click)="navigateToSignIn()"> Login </a></li>
                <!-- <li>
                  <a href="javascript:void(0)" (click)="navigateToSignUp()" class="btn btn-primary">sign up</a>
                </li> -->
              </ul>
            </div>
          </div>

          <!-- <div class="sidebar-body">
            <ul class="sidebarbody-container">
              <li>
                <a href="#">
                  <img src="../../../../assets/images/courses.svg" alt="" />
                  Cousrses
                </a>
              </li>

              <li class="dropdown exam-menu">
                <a
                  href="#"
                  class="dropdown-toggle "
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img src="../../../../assets/images/exam.svg" alt="" />
                  My Exams
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <ul>
                  <li><a class="active" routerLinkActive="active" [routerLink]="['/exam','jee-main']">JEE Main</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','jee-advanced']">JEE Advanced</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','bitsat']">BITSAT</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','ntse']">NTSE</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','kvpy']">KVPY</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','viteee']">VITEEE</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','comedk-uget']">COMEDK UGET</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','mhtcet']">MHTCET</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','wbjee']">WBJEE</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','upsee']">UPSEE</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','ts-eamcet']">TS EAMCET</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','keam']">KEAM</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','kcet']">KCET</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','ipu-cet']">IPU CET</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','lpu-nest']">LPU NEST</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','kiitee']">KIITEE</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','srm-jee']">SRM JEE</a></li>
                  <li><a routerLinkActive="active" [routerLink]="['/exam','amity-jee']">AMITY JEE</a></li>
                </ul>
                </div>
              </li>

              <li class="dropdown exam-menu">
                <a
                  href="#"
                  class="dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img src="../../../../assets/images/courses.svg" alt="" />
                  Resources
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <ul>
                    <li><a href="http://mypat.in/blog/" target="_blank"><b>Blog</b></a></li>
                    <li><a href="https://www.youtube.com/channel/UCHNzbB0yeaP0q8Zm3Ais2AQ" target="_blank"><b>Webinar</b></a></li>
                    <li><a routerLinkActive="active"  [routerLink]="['/previous-year-papers','iit-jee']">JEE Main/Advanced</a></li>
                    <li><a routerLinkActive="active" [routerLink]="['/previous-year-papers','ntse']">NTSE</a></li>
                    <li><a routerLinkActive="active" [routerLink]="['/previous-year-papers','kvpy']">KVPY</a></li>
                    <li><a><b>Board Sample paper</b></a></li>
                    <li><a routerLinkActive="active" [routerLink]="['/sample-paper/board']">All Sample Papers</a></li>
                    <li><a routerLinkActive="active" [routerLink]="['/cbse-sample-papers-for-class-10-science']">CBSE 10th Science</a></li>
                    <li><a routerLinkActive="active" [routerLink]="['/cbse-sample-papers-for-class-10-maths']">CBSE 10th Maths</a></li>
                    <li><a routerLinkActive="active" [routerLink]="['/cbse-sample-papers-for-class-12-chemistry']">CBSE 12th Chemistry</a></li>
                    <li><a routerLinkActive="active" [routerLink]="['/cbse-sample-papers-for-class-12-maths']">CBSE 12th Maths</a></li>
                    <li><a routerLinkActive="active" [routerLink]="['/cbse-sample-papers-for-class-12-physics']">CBSE 12th Physics</a></li>
                    <li><a><b>Tools</b></a></li>
                    <li><a routerLinkActive="active" [routerLink]="['/prep-tracker','jee-main']">JEE (M) Prep Tracker</a></li>
                    <li><a routerLinkActive="active" [routerLink]="['/prep-tracker','jee-advanced']">JEE (A) Prep Tracker</a></li>
                    <li><a routerLinkActive="active" [routerLink]="['/prep-tracker','bitsat']">BITSAT Prep Tracker</a></li>
                    <li><a routerLinkActive="active" [routerLink]="['/prep-tracker','kvpy']">KVPY Prep Tracker</a></li>
                    <li><a routerLinkActive="active" [routerLink]="['/prep-tracker','ntse']">NTSE Prep Tracker</a></li>
                    <li><a routerLinkActive="active" [routerLink]="['/college-predictor','jee-advanced']">JEE (A) College Predictor</a></li>
                    <li><a routerLinkActive="active" [routerLink]="['/college-predictor','jee-main']">JEE (M) College Predictor</a></li>
                  </ul>
                </div>
              </li>

              <li class="dropdown exam-menu">
                <a
                  href="#"
                  class="dropdown-toggle "
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img src="../../../../assets/images/community.svg" alt="" />
                  Community
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <ul>
                  <li><a href="#">My Feed</a></li>
                  <li><a href="#">My Questions (20)</a></li>
                  <li><a href="#">My answers (10)</a></li>
                </ul> 
                </div>    
              </li>
              <li>
                <a href="#">
                  <img src="../../../../assets/images/earn.svg" alt="" />
                  Refer & Earn
                </a>
              </li>
            </ul>
          </div> -->

          <div class="sidebar-footer">
            <button class="btn btn-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="19"
                fill="none"
              >
                <rect
                  x=".5"
                  y="1"
                  width="11"
                  height="17"
                  rx=".5"
                  stroke="#fff"
                />
                <path
                  d="M9.103 12.003H2.648a.249.249 0 000 .497h6.455a.249.249 0 000-.497zM5.875 11.626l2.634-2.634a.248.248 0 00-.35-.35l-2.036 2.033V5.548a.248.248 0 00-.497 0v5.127L3.593 8.642a.248.248 0 10-.35.35l2.632 2.634z"
                  fill="#fff"
                  stroke="#fff"
                  stroke-width=".5"
                />
              </svg>
              Get the App
            </button>
          </div>
        </div>
        <!--Sidebar ends here-->
      </div>
    </nav>
  </div>
  <!--Container ends here-->
</header>
<!--Header ends here-->


<ng-template #glorifireLogo>
  
 <img src="/assets/images/glorifire-logo-1.svg" alt="Glorifire Logo"/>
      
  
  
    
    
</ng-template>
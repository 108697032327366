import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-profile-user-info',
  templateUrl: './profile-user-info.component.html',
  styleUrls: ['./profile-user-info.component.scss']
})
export class ProfileUserInfoComponent implements OnInit, OnChanges {
  @Input() userData: any;
  public romanClassMapping = [
    { english: '1st', roman: 'I' },
    { english: '2nd', roman: 'II' },
    { english: '3rd', roman: 'III' },
    { english: '4th', roman: 'IV' },
    { english: '5th', roman: 'V' },
    { english: '6th', roman: 'VI' },
    { english: '7th', roman: 'VII' },
    { english: '8th', roman: 'VIII' },
    { english: '9th', roman: 'IX' },
    { english: '10th', roman: 'X' },
    { english: '11th', roman: 'XI' },
    { english: '12th', roman: 'XII' },
    { english: '12th Pass', roman: 'XII Pass'},
    { english: '12th +', roman: 'XII +'}
  ];
  public showMore: boolean = false;
  public showMoreData: any = [];
  public primaryGoalName: any = '';
  public assignedBatched: any = [];

  constructor() { }

  ngOnInit(): void {
    // tslint:disable-next-line: prefer-for-of
    if (this.userData && this.userData.userId && this.userData.userCentres && this.userData.userCentres.length) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.userData.userCentres.length; i++) {
        if (this.userData.userCentres[i].batches.length > 1) {
          this.showMore = true;
        }
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < this.userData.userCentres[i].batches.length; j++) {
          const obj = {centre: this.userData.userCentres[i].name, batches: this.userData.userCentres[i].batches[j].name };
          this.showMoreData.push(obj);
        }
      }
      if (this.userData.userCentres.length > 1) {
        this.showMore = true;
      }
      const assignedBatches = [];
      this.userData?.userCentres?.forEach(centre => {
        centre?.batches?.forEach(batch => {
          assignedBatches.push(batch);
        });
      });
      this.assignedBatched = assignedBatches;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.userData?.currentValue !== changes?.userData?.previousValue) {
      for (let i = 0; i < this.userData.userGoals.length; i++) {
        if (this.userData.userGoals[i].isPrimary) {
          this.primaryGoalName = ((this.userData.userGoals[i].courseType && this.userData.userGoals[i].courseType.name) ||
            this.userData.userGoals[i].courseTypeName);
          break;
        }
      }
    }
  }

  getEnNo(): string {
    return this.userData.isFiitjee ? this.userData.fiitjeeEnrollmentNumber : this.userData.enrollmentNumber;
  }

  getName(name): string {
    return name.split(' ')[0];
  }

  getClass(className): string {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.romanClassMapping.length; i++) {
      if (className === this.romanClassMapping[i].english) {
        return this.romanClassMapping[i].roman;
      }
    }
  }

  getTitle(): string {
    let result = '';
    if (this.userData.userCentres && this.userData.userCentres[0].batches.length) {
      result = this.userData.userCentres[0].batches[0].name + ', ' + this.userData.userCentres[0].name;
    }
    return result;
  }
}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { OverviewService } from 'src/app/test-results-analytics/services/overview.service';

declare var $: any;

@Component({
  selector: 'app-side-filter-list',
  templateUrl: './side-filter-list.component.html',
  styleUrls: ['./side-filter-list.component.css']
})
export class SideFilterListComponent implements OnInit, OnChanges {

  @Input() filterDetails: any;
  @Input() filterType: any;
  @Input() clearingData: Boolean;
  @Input() marginOffset: boolean;
  @Output() filterChange = new EventEmitter<any>();
  @Output() closeFilters = new EventEmitter<any>();
  @Output() isViewUpdateChecked = new EventEmitter<any>();
  public filterDataToShow: any = [];
  public isMobile = false;
  public viewUpdateFlag:boolean=false;
  public applyClicked:boolean;

  constructor(private overviewService:OverviewService) {
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }
  }

  ngOnInit() {
    if(this.overviewService.resultRegen){
      this.overviewService.viewUpdateFlag=true;
      this.overviewService.applyClicked=true;
    }
    this.overviewService.resultRegen=false;
    $('#kaptureChatWrapper').addClass('hide-chat');
    this.filterDataToShow = [];
    for (let i = 0; i < this.filterDetails.length; i++) {
      const filterKeys = Object.keys(this.filterDetails[i]);
      const filterObj = {
        filterKey: filterKeys[0],
        filterValues: this.filterDetails[i][filterKeys[0]],
        type: filterKeys[0] === 'Time Taken' ? 'single' : this.filterType
      };
      for (let i = 0; i < filterObj.filterValues.length; i++) {
        filterObj.filterValues[i].isChecked = false;
        filterObj.filterValues[i].isApplied = false;
      }
      this.filterDataToShow.push(filterObj);
    }
    this.viewUpdateFlag = this.overviewService.viewUpdateFlag;
  }

  ngOnChanges() {
  }

  onChangeMultiSelect(event) {
    if (this.isMobile) {
      return;
    }
    this.filterChange.emit(event);
  }

  onChangeSingleSelect(event) {
    if (this.isMobile) {
      return;
    }
    this.filterChange.emit(event);
  }


  apply() {
    this.overviewService.applyClicked = true;
    this.filterDataToShow.forEach((category: any) => {
      category.filterValues.forEach((filter: any) => {
        if (filter.isChecked) {
          filter.isApplied = true;
        }
        this.filterChange.emit({
          isChecked: filter.isChecked,
          filterKey: category.filterKey,
          filterValue: filter
        });
      });
    });
    $('#kaptureChatWrapper').removeClass('hide-chat');
    this.closeFilters.emit();
    if(this.viewUpdateFlag){
      this.overviewService.viewUpdateFlag = this.viewUpdateFlag;
      this.isViewUpdateChecked.emit(this.viewUpdateFlag);
    }else{
      this.overviewService.applyClicked = false;
    }
  }

  closeFilter() {
    if ((!this.overviewService.applyClicked && this.overviewService.viewUpdateFlag) || (this.overviewService.applyClicked && !this.overviewService.viewUpdateFlag)) {
      this.overviewService.viewUpdateFlag = !this.overviewService.viewUpdateFlag;
    } 
    this.viewUpdateFlag = this.overviewService.viewUpdateFlag;
    this.filterDataToShow.forEach((category: any) => {
      category.filterValues.forEach((filter: any) => {
        filter.isChecked = filter.isApplied;
      });
    });
    $('#kaptureChatWrapper').removeClass('hide-chat');
    this.closeFilters.emit();
  }

  resetFilters() {
    this.filterDataToShow.forEach((category: any) => {
      category.filterValues.forEach((filter: any) => {
        filter.isChecked = false;
        filter.isApplied = false;
      });
    });
    for (let i = 0; i < this.filterDataToShow.length; i++) {
      this.filterDataToShow[i].isChecked = false;
      for (let j = 0; j < this.filterDataToShow[i].filterValues.length; j++) {
        this.filterChange.emit({ isChecked: false, filterValue: this.filterDataToShow[i].filterValues[j], filterKey: this.filterDataToShow[i].filterKey });
      }
    }
    this.viewUpdateFlag = false;
    this.overviewService.viewUpdateFlag = false;
    this.overviewService.applyClicked = false;
  }
  
  viewUpdateCheck(event: any) {
    this.viewUpdateFlag = event;
    this.overviewService.viewUpdateFlag = this.viewUpdateFlag;
    if(this.isMobile){
      return;
    }
    this.isViewUpdateChecked.emit(this.viewUpdateFlag);
  }
}

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import * as fromApp from '../../../stores/app.reducers';
import * as AuthActions from '../../../stores/auth/auth.actions';
import { AlertService } from '../../services/alert.service';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-under-development',
  templateUrl: './under-development.component.html',
  styleUrls: ['./under-development.component.scss']
})
export class UnderDevelopmentComponent implements OnInit {

  constructor(
    private httpService: HttpService,
    private alertService: AlertService,
    private store: Store<fromApp.AppState>,
    private gtmService: GoogleTagManagerService
  ) { }

  ngOnInit(): void {
  }

  logout() {
    const gtmTag: any = {
      event: 'custom_event',
      custom_event_name: 'logout',
    };
    gtmTag.userId = window.localStorage.getItem('enrollmentNo') || 'undefined';
    const centreIds = window.localStorage.getItem('centres')?.split(',') || [];
    gtmTag.centre1 = centreIds[0] || 'undefined';
    gtmTag.centre2 = centreIds[1] || 'undefined';
    const batchIds = window.localStorage.getItem('batches')?.split(',') || [];
    gtmTag.batch1 = batchIds[0] || 'undefined';
    gtmTag.batch2 = batchIds[1] || 'undefined';
    gtmTag.batch3 = batchIds[2] || 'undefined';
    this.gtmService.pushTag(gtmTag);
    this.store.dispatch(new AuthActions.Logout());
  }
}

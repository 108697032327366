import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { HelpService } from 'src/app/help-and-support/services/help.service';
import * as fromApp from '../../../stores/app.reducers';
import * as LoaderAction from '../../../stores/loader/loader.actions';
import { HttpService } from '../../../shared/services/http.service';
import { AlertService } from '../../services/alert.service';
import { HomeService } from 'src/app/home-page/services/home.service';

declare var $: any;
var w = (window.innerWidth);

@Component({
  selector: 'app-provide-comments',
  templateUrl: './provide-comments.component.html',
  styleUrls: ['./provide-comments.component.scss']
})
export class ProvideCommentsComponent implements OnInit {

  @Input() issueId: string;
  public commentText = '';
  disableSubmit = false;
  errorAndSuccess = false;
  uploadingFile: boolean = false;
  selectedProblemTypeId: any;
  public isLoading: boolean = false;
  public disabledCrossIcon: boolean = false;
  public isMobile: boolean = false;
  public isTab: boolean = false;
  public fileAdded: boolean = false;
  public commentBlank : boolean = false;
  public commentLength = 0;
  public attachments = [];
  @Output() onClosePopUp = new EventEmitter<any>();
  @Output() onSuccessfulComment = new EventEmitter<any>();
  
  constructor(private helpServices: HelpService, private http: HttpClient,
    private store: Store<fromApp.AppState>, private homePageService: HomeService,
    private httpService: HttpService, private alert: AlertService) { }

  ngOnInit(): void {
    $('#addComment').modal({
      backdrop: 'static',
      keyboard: false
    })
  }


  mydata = [];
  fileSizeError = false;
  fileTypeAccept = false;

  onimageClick(){
    $('#files')[0].value = null; 
  }

  onSelectFile(event) {
    this.fileSizeError = false;
    this.fileTypeAccept = false;
    this.fileAdded = false;
    const files = event.target.files;

    var ext = $('#files').val().split('.').pop().toLowerCase();
    if (ext !== 'jpg' && ext !== 'jpeg' && ext !== 'png' && ext !== 'mp4' && ext !== 'mov' && ext !== 'hevc') {
    this.fileTypeAccept = true;
    return false;
    }

    if (files) {
      for (const file of files) {
        const reader = new FileReader();
        let fileType = (Math.floor(Math.log(file.size) / Math.log(1024)));
        let actualSize = Math.round(file.size / Math.pow(1024, fileType));
        const fileName = file.name.split(' ').join('');
        reader.onload = (e: any) => {
          if (file.type.indexOf('image') > -1) {
            if (fileType === 2 && actualSize >= 100) {
              this.fileSizeError = true;
              this.fileAdded = false;
              return;
            }
            const index = this.mydata.findIndex((obj) => obj.file.name == file.name)
            if (index == -1) {
              this.fileAdded = false;
              this.mydata.push({
                url: e.target.result,
                type: 'image',
                name: fileName,
                file: file,
              });
            } else {
              this.fileAdded = true;
              this.fileSizeError = false;
              this.fileTypeAccept = false;
            }
            // this.getPreSignedUrl();
          } else if (file.type.indexOf('video') > -1) {
            if (fileType === 2 && actualSize >= 100) {
              this.fileSizeError = true;
              this.fileAdded = false;
              return;
            }
            const index = this.mydata.findIndex((obj) => obj.file.name == file.name)
            if (index == -1) {
              this.fileAdded = false;
              this.mydata.push({
                url: e.target.result,
                type: 'video',
                name: fileName,
                file: file
              });
            } else {
              this.fileAdded = true;
              this.fileSizeError = false;
              this.fileTypeAccept = false;
            }
          }
        };
        reader.readAsDataURL(file);
      }
    }
  }

  onKeyUp() {
    const count = this.commentText.replace(/\r?\n|\r/g, "");
    this.commentLength = count.replace(/ /g, '').length;
    this.commentBlank = false;
    if (!this.commentText || (this.commentText.replace(/ /g, '').length < 40)) {
      //this.errorText = true;
      this.commentBlank = false;
    } else {
      //this.errorText = false;
      this.commentBlank = false;
      this.disableSubmit = false;
    }
  }

  closeThumb(index) {
    this.fileAdded = false;
    this.mydata.splice(index, 1);
  }

  // get permission from s3 to upload image
  getPreSignedUrl(obj): void {
    const apiUrl = 'v4/auth/upload-signed-url?location=ideasAndSuggestions';
    this.httpService.get(apiUrl, true, { key: obj.name }, false).subscribe(
      (res) => {
        if (res && res.code === 200) {
          if (res.data.url && res.data.fields) {
            obj.photoUrlCopy = res.data.url + '/' + res.data.fields.key;
            this.uploadYourFileOnS3(res.data, obj);
          }
        }
      }, (error) => {
        this.currentUploadIndex++;
        obj.status = 'Failed';
        this.uploadFiles();
      });
  }

  // upload image after permision
  uploadYourFileOnS3(data, obj): void {
    const formData: FormData = new FormData();
    formData.append('key', data.fields.key);
    formData.append('bucket', data.fields.bucket);
    formData.append('acl', 'public-read');
    formData.append('X-Amz-Algorithm', data.fields['X-Amz-Algorithm']);
    formData.append('X-Amz-Credential', data.fields['X-Amz-Credential']);
    formData.append('X-Amz-Date', data.fields['X-Amz-Date']);
    formData.append('Policy', data.fields['Policy']);
    formData.append('X-Amz-Signature', data.fields['X-Amz-Signature']);
    formData.append('file', obj.file, obj.name);

    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };
    this.http.post(data.url, formData, options).subscribe((res) => {
      this.currentUploadIndex++;
      obj.status = 'Uploaded';
      this.uploadFiles();
    }, () => {
      this.currentUploadIndex++;
      obj.status = 'Failed';
      this.uploadFiles();
    });
  }

  uploadFiles() {
    this.disableSubmit = true;
    this.isLoading = true;
    this.errorAndSuccess = false;
    if (this.currentUploadIndex < this.mydata.length) {
      this.getPreSignedUrl(this.mydata[this.currentUploadIndex]);
    } else {
      this.attachments = [];
      this.mydata.forEach((item) => {
        this.attachments.push({
          type: item.type,
          url: item.photoUrlCopy
        });
      });

      const payload = {
        issueId : this.issueId,
        message : this.commentText,
        attachments : this.attachments,
      }

      this.helpServices.postIssueComment(payload).then((res) => {
        if (res.code === 200) {
          // success
          $('#addComment').modal('hide');
          $('#addComment .modal-backdrop').removeClass('show');
          $('.reportIssueidea').removeClass('discardOpen');
          this.discardChanges = false;
          this.disableSubmit = false;
          this.isLoading = false;
          // hide loader and close popup then show sucsses res.message
          this.store.dispatch(new LoaderAction.SetLoader(false));
          this.alert.showSuccessMessage(res.message);
          this.uploadingFile = false;
          this.onSuccessfulComment.emit();
        } else {
          // error: res.message
          this.alert.showErrorMessage(res.message);
          // hide loader
          this.store.dispatch(new LoaderAction.SetLoader(false));
          this.disableSubmit = false;
          this.uploadingFile = false;


        }
      });

    }
  }

  currentUploadIndex = 0;
  errorText = false;
  errorTextProblem = false;

  reportAnIssue() {
    // show loader
    // this.disableSubmit = true;
    this.isLoading = false;
    // this.disabledCrossIcon = true;
    this.errorText = false;
    this.errorTextProblem = false;
    this.commentBlank = false
    if (!this.commentText || (this.commentLength < 40)) {
      // this.errorText = true;
      this.commentBlank = true;
      this.disabledCrossIcon = false;
      return;
    }
    else {
      this.disabledCrossIcon = true;
    }

   // if(this.reqObj.categories == 'Idea Type' || this.reqObj.categories == 'Suggestion Type')
    this.store.dispatch(new LoaderAction.SetLoader(true));
    this.currentUploadIndex = 0;
    this.uploadingFile = true;
    this.uploadFiles();
  }

  public descriptionLength = 0;

  discardChanges = false;
  cancelClick() {
    this.discardChanges = false;
    $('.reportIssue').removeClass('discardOpen');
  }

  closePopup() {
    if (this.commentLength  || this.mydata.length) {
      $('.reportIssue').addClass('discardOpen');
      this.discardChanges = true;
    } else {
      this.fileAdded = false;
      this.fileSizeError = false;
      this.onClosePopUp.emit();
      $('#addComment').modal('hide');
      $('#addComment .modal-backdrop').removeClass('show');
    }
  }

  onDiscard() {
    this.onClosePopUp.emit();
    // this.discardComplete.emit(); need to check
    $('#addComment').modal('hide');
    $('#addComment .modal-backdrop').removeClass('show');
    $('.reportIssue').removeClass('discardOpen');
    this.discardChanges = false;
    this.disabledCrossIcon = false;
    this.fileAdded = false;
    this.fileSizeError = false;
  }

  checkIfDisabled() {
    if (this.commentLength >= 40
    ) {
      return true;
    } else {
      return false;
    }
  }
  onFocusDescription(){
    this.commentBlank=false;
  }
}

<div class="modal fade" tabindex="-1" role="dialog" id="timelinePopup" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom timelinePopup" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="col-1"></div>
          <div class="col-10 text-center">
            <h5 class="modal-title">Issue Timeline</h5>
          </div>
          <div class="col-1 ms-3">
            <button
              type="button"
              class="close"
              (click)="onClosePopup()"
            >
            </button>
          </div>
        </div>
        <div class="modal-body">

<div class="timeLine">
  <div class="loopContainer" *ngFor="let data of timeline">
    <p class="mb-0 text-center"><span class="issueType">{{data.displayName}}</span></p>
  <ul>
    <!--  [ngClass]="data.reportedOn ? 'active' : 'listtimeline'" -->
    <li class="listtimeline" [ngClass]="{activeList: data.reportedOn, heightZero: data.pickedAt.length==0}">
      <div class="d-flex" [ngClass]="{active: data.reportedOn}"><em><b *ngIf="data.reportedOn">{{data.reportedOn}}</b>
        <span *ngIf="data.reportedOn">{{getSolutionDate(data.reportedonTime)}}</span></em>
      <p [ngClass]="{activeData: data.reportedOn}"><span *ngIf="data.reportedOn && data.displayName === 'Original'" class="bubble reported">Reported</span>
        <span *ngIf="data.reportedOn && data.displayName !== 'Original'" class="bubble reported">Re-opened</span></p>
    </div>
    </li>
      <li class="listtimeline" [ngClass]="{activeList: data.pickedAt, heightZero: data.resolvedOn.length==0}">
        <div class="d-flex" [ngClass]="{active: data.pickedAt}"><em><b *ngIf="data.pickedAt">{{data.pickedAt || '-'}}</b>
          <span *ngIf="data.pickedAt">{{getSolutionDate(data.pickedTime)}}</span></em>
          <p [ngClass]="{activeData: data.pickedAt}"><span *ngIf="data.pickedAt" class="bubble picked">Picked</span></p>
        </div>
        </li>
        <li class="listtimeline" [ngClass]="{activeList: data.resolvedOn}">
          <div class="d-flex" [ngClass]="{active: data.resolvedOn}"><em><b *ngIf="data.resolvedOn">{{data.resolvedOn}}</b>
            <span *ngIf="data.resolvedOn">{{getSolutionDate(data.resolvedTime)}}</span></em>
            <p [ngClass]="{activeData: data.resolvedOn}">
              <span *ngIf="data.resolvedOn && data.closedAt == '' " class="bubble resolved">Resolved</span>
              <span *ngIf="data.closedAt" class="bubble closed">Closed</span>
            </p>
        </div>
      </li>
  </ul>
</div>
</div>



            <!-- <div class="openIssuesList" *ngFor="let data of timeline">
             <div><span>{{data.displayName}}</span></div>
             <div *ngIf="data.reportedOn"><b>Reported on</b> {{data.reportedOn}}</div>
             <div *ngIf="data.reopenedOn"><b>Reopened on</b> {{data.reopenedOn}}</div>
             <div><b>Picked on</b> {{data.pickedAt || '-'}}</div>
             <div><b>{{data.closedAt ? 'Closed On ' : 'Resolved On'}}</b> {{data.closedAt ? data.closedAt : data.resolvedOn || '-' }}</div>
           </div> -->
        </div>
    </div>
  </div>
</div>  
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpService } from './http.service';
import * as JSLZString from 'lz-string';


@Injectable({
  providedIn: 'root'
})
export class EventDataBusService {
  public hideHeader = new Subject<boolean>();
  public feedbackFormInfo = new BehaviorSubject<any>([]);
  public selectedPopForm = {
    submissionFormId: []
  };

  constructor(
    private http: HttpService,
  ) { }

  updateFeedbackPopupSeen(formType: string, startDate: string, endDate: string) {
    return this.http.patch(`v4/auth/teachers-feedback/${formType}/pop-up-seen?startDate=${startDate}&endDate=${endDate}`, true, {});
  }
  getMyPlanData(courseId?: any) {
    return this.http.get('v4/auth/my-plan/syllabus' + (courseId ? '?courseIds=' + courseId : ''), true, null, true);
  }

  parseResponse(data: any) {
    let parsedResult;
    try {
      parsedResult = JSON.parse(JSLZString.decompressFromEncodedURIComponent(data));
    } catch (err) {
      parsedResult = {};
    }
    return parsedResult;
  }
}


export const TEACHERS_FEEDBACK_SUBJECTS = {
  'all': 'All Subjects',
  'mathematics': 'Mathematics',
  'physics': 'Physics',
  'chemistry': 'Chemistry',
  'biology': 'Biology',
  'english': 'English',
  'socialscience': 'Social science',
  'mat': 'Mat',
  'hindi': 'Hindi',
  'logical reasoning': 'LOGICAL REASONING'
};

export const MONTHS = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
];

export const SUBJECTS = {
  ALL_SUBJECTS: 'All Subjects',
}

export const WEEK_TYPE = {
  ALL_WEEKS : 'All Weeks',
  WEEK : 'Week'
}

export const FEEDBACK_EDIT = 'Edit';

export const TAB = {
  week: 'week',
  phase: 'phase',
  month: 'month',
};

export const ALL_CONCEPTS = 'all the concepts';

export const FEEDBACK_STATUS = {
  EDIT: 'Edit',
  GIVE: 'Give',
}

export const DEFAULT_FILTER_VALUE = 'all';

export const FEEDBACKS = {
  Excellent: 'excellent',
  Never: 'never',
  Unsatisfactory: 'unsatisfactory',
  Seldom: 'seldom',
  Satisfactory: 'satisfactory',
  Good: 'good',
  Always: 'always',
  Yes: 'yes',
  Completed: 'completed',
}

export const WEEKLY_FORM_NOTIFICATION = 'weekly-form-open';

export const VALID_ROUTES = ['/home'];

export const MY_TEACHERS_FEEDBACK = "myTeachers' Feedback";

export const QUESTION_TYPE = {
  MCQ: 'MCQ',
  DESCRIPTIVE: 'Descriptive'
}

export const DATE_FORMAT = 'd MMM y';

export const FORM_TYPE = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  PHASE: 'phase'
}

export const ROUTES = {
  HOME: 'home',
  FEEDBACK: 'my-teachers-feedback'
};

export const WEEK_HEADING = {
  CLOSURE: 'Closure Week',
  MYPLAN: 'Week as per myPlan'
}

export const RESPONSES = {
  NOT_STARTED: 'Not Started yet',
  PARTIALLY_COMPLETED: 'Partially Completed',
  NEAR_COMPLETION: 'Near Completion',
  COMPLETED: 'Completed',
};

export const FORM_UPDATED_MSG = 'Feedback Responses updated successfully';

export const FORM_ERROR = 'Your attendance is not up to the mark to submit the feedback.';

export const TABLE: any = {
  phase: {
    SINGLE_SUBJECT: [
      { 'key': 'teacher', 'displayName': 'Teacher' },
      { 'key': 'status', 'displayName': 'Status' },
    ],
    ALL_SUBJECTS: [
      { 'key': 'teacher', 'displayName': 'Teacher' },
      { 'key': 'subject', 'displayName': 'Subject' },
      { 'key': 'status', 'displayName': 'Status' },
    ],
    ALL_PHASES: [
      { 'key': 'phase', 'displayName': 'Phase' },
      { 'key': 'status', 'displayName': 'Status' },
    ],
  },
  month: {
    ALL_SUBJECTS: [
      { 'key': 'teacher', 'displayName': 'Teacher' },
      { 'key': 'subject', 'displayName': 'Subject' },
      { 'key': 'status', 'displayName': 'Status' },
    ],
    SINGLE_SUBJECT: [
      { 'key': 'teacher', 'displayName': 'Teacher' },
      { 'key': 'status', 'displayName': 'Status' },
    ],
    ALL_PHASES: [
      { 'key': 'month', 'displayName': 'Month' },
      { 'key': 'status', 'displayName': 'Status' },
    ],
  },
  STATUS: {
    GIVEN: 'Given',
    NOT_GIVEN: 'Not Given',
  },
  DUMMY_PROFILE_SRC: '/assets/images/teacher-feedback/dummy_profile.svg',
}

export const INIT_TIMEOUT = 2500;
<!-- info popup -->
<div class="modal fade" id="infoPopup" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <!-- imageName: trickyQuestion, toughQuestion, attemptPercent, averageTime, scoringQuestion, trickyQuestion -->
          <img src={{imgSrc}}>
          <h4>{{heading}}</h4>
          <p class="smallText">{{text}}</p>
          
        </div>
        <div class="footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">Got it</button>
        </div>
      </div>
    </div>
  </div>
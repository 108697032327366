<div class="modal fade" tabindex="-1" role="dialog" id="addComment">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom reportIssue" role="document">
      <div class="modal-content"><div class="opacityDiscard"></div>
        <div class="modal-header">

            <h5 class="modal-title">Add a Comment</h5>

            <button
              [ngClass]="discardChanges ? 'close closedisable':'close'"
              (click)="closePopup()"
              [disabled]="disabledCrossIcon"
            >
            </button>

        </div>
        <div class="modal-body">
            

          
          <div class="form-group" *ngIf="true">
            <textarea [disabled]="isLoading" [(ngModel)]="commentText" (keyup)="onKeyUp()" placeholder="Write your comments in detail..." rows="6" [ngClass]="{'redborder': commentBlank}"></textarea>
            <span class="error counter" [ngClass]="commentLength >= 40 ? 'textColorChange' : ''" *ngIf="!errorText"><span id="current" class="coutText">{{commentLength}} <span *ngIf="commentLength == 1">character</span><span *ngIf="commentLength != 1">characters</span></span> (min 40 characters)</span>
          </div>
          <div class="form-group attachMents">
            <label>Attachments<div [ngClass]="fileTypeAccept?'error':'fileTypeUpload'" style="margin-top: 0;">only .jpg, .jpeg, .png and .mp4 files are supported</div></label>
            <div class="attachFile">
              <span class="blankData">
                <label for="files" class="btn"></label>
                <input type='file' id="files" accept=".jpg,.jpeg,.png,.mp4," (change)="onSelectFile($event)" (click)="onimageClick()"  multiple style="display: none;"/>
              </span>
              <span *ngFor="let list of mydata;let index = index">
                <img class="img" *ngIf="list.type == 'image'" [src]="list.url" />
                <img class="img" *ngIf="list.type == 'video'"
                    src="../../../../../../assets/images/help/bgwith-play.png"
                    alt="Video"
                />
                <button  class="closebutton" (click)="closeThumb(index)" href="javascript:void(0);"  [disabled]="disabledCrossIcon"
                  ><img
                    src="../../../../../../assets/images/help/attachClose.svg"
                    alt="close"
                /></button>
              </span>
              <div class="blankDiv w-100">
              <p class="fileSizeText">Max. File Size: 100 MB</p> 
              </div>
            </div>
            <p class="fileSizeText error" *ngIf="fileAdded">File is already added!</p>
            <div class="error mtop" *ngIf="fileSizeError">Files greater than 100 MB cannot be added</div>
          </div>
          <div *ngIf="discardChanges" class="discardChanges">
            <div class="txtDiscard">
              <b>Discard Changes?</b>
              <em>Changes you made will be lost</em>
            </div>
            <div class="discardCta">
              <button type="button" (click)="onDiscard()" class="btn textButton">Discard</button>
              <button type="button" (click)="cancelClick()" class="btn btn-primary">Cancel</button>
            </div>
          </div>
          <div class="submitCta">
            <label *ngIf="!this.mydata.length" class="noAttachment">No File Attached</label>
            <label class="noAttachment" *ngIf="this.mydata.length">{{this.mydata.length}} Attachment{{this.mydata.length > 1 ? 's' : ''}} added</label>
            <button type="button" [ngClass]="{'disableBtn': !checkIfDisabled()}" [disabled]="disableSubmit" (click)="reportAnIssue()" class="btn btn-primary">
              Submit</button>
          </div>
        </div>
        <div class="uploaderprogress" *ngIf="uploadingFile">
        <div class="progress" style="height:10px">
          <div class="progress-bar" style="width:40%;height:10px"></div>
        </div>
        </div>
      </div>
    </div>
  </div>

<div class="customKeypad">
    <div class="form-group form-inline" [ngClass]="{'correctAnsTick': isAttempted && isCorrectAttempt}">
        <input type="text" id="integer" class="form-control" onkeydown="return false"
            (ngModelChange)="updateValue($event)" [(ngModel)]="answerKey" maxlength="1000" name="integerKey"
            [ngClass]="{'userclicked': !isAttempted && answerKey, 'correctAttempt': isAttempted && isCorrectAttempt, 'incorrectAttempt': isAttempted && !isCorrectAttempt}" />
        <button type="button" class="btn btnBackspace ml-3" (click)="backspaceClick()">Backspace</button>
    </div>
    <div class="keypad">
        <div class="number">
            <a (click)="numberIntegerClick('7')">7</a>
            <a (click)="numberIntegerClick('8')">8</a>
            <a (click)="numberIntegerClick('9')">9</a>
        </div>
        <div class="number">
            <a (click)="numberIntegerClick('4')">4</a>
            <a (click)="numberIntegerClick('5')">5</a>
            <a (click)="numberIntegerClick('6')">6</a>
        </div>
        <div class="number">
            <a (click)="numberIntegerClick('1')">1</a>
            <a (click)="numberIntegerClick('2')">2</a>
            <a (click)="numberIntegerClick('3')">3</a>
        </div>
        <div class="number">
            <a (click)="numberIntegerClick(0)">0</a>
            <a (click)="dotClick()">.</a>
            <a (click)="dashClick()">-</a>
        </div>
        <div class="number">
            <a data="left" (click)="leftArrowClick()"><img src="/assets/images/revision/backIcon.svg"></a>
            <a data="right" (click)="rightArrowClick()"><img src="/assets/images/revision/nextIcon2.svg"></a>
            <a href="javascript.void(0)"></a>
        </div>
    </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToAlphabet'
})
export class NumberToAlphabetPipe implements PipeTransform {
  transform(value: number): string {
    return String.fromCharCode(64 + value);
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import ClevertapReact from 'clevertap-react';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// Needs to import the BrowserAnimationsModule

import { AppReducers } from './stores/app.reducers';
import { AuthEffects } from './stores/auth/auth.effects';
import { UserEffects } from './stores/profile/user-profile.effect';
import { ProformaEffects } from './stores/proforma/proforma.effect';

import { SharedModule } from './shared/shared.module';
import { DetectLocationService } from './shared/services/detect-location.service';

import { AuthGaurd } from './auth-guard.service';
import { environment } from './../environments/environment';
import { ProfileService } from './profile/shared/services/profile.service';
import { GoalSettingService } from './goal-setting/services/goal-setting.service';
import { AlertService } from './shared/services/alert.service';
import { VerifyPasscodeComponent } from './auth/verify-passcode/verify-passcode.component';
import { FiitJeeService } from './auth/fiit-jee-login/service/fiit-jee.service';
ClevertapReact.initialize(environment.clevertapId);

import { IdeasComponent } from './ideas/ideas.component';
import { IdeaFilterComponent } from './ideas/idea-filter/idea-filter.component';
import { HeaderBeforeLoginComponent } from './components/header-before-login/header-before-login.component';
import { AlertComponent } from './components/alert/alert.component';
import { DatePipe } from '@angular/common';
@NgModule({
  declarations: [
    AlertComponent,
    HeaderBeforeLoginComponent,
    AppComponent,
    VerifyPasscodeComponent,
    IdeasComponent,
    IdeaFilterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    StoreModule.forRoot(AppReducers, {
      runtimeChecks: {
        strictStateImmutability: false, // set this to false
    },
    }),
    EffectsModule.forRoot([AuthEffects, UserEffects, ProformaEffects]),
  ],
  providers: [DatePipe, AlertService, AuthGaurd, DetectLocationService, ProfileService, GoalSettingService, FiitJeeService, { provide: 'googleTagManagerId', useValue: environment.gtmId }],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../../stores/app.reducers';
declare var $: any;

@Component({
  selector: 'app-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss', '../header-after-login.component.scss']
})
export class ProfileDropdownComponent implements OnInit {

  @Input() userData: any;
  public photoUrl = '';
  public userName = '';
  public gotImage = false;
  public initials: any = '';
  public intialsClass: string;
  @Output() openLogout = new EventEmitter<any>();
  @Output() openPassword = new EventEmitter<any>();

  constructor(
    private router: Router,
    private store: Store<fromApp.AppState>,
  ) { }

  ngOnInit(): void {
    this.store.select('userProfile').subscribe((userProfile) => {
      this.userData = userProfile.userData;
      this.userName = this.userData.name ? this.userData.name : '';
      this.photoUrl = this.userData.photoUrl ? this.userData.photoUrl : '';
      this.checkIfImageExist();
    });

    this.store.select('userProfile').subscribe((userProfile) => {
      this.userData = userProfile.userData;
    });
  }

  checkIfImageExist(): void {
    if (this.userData.userId && this.userData.photoUrl) {
      this.gotImage = true;
    } else {
      this.gotImage = false;
    }
  }
  goToProfile(): void {
    this.router.navigate(['/profile/personal-info']);
  }

  goToRefer(): void {
    this.router.navigate(['/refer-and-earn']);
  }

  changePassword(): void {
    this.openPassword.emit();
  }

  logoutConfirm(): void {
    if (this.userData.isTeacher) {
      return;
    }
    this.openLogout.emit();
  }
  goToManageDevice(): void {
    this.router.navigate(['/manage-devices']);
  }
}

import { DOCUMENT } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-before-login',
  templateUrl: './header-before-login.component.html',
  styleUrls: ['./header-before-login.component.scss']
})
export class HeaderBeforeLoginComponent implements OnInit {
  sidebarOverlay: boolean;
  @ViewChild('sideBar') sideBarRef: ElementRef;

  constructor(@Inject(DOCUMENT) private document, private router: Router) { }

  ngOnInit(): void {
    this.sidebarOverlay = false;
    this.headerScrollFixed();
  }

  showSidebar(): void {
    this.sideBarRef.nativeElement.style.left = '0px';
    this.sidebarOverlay = !this.sidebarOverlay;
  }

  hideSidebar(): void {
    this.sideBarRef.nativeElement.style.left = '-1000px';
    this.sidebarOverlay = !this.sidebarOverlay;
  }

  navigateToSignIn(): void {
    this.router.navigate(['/auth/sign-in']);
  }

  navigateToSignUp(): void {
    this.router.navigate(['/auth/sign-up']);
  }

  navigateToReferEarn(): void {
    this.router.navigate(['/refer-and-earn']);
  }
  //Header fixed on scroll
  headerScrollFixed(): void{
    window.addEventListener('scroll', this.scrollListener);
  }
  scrollListener(){
    if(this.document.documentElement.scrollTop > 0){
      this.document.querySelector('header').classList.add('fixed-top');
    }else{
      this.document.querySelector('header').classList.remove('fixed-top');
    }
  }
  ngOnDestroy(): void{
    window.removeEventListener('scroll', this.scrollListener);
  }
}
<div class="VerifyOTPScreen">
  <div class="backArrow" (click)="backClicked()">
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M0 12L12 24.0015L14.1195 21.879L5.742 13.5H24V10.5H5.742L14.1195 2.121L12 0L0 12Z" fill="#333333" />
    </svg>
  </div>
  <div class="verifyotpimg">
    <img class="logo-block" src="/assets/senior-images/glorifire-logo-1.svg" alt="Glorifire Logo"/>
  </div>
  <h3>Verification</h3>
  <p>
    Please enter the 4-digit OTP sent to your<br />
    registered mobile/email
  </p>
  <ng-container *ngIf="fromScreen === 'manage-devices';else editableDetails">
    <div class="mobiletext otpcodesection">
      <ng-container *ngIf="userInfo?.mobile">{{ userInfo?.countryCode }} - {{ userInfo?.mobile }}</ng-container> <ng-container *ngIf="userInfo?.email || userInfo?.mobile_email"> {{userInfo?.mobile && '&'}} {{ userInfo?.email || userInfo?.mobile_email }} </ng-container>
    </div>
  </ng-container>
  <ng-template #editableDetails>
    <div class="mobiletext" *ngIf="userInfo?.countryCode && phoneNumber">
      {{ userInfo?.countryCode }} - {{ phoneNumber }}
      <a *ngIf="fromScreen !== 'manage-devices'" class="edit-icon" (click)="backClicked('editClicked')"><svg width="20"
          height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.4722 1.9425C14.7076 1.7071 14.9871 1.52037 15.2946 1.39298C15.6022 1.26558 15.9318 1.20001 16.2647 1.20001C16.5976 1.20001 16.9273 1.26558 17.2348 1.39298C17.5424 1.52037 17.8218 1.7071 18.0572 1.9425C18.2926 2.17789 18.4794 2.45735 18.6068 2.76491C18.7341 3.07247 18.7997 3.40211 18.7997 3.73501C18.7997 4.06792 18.7341 4.39756 18.6068 4.70512C18.4794 5.01268 18.2926 5.29213 18.0572 5.52753L5.95774 17.627L1.02832 18.9714L2.37271 14.042L14.4722 1.9425Z"
            stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </a>
    </div>
  </ng-template>
  <!-- <div class="mobiletext" *ngIf="userInfo.mobile_email">
    {{userInfo.mobile_email}}
  </div> -->

  <form #otpForm="ngForm" (submit)="submitOTP(otpForm.value)" class="otp-form">
    <div class="verify-form-group" id="otp-container">
      <input type="text" class="form-control inputs" name="otp1" id="otp1" maxlength="1"
        oninput="this.value = this.value.replace(/[^0-9]/g, '')" ngModel required />
      <input type="text" class="form-control inputs" name="otp2" id="otp2" maxlength="1"
        oninput="this.value = this.value.replace(/[^0-9]/g, '')" ngModel required />
      <input type="text" class="form-control inputs" name="otp3" id="otp3" maxlength="1"
        oninput="this.value = this.value.replace(/[^0-9]/g, '')" ngModel required />
      <input type="text" class="form-control inputs" name="otp4" id="otp4" maxlength="1"
        oninput="this.value = this.value.replace(/[^0-9]/g, '')" ngModel required />
    </div>
    <div class="error" *ngIf="otpApiError">{{ otpApiError }}</div>
    <div class="row">
      <div class="col-12">
        <div class="otp-container">
          <p class="otptime" *ngIf="showTimer && timeValue">
            00:{{ timeValue < 10 ? "0" + this.timeValue : this.timeValue }} </p>
              <!-- <p *ngIf="!showTimer" (click)="resendOtp()" class="resendotp">Resend OTP</p> -->
              <p *ngIf="!showTimer" class="resendotp">
                <a (click)="resendOtp()">Resend OTP</a>
              </p>
        </div>
      </div>
    </div>
    <!-- captcha -->
    <div class="captcha-container" *ngIf="captchaShow && !showTimer">
      <div class="captcha" appCaptcha [config]="'qq'" [lang]="'en'" (captchaExpired)="captchaExpired()"
        (captchaResponse)="getSuccess()"></div>
    </div>
    <div class="error" *ngIf="showCaptchaError && !capcthaChecked">
      <span class="captcha-error"> Please select captcha* </span>
    </div>

    <!-- capctha ends -->
    <button type="submit" class="btn btn-primary submit-btn mt10">Verify</button>
  </form>
</div>
<div class="row">
  <div class="col-sm-12 d-block d-sm-none">
    <div class="navheader d-md-none">
      <h2><img appLazyLoad src="/assets/img/s3-images/cross-icon-black.png" (click)="closeFilter()" class="cross_img">Setting</h2>
    </div>
  </div>
</div>
<div class="sidebar-item listView" [ngClass]="{'mt-offset': (marginOffset && !isMobile)}" >
  <span>
  <h4 *ngIf="marginOffset" class="filterHeading">Result Modified&nbsp;<button class="btn p-0" data-toggle="modal" data-target="#resultModifiedModal"><img class="info-img" src="/assets/images/info-icon.svg" alt="Info icon"/></button></h4>
<div class="card filterCard" *ngIf="marginOffset" >
  <ul>
    <li class="list-group-item">
      <label class="container">Impacted Questions
        <input type="checkbox" [ngModel]="overviewService.viewUpdateFlag" (ngModelChange)="viewUpdateCheck($event)">
        <span class="checkmark"></span>
      </label>
    </li>
  </ul>
</div>
</span>
  <span *ngFor="let filterData of filterDataToShow">
    <span *ngIf="filterData.filterKey">
      <span *ngIf="filterData.type==='multi'">
        <!-- <div class="card">
          <ul class="list-group list-group-flush"> -->
            <app-multiselectfilter [filters]="filterData" [clearingData]="clearingData" (onChange)="onChangeMultiSelect($event)"></app-multiselectfilter>
          <!-- </ul>
        </div> -->
      </span>
      <span *ngIf="filterData.type==='single'">
        <!-- <div class="card">
          <ul class="list-group list-group-flush"> -->
            <app-single-select-filter [filters]="filterData" [clearingData]="clearingData" (onChange)="onChangeSingleSelect($event)"></app-single-select-filter>
          <!-- </ul>
        </div> -->
      </span>
    </span>
  </span>
  <div class="col-sm-12 d-block d-sm-none">
      <div class="filter_close">
        <button class="btn btn-primarytext" (click)="resetFilters()">Reset</button>
        <button class="btn btn-primary btn-lg filter-submit d-md-none" (click)="apply()">Apply</button>
      </div>
    </div>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { HelpService } from 'src/app/help-and-support/services/help.service';
import * as fromApp from '../../../stores/app.reducers';
import { HttpService } from '../../../shared/services/http.service';
import { AlertService } from '../../services/alert.service';
import { HomeService } from 'src/app/home-page/services/home.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import _ from 'lodash';

declare var $: any;
@Component({
  selector: 'app-help-reopen-popup',
  templateUrl: './help-reopen-popup.component.html',
  styleUrls: ['./help-reopen-popup.component.scss'],
})
export class HelpReopenPopupComponent implements OnInit {
  @Output() discardComplete = new EventEmitter<any>();
  @Input() issueId: string;
  reqObj = {
    reopeningReason: 'Select',
    description: '',
    attachments: [],
    issueId: ''
  };
  errorAndSuccess = false;
  isLoading = false;
  selectedProblemTypeId: any;
  public dataToPassForUserCards: any;
  issueType = [];
  mydata = [];
  fileSizeError = false;
  fileTypeAccept = false;
  currentUploadIndex = 0;
  errorText = false;
  errorTextProblem = false;
  discardChanges = false;
  disableSubmit = false;
  fileAdded: boolean = false;
  public disabledCrossIcon: boolean = false;
  descriptionError : boolean = false;
  constructor(
    private helpServices: HelpService,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private httpService: HttpService,
    private alert: AlertService,
    private router: Router,
    private location: Location,
    private homePageService: HomeService
  ) { }

  ngOnInit(): void {
    this.helpServices.helpIssueType().then((res) => {
      this.issueType = _.get(res, 'issue.reopeningReasons', []);
    });
    this.fileAdded = false;
  }

  onChangeProblem(item: string): void {
    this.errorTextProblem = false;
    this.reqObj.reopeningReason = item;
    if (this.reqObj.reopeningReason === 'Problem Type') {
      this.errorTextProblem = true;
    }
  }

  onimageClick() {
    $('#files')[0].value = null;
  }

  onSelectFile(event) {
    this.fileSizeError = false;
    this.fileTypeAccept = false;
    const files = event.target.files;

    let ext = $('#files').val().split('.').pop().toLowerCase();
    if (files.length == 0 || ext == '') {
      return;
    }
    if (
      ext !== 'jpg' &&
      ext !== 'jpeg' &&
      ext !== 'png' &&
      ext !== 'mp4' &&
      ext !== 'mov' &&
      ext !== 'hevc'
    ) {
      this.fileTypeAccept = true;
      this.fileAdded = false;
      return false;
    }

    if (files) {
      for (const file of files) {
        const reader = new FileReader();
        let fileType = (Math.floor(Math.log(file.size) / Math.log(1024)));
        let actualSize = Math.round(file.size / Math.pow(1024, fileType));
        const fileName = file.name.split(' ').join('');
        reader.onload = (e: any) => {
          if (file.type.indexOf('image') > -1) {
            if (fileType === 2 && actualSize >= 100) {
              this.fileSizeError = true;
              this.fileAdded = false;
              return;
            }
            const index = this.mydata.findIndex((obj) => obj.file.name == file.name)
            if (index == -1) {
              this.fileAdded = false;
              this.mydata.push({
                url: e.target.result,
                type: 'image',
                name: fileName,
                file: file,
              });
            } else {
              this.fileAdded = true;
              this.fileSizeError = false;
            }
            // this.getPreSignedUrl();
          } else if (file.type.indexOf('video') > -1) {
            if (fileType === 2 && actualSize >= 100) {
              this.fileSizeError = true;
              this.fileAdded = false;
              return;
            }
            const index = this.mydata.findIndex((obj) => obj.file.name == file.name)
            if (index == -1) {
              this.fileAdded = false;
              this.mydata.push({
                url: e.target.result,
                type: 'video',
                name: fileName,
                file: file,
              });
            } else {
              this.fileAdded = true;
              this.fileSizeError = false;
            }
          }
        };
        reader.readAsDataURL(file);
      }
    }
  }

  closeThumb(index) {
    this.fileAdded = false;
    this.mydata.splice(index, 1);
  }

  // get permission from s3 to upload image
  getPreSignedUrl(obj): void {
    const apiUrl = 'v4/auth/upload-signed-url?location=ideasAndSuggestions';
    this.httpService.get(apiUrl, true, { key: obj.name }, false).subscribe(
      (res) => {
        if (res && res.code === 200) {
          if (res.data.url && res.data.fields) {
            obj.photoUrlCopy = res.data.url + '/' + res.data.fields.key;
            this.uploadYourFileOnS3(res.data, obj);
          }
        }
      },
      (error) => {
        this.currentUploadIndex++;
        obj.status = 'Failed';
        this.uploadFiles();
      }
    );
  }

  // upload image after permision
  uploadYourFileOnS3(data, obj): void {
    const formData: FormData = new FormData();
    formData.append('key', data.fields.key);
    formData.append('bucket', data.fields.bucket);
    formData.append('acl', 'public-read');
    formData.append('X-Amz-Algorithm', data.fields['X-Amz-Algorithm']);
    formData.append('X-Amz-Credential', data.fields['X-Amz-Credential']);
    formData.append('X-Amz-Date', data.fields['X-Amz-Date']);
    formData.append('Policy', data.fields['Policy']);
    formData.append('X-Amz-Signature', data.fields['X-Amz-Signature']);
    formData.append('file', obj.file, obj.name);

    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    };
    this.http.post(data.url, formData, options).subscribe(
      (res) => {
        this.currentUploadIndex++;
        obj.status = 'Uploaded';
        this.uploadFiles();
      },
      () => {
        this.currentUploadIndex++;
        obj.status = 'Failed';
        this.uploadFiles();
      }
    );
  }

  uploadFiles() {
    this.errorAndSuccess = false;
    this.isLoading = true;
    this.descriptionError = false;
    if (this.currentUploadIndex < this.mydata.length) {
      this.getPreSignedUrl(this.mydata[this.currentUploadIndex]);
    } else {
      this.reqObj.attachments = [];
      this.mydata.forEach((item) => {
        this.reqObj.attachments.push({
          type: item.type,
          url: item.photoUrlCopy,
        });
      });
      this.reqObj.issueId = this.issueId;
      this.helpServices.reopenAnIssue(this.reqObj).then(
        (res) => {
          if (res.code === 200) {
            // success
            this.reqObj.reopeningReason = 'Problem Type';
            this.reqObj.description = '';
            this.reqObj.attachments = [];
            this.mydata = [];
            this.reqObj.issueId = '';
            $('#reopenIssue').modal('hide');
            $('.modal-backdrop').removeClass('show');
            $('.reopenIssue').removeClass('discardOpen');
            this.discardChanges = false;
            this.isLoading = false;
            //this.errorAndSuccess = true;
            this.alert.showSuccessMessage(res.message);
            this.discardComplete.emit('');
            this.alert.notificationCountUpdate.next(1);
            // hide loader and close popup then show sucsses res.message
            this.homePageService.fetchHomeCardsData().then((res) => {
              if (res) {
                if (res.userCards && res.userCards.length) {
                  this.dataToPassForUserCards = JSON.parse(
                    JSON.stringify(res.userCards)
                  );
                  this.dataToPassForUserCards[2].data.forEach((element) => {
                    if (element.type === 'help') {
                      this.helpServices.UpdatedDataHelp.next(element);
                    }
                  });
                }
              }
            });
          } else {
            this.isLoading = false;
            this.alert.showErrorMessage(res.message);
          }
        },
        (error) => {
          this.alert.showErrorMessage('Something went wrong');
        }
      );
    }
  }

  reportAnIssue() {
    this.errorText = false;
    this.isLoading = false;
    this.errorTextProblem = false;
    // this.disabledCrossIcon = true;
    this.descriptionError = false;
    if (this.reqObj.reopeningReason == 'Problem Type') {
      this.errorTextProblem = true;
      return;
    }
    if(this.reqObj.reopeningReason === 'Select'){
      this.errorTextProblem = true;
      return;
    }
    if (this.reqObj.reopeningReason !== 'Select' && (this.descriptionLength < 40)) {
      this.descriptionError = true;
      return;
    }
    if (this.reqObj.reopeningReason !== 'Select' && (this.descriptionLength < 40)) {
      this.disabledCrossIcon = false;
      return;
    }
    else {
      this.disabledCrossIcon = true;
    }
    this.reqObj.description = this.reqObj.description.trim();
    if (
      !this.reqObj.description ||
      this.reqObj.description.replace(/ /g, '').length < 10
    ) {
      this.errorText = true;
      return;
    }

    // show loader
    this.currentUploadIndex = 0;
    this.uploadFiles();
  }
  public descriptionLength = 0;
  onKeyUp() {
    // character count
    const count = this.reqObj.description.replace(/\r?\n|\r/g, "");
    this.descriptionLength = count.replace(/ /g, '').length;
    this.descriptionError = false;
    if (!this.reqObj.description || (this.reqObj.description.replace(/ /g, '').length < 40)) {
      //this.errorText = true;
      this.descriptionError = false;
    } else {
      //this.errorText = false;
      this.disableSubmit = false;
      this.descriptionError = false;
    }
  }

  cancelClick() {
    this.discardChanges = false;
    $('.reopenIssue').removeClass('discardOpen');
  }

  closePopup() {
    if (this.descriptionLength || this.mydata.length) {
      $('.reopenIssue').addClass('discardOpen');
      this.discardChanges = true;
    } else {
      this.fileSizeError = false;
      this.fileTypeAccept = false;
      this.reqObj.description = '';
      this.descriptionLength = 0;
      $('#reopenIssue').modal('hide');
      $('.modal-backdrop').removeClass('show');
      this.discardComplete.emit('');
    }
    this.fileSizeError = false;
    this.fileTypeAccept = false;
  }

  onDiscard() {
    this.reqObj.description = '';
    this.reqObj.reopeningReason = 'Problem Type';
    this.discardComplete.emit('');
    $('#reopenIssue').modal('hide');
    $('.modal-backdrop').removeClass('show');
    $('.reopenIssue').removeClass('discardOpen');
    this.discardChanges = false;
    this.fileSizeError = false;
    this.fileTypeAccept = false;
    this.fileAdded = false;
  }

  get checkIfDisabled() { 
    if (
      (this.reqObj.reopeningReason !== 'Select' &&
        this.descriptionLength >= 40)) {
      return true;
    } else {
      return false;
    }
  }
  focusreopeningReason(){
    this.errorTextProblem=false;
  }
  onFocusDescription(){
    this.descriptionError=false;
  }
}

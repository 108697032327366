import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { BehaviorSubject } from 'rxjs';

import * as fromApp from '../../../../stores/app.reducers';
import * as AuthActions from '../../../../stores/auth/auth.actions';
import * as UserProfileActionList from '../../../../stores/profile/user-profile.actions';
import * as ProformaActions from '../../../../stores/proforma/proforma.actions';

import { HttpService } from '../../../../shared/services/http.service';
import { AlertService } from '../../../../shared/services/alert.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root'
})
export class SignupService {
  public signUpVerified = new BehaviorSubject<any>(false);
  public otpApiError = new BehaviorSubject<any>('');
  public resetCaptcha = new BehaviorSubject<any>(false);
  public isLogOut = false;
  public visitorID: string = null;
  public userEnteredLoginInfo: any;
  constructor(private http: HttpService,
    private alert: AlertService,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private gtmService: GoogleTagManagerService
  ) { }

  applyReferralCode(value): any {
    return new Promise((resolve, reject) => {
      const apiUrl = 'v4/referral-code/' + value;
      this.http.get(apiUrl, false, null, true).subscribe(res => {
        resolve(res);
      });
    });
  }

  forgotPassword(body): any {
    return new Promise((resolve, reject) => {
      const apiUrl = 'v4/forgot-password';
      this.http.post(apiUrl, false, body, true).subscribe(res => {
        if (res.code === 200 || res.code === 404) {
          if (res.code === 200) {
            this.alert.showSuccessMessage(res.message);
          } else if (res.code === 404 && res.message.toLowerCase() === 'user not found') {
            this.alert.showErrorMessage(res.message);
          }
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  public $fingerprintID = new BehaviorSubject<boolean>(false);
  setFingerprintID(id:string){
    this.visitorID = id;
    this.$fingerprintID.next(!!id);
  }
  getFingerprintID(){
    return this.visitorID;
  }

  verifyOTP(from, body): any {
    let apiUrl = 'v4/verify-forgot-password';
    if (from == 'loginOtp') {
      apiUrl = 'v4/login/verify-otp';
    }
    body = {
      ...body,
      visitorId: this.visitorID
    };
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, false, body, true).subscribe(res => {
        if (res.code === 200) {
          localStorage.removeItem('lastRequested');
          resolve(res);
        } else if (res.code === 460) {
          this.store.dispatch(new AuthActions.AlreadyLoggedIn(res.data));
        } else if (res.code === 461) {
          this.store.dispatch(new AuthActions.UnregisterDevice(res.data));
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  resetPassword(body) {
    return new Promise((resolve, reject) => {
      const apiUrl = 'v4/auth/reset-password';
      this.http.post(apiUrl, true, body, true).subscribe(res => {
        this.alert.showSuccessMessage(res.message);
        resolve(res);
      });
    });
  }

  login(loginType, body) {
    let apiUrl;
    switch (loginType) {
      case 2:
        apiUrl = 'v4/login';
        break;
      case 1:
        apiUrl = 'v4/enrollment-login';
    }
    body = {
      ...body,
      visitorId: this.visitorID
    };
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, false, body, true).subscribe(res => {
        if (res.code === 200) {
          resolve(res);
        } else if (res.code === 460) {
          this.store.dispatch(new AuthActions.AlreadyLoggedIn(res.data));
        } else if (res.code === 461) {
          this.store.dispatch(new AuthActions.UnregisterDevice(res.data));
        } else {
          if (res.code === 400 && res.message === 'This mobile is not registered with us. Please Sign Up.') {
            // this.router.navigateByUrl('/auth/sign-up');
          }
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  getOrganisation(query) {
    return new Promise((resolve, reject) => {
      let apiUrl = 'v4/organizations';
      const queryString = `?instituteId=${query.instituteId}`;
      apiUrl += queryString;
      this.http.get(apiUrl, false, null, true).subscribe(res => {
        if (res.code === 200) {
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  sendOtp(body) {
    const apiUrl = 'v4/login/send-otp';
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, false, body, true).subscribe(res => {
        if (res.code === 200) {
          this.alert.showSuccessMessage(res.message);
          resolve(res);
        } else {
          // if (res.code === 400 && res.message === 'This mobile is not registered with us. Please Sign Up.') {
          //   this.router.navigateByUrl('/auth/sign-up');
          // }
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  navigateAfterLogin(data, userTag: string = 'student'): void {
    data.tag = userTag;
    localStorage.removeItem('lastRequested');
    localStorage.setItem('authToken', data.authToken);
    const gtmTag: any = {
      event: 'custom_event',
      custom_event_name: 'login',
      page_path: window.location.href,
      pagePath: window.location.href,
    };
    gtmTag.userId = window.localStorage.getItem('enrollmentNo') || 'undefined';
    const centreIds = window.localStorage.getItem('centres')?.split(',') || [];
    gtmTag.centre1 = centreIds[0] || 'undefined';
    gtmTag.centre2 = centreIds[1] || 'undefined';
    const batchIds = window.localStorage.getItem('batches')?.split(',') || [];
    gtmTag.batch1 = batchIds[0] || 'undefined';
    gtmTag.batch2 = batchIds[1] || 'undefined';
    gtmTag.batch3 = batchIds[2] || 'undefined';
    this.gtmService.pushTag(gtmTag);
    this.store.dispatch(new AuthActions.SetToken(data.authToken));
    this.store.dispatch(new AuthActions.SetAuthentication());
    this.store.dispatch(new UserProfileActionList.GetUserDetail(data));
  }

  setAuthData(authToken) {
    this.store.dispatch(new AuthActions.SetToken(authToken));
    this.store.dispatch(new AuthActions.SetAuthentication());
  }
}

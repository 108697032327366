export const IMAGE_PATH = '/assets/images/notification/new/';
export const NOTIFICATION_TYPE = {
    PTM_SCHEDULED: {type: 'ptm-scheduled', src: `${IMAGE_PATH}ptm-scheduled-icon.svg`},
    PTM_SCHEDULED_TOMORROW: {type: 'ptm-tomorrow', src: `${IMAGE_PATH}ptm-scheduled-icon.svg`},
    PTM_SCHEDULED_DELETED: {type: 'ptm-schedule-deleted', src: `${IMAGE_PATH}ptm-cancelled-icon.svg`},
    PTM_SCHEDULED_UPDATED: {type: 'ptm-schedule-updated', src: `${IMAGE_PATH}ptm-updated-icon.svg`},
    PTM_SCHEDULED_LIVE: {type: 'ptm-live', src: `${IMAGE_PATH}ptm-live-icon.svg`},
    CALENDAR_EVENT: {type: 'calendarEvent', src: `${IMAGE_PATH}calendar-event.svg`},
    ATTENDANCE_UPDATE: { type: 'attendanceStatusUpdated', src: `${IMAGE_PATH}attendanceUpdated.svg` },
}
export const MODULE = {
    COMMUNITY_ANSWER_VOTE: 'community-answer-vote',
    COMMUNITY_QUESTION_VOTE :'community-question-vote',
    COMMUNITY_ANSWER :'community-answer',
    COMMUNITY_MARK_BEST_ANSWER :'community-mark-best-answer',
    QUESTION_DELETED :'questionDeleted',
    QUESTION_APPROVED :'questionApproved',
    ANSWER_DELETED :'answerDeleted',
    UBER_BLOCKED_FOR_QUESTION :'userBlockedForQuestion',
    ANSWER_APPROVED :'answerApproved',
    USER_BLOCKED_FOR_ANSWER :'userBlockedForAnswer',
    USER_UNBLOCKED :'userUnblocked',
};

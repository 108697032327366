<!-- Responsive info modal  -->
<h4 class="mobileHeading d-block d-sm-none" *ngIf="!maxPageSize">{{displayStatus || 'All'}} <span>Issue(s)</span>
    <span *ngIf="displayStatus"><img src="/assets/images/info-icon.svg" data-toggle="modal" (click)="openInfo($event)"/> </span>
</h4>
<!-- End: Responsive info modal -->
<div class="reportListsHelp">
    <div *ngIf="!showLoader">
    <ng-container *ngFor="let helpReport of helpDataFiltered; let i=index">
    <div id="notificationListingTop{{helpReport?.ticketId}}"></div>
     <div *ngIf="isMatchWithFilter(helpReport)" class="accordion accordion-group" id="accordion{{i}}" aria-expanded="false" (click)="clickTab(i,helpReport)">
        <div>
            <div class="accordion-heading">
                <a (click)="resetImageLoaded()" class="accordion-toggle" aria-expanded="false" data-toggle="collapse" id="{{helpReport?.ticketId}}" data-parent="#accordion{{helpReport?.ticketId}}"
                    href="#collapse{{helpReport.ticketId}}">
                    <b class="titleTxt" innerHTML="{{ helpReport?.description | highlight : payload.searchQuery}}"></b>
                    <em class="{{getStyle(helpReport?.status)}}">{{getStausText(helpReport?.status)}}</em>

                    <div class="listDetails">
                        <span>Issue ID: <b class="detailData" innerHTML="{{ helpReport?.ticketId | highlight : payload.searchQuery}}"></b></span>
                        <span>Problem Area: <b class="detailData" [title]="helpReport?.categories[0].name">{{ (helpReport?.categories[0].name.length>16)? (helpReport?.categories[0].name | slice:0:13)+'...':(helpReport?.categories[0].name) }}</b></span>
                        <span [innerHTML]="getCurrentTicketStatusWithDate(helpReport)"></span>
                        <span *ngIf="(helpReport.status === 'resolved' || helpReport.status === 'closed') && helpReport.feedback?.createdAt !== null">Feedback: <b class="detailData">{{getFormattedDate(helpReport.feedback?.createdAt)}}</b></span>
                    </div>
                </a>
            </div>
            <!-- [ngClass]="(fi < 3 || getSelectedFilterInfo(filter)) ? 'show' : 'collapse'" -->
            <div id="collapse{{helpReport.ticketId}}" class="accordion-body collapse">
            <div class="accordion-inner">
                <div class="reportedDate issueType">
                    <p>Problem Type:<span>{{helpReport?.categories[0]?.subCategories[0]?.name}}</span></p>
                    <div class="mobView" *ngIf="helpReport.headerDateString.length && helpReport?.status != 'open'">
                        <div class="spanMore" *ngIf="helpReport?.status != 'open'"
                            [innerHTML]="helpReport.headerDateString">
                    
                        </div>
                        <a  href="javascript:void(0);" *ngIf="helpReport.displayPlusIcon" data-toggle="modal" data-target="#timelinePopup" (click)="toogleTimelinePopUp(helpReport)">+ More</a>
                        <a  href="javascript:void(0);" *ngIf="helpReport?.reopenedTickets.length>0" data-toggle="modal" data-target="#timelinePopup" (click)="toogleTimelinePopUp(helpReport)">+{{helpReport.reopenedTickets.length}} More</a>
                    </div>
                </div> 
            <!-- Comment box -->
            <div class="comment_container" *ngIf="displayComments.get(helpReport._id)?.length" id="container">
                <div class="commentBox">
                    <div class="comment-header">
                        <h5><img src="assets/images/help/add-comments.svg">Comments</h5>
                    </div>
                    <div class="comment-body" id="{{helpReport.ticketId}}">
                        <div>
                        <ng-container *ngFor="let commentDetail of displayComments.get(helpReport._id);let i = index">

                            <div class="commentsList right" *ngIf="commentDetail.userType === 'mypat'">
                                <div class="mr-12 df_list_end">
                                    <div class="comments-txt">{{commentDetail.message}}</div>
                                    <div class="attached-img" *ngIf="commentDetail.attachments.length">
                                        <ul>
                                            <li *ngFor="let attachment of commentDetail.attachments; let i = index">
                                                    <img [hidden]="imageShimmer" (load)="onImageLoad()" (click)="modalPopOpen($event, commentDetail.attachments, i)" *ngIf="attachment.type === 'image'"
                                                        [src]="attachment.url" />
                                                    <img [hidden]="imageShimmer" (load)="onImageLoad()" (click)="modalPopOpen($event, commentDetail.attachments, i)" *ngIf="attachment.type == 'video'"
                                                        src="../../../../../../assets/images/help/bgwith-play.png" />
                                                <div *ngIf="imageShimmer" class="shimmer">
                                                    <div class="box-md"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="comment-date">{{commentDetail.createdAt | date :'d MMM y, h:mm a'}}</div>
                                </div>
                                <div>
                                    <div class="profileImg">
                                        <img *ngIf="photoUrl" src="{{photoUrl}}">
                                        <span *ngIf="!photoUrl" class="{{initialsClass}}">{{userInitials}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="commentsList left" *ngIf="commentDetail?.userType !== 'mypat'">
                                <div>
                                    <div class="profileImg">
                                        <img src="/assets/images/glogo.svg">
                                    </div>
                                </div>
                                <div class="ml-12 df_list_start">
                                    <div class="comments-txt">{{commentDetail.message}}</div>
                                    <div class="attached-img" *ngIf="commentDetail.attachments.length">
                                        <ul>
                                            <li *ngFor="let attachment of commentDetail.attachments; let i = index">
                                                <img [hidden]="imageShimmer" (load)="onImageLoad()" (click)="modalPopOpen($event, commentDetail.attachments, i)" *ngIf="attachment.type === 'image'"
                                                    [src]="attachment.url" />
                                                <img [hidden]="imageShimmer" (load)="onImageLoad()" (click)="modalPopOpen($event, commentDetail.attachments, i)" *ngIf="attachment.type == 'video'"
                                                    src="../../../../../../assets/images/help/bgwith-play.png" />
                                                <div *ngIf="imageShimmer" class="shimmer">
                                                    <div class="box-md"></div>
                                                </div>    
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="comment-date">{{commentDetail.createdAt | date :'d MMM y, h:mm a'}}</div>
                                </div>   
                            </div>
                        </ng-container>
                    </div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <span class="text-right addComment" *ngIf="helpReport.status === 'open' || helpReport.status === 'reopened' || helpReport.status === 'ongoing'" (click)="openCommentModal(helpReport)">
                    Add Comment
                </span>
            </div>
            <!-- End -->
            
            <p class="descriptionTxt" *ngIf="!helpReport?.reopenedTickets.length" innerHTML="{{ helpReport?.description | highlight : payload.searchQuery}}"></p>
            <div class="fileattached" *ngIf="!helpReport?.reopenedTickets.length">
                <span *ngFor="let listImage of helpReport?.attachments; let i = index">
                    <img (click)="modalPopOpen($event, helpReport.attachments, i)" *ngIf="listImage.type == 'image'"
                        [src]="listImage?.url" />
                    <img (click)="modalPopOpen($event, helpReport.attachments, i)" *ngIf="listImage.type == 'video'"
                        src="../../../../../../assets/images/help/bgwith-play.png" />
                </span>
            </div>
            <div *ngIf="helpReport?.solution?.message && helpReport.reopenedTickets.length==0" class="solutionPanle">
                <div class="solution" [ngClass]="{remarks: helpReport?.status == 'closed'}">                           
                        <div class="titleDate">
                            <b><img class="icon" src={{getSolutionImage(helpReport?.status)}}/> {{helpReport?.status == 'closed' ? 'Remarks by Support Team' : 'Solution' }}</b>
                            <span>{{getSolutionDate(helpReport)}}</span>
                            </div>
                         <p>{{helpReport?.solution?.message}}</p>                         
                </div>
            </div>
            <div *ngIf="helpReport.showHistory || helpReport.reopenedTickets.length>0"> 
                <div class="accordion-heading"><a class="accordion-toggle innerAccordion" aria-expanded="false" data-toggle="collapse" id="dataorig{{helpReport?.ticketId}}" data-parent="#accordion{{i}}"
                    href="#dataoriginal{{helpReport?.ticketId}}" *ngIf="helpReport.reopenedTickets.length>0">
                    <h4 class="reopenNo" *ngIf="helpReport.reopenedTickets.length>0">Original Issue</h4>
                </a></div>
                <div class="accordion-body collapse" id="dataoriginal{{helpReport?.ticketId}}">
                <div class="originalIssue">
                    <div class="titleDate">
                        <div><span class="issueId">Issue ID: <b class="detailData">{{helpReport?.ticketId}}</b></span></div>
                        <span>{{getSolutionDateformat(helpReport.createdAt)}}</span>
                    </div>
                    <p innerHTML="{{ helpReport?.description | highlight : payload.searchQuery}}"></p>
                </div> 
                   
                    <div class="fileattached" *ngIf="helpReport?.attachments !==0 || helpReport.reopenedTickets.length>0">
                        <span *ngFor="let listImage of helpReport?.attachments; let i = index">
                            <img (click)="modalPopOpen($event, helpReport.attachments, i)" *ngIf="listImage.type == 'image'"
                                [src]="listImage?.url" />
                            <img (click)="modalPopOpen($event, helpReport.attachments, i)" *ngIf="listImage.type == 'video'"
                                src="../../../../../../assets/images/help/bgwith-play.png" />
                        </span>
                    </div>
                    <!-- Solution -->
                    <div *ngIf="helpReport?.solution?.message" class="solutionPanle">
                        <div class="solution" [ngClass]="{remarks: helpReport?.closedAt}">                           
                                <div class="titleDate">
                                    <b><img class="icon" src={{getOriginalSolutionImage(helpReport)}} /> {{helpReport?.closedAt ? 'Remarks by Support Team' : 'Solution' }}</b>
                                    <span>{{getSolutionDateformat(helpReport?.actionDates[2]?.date)}}</span>
                                  </div>
                                 <p>{{helpReport?.solution?.message}}</p>                         
                        </div>
                    </div>
                    <!-- End -->
                </div>  
                </div>
                <ng-container  *ngFor="let reopenData of helpReport.reopenedTickets; let j=index;">
                 <div class="" >
                    <div class="accordion-heading"><a class="accordion-toggle innerAccordion" aria-expanded="false" data-toggle="collapse" id="datacollapse{{helpReport?.ticketId}}{{j}}" data-parent="#accordion{{i}}"
                    href="#reopenData{{helpReport?.ticketId}}{{j}}">
                        <h4 class="reopenNo">{{reopenData.displayName}} </h4>
                    </a>
                </div>
                    
                    <div class="accordion-body collapse" id="reopenData{{helpReport?.ticketId}}{{j}}">
                    <div class="reopenedAgain">
                    <div class="titleDate">
                        <b><img class="icon" src="/assets/images/help/reopen-icon.svg"/>Reason for re-opening issue</b>
                        
                        <span>{{getSolutionDateformat(reopenData.createdAt)}}</span>
                    </div>
                    <div class="reasonText">{{reopenData.reopeningReason}}</div>
                    <p innerHTML="{{ reopenData?.description | highlight : payload.searchQuery}}"></p>  
                </div>
                   <div class="fileattached" *ngIf="reopenData?.attachments !==0">
                        <span *ngFor="let listImage of reopenData?.attachments; let i = index">
                            <img (click)="modalPopOpen($event, reopenData.attachments, i)" *ngIf="listImage.type == 'image'"
                                [src]="listImage?.url" />
                            <img (click)="modalPopOpen($event, reopenData.attachments, i)" *ngIf="listImage.type == 'video'"
                                src="../../../../../../assets/images/help/bgwith-play.png" />
                        </span>
                    </div>
                    <div class="solution" *ngIf="reopenData?.solution?.message" [ngClass]="{remarks: reopenData?.status == 'closed'}">                           
                        <div class="titleDate">
                            <b><img class="icon" src={{getSolutionImage(reopenData?.status)}}/> {{reopenData?.status == 'closed' ? 'Remarks by Support Team' : 'Solution' }}</b>
                            <span>{{getSolutionDateformat(reopenData?.actionDates[2]?.date)}}</span>
                         </div>
                         <p>{{reopenData?.solution?.message}}</p>                         
                   </div> 
                </div>
                 </div>    
                </ng-container> 
                <!-- Edit feedback -->
                <div class="fedbackReopen"> 
                    <div *ngIf="helpReport.status==='closed'|| helpReport.status==='resolved'">
                        <a href="javascript:void(0)" (click)="handleReopenPopUp(helpReport._id)" class="btn-text"
                            data-toggle="modal" data-target="#reopenIssue">Still facing this issue? </a>
    
                    </div>                          
                    <div *ngIf="helpReport?.feedback?.rating === null && (helpReport.status==='closed'|| helpReport.status==='resolved')" class="LeaveFeedback">
                        <a href="javascript:void(0)" (click)="onFeedBackOpen($event, helpReport)" class="btn-text"><img
                                src="/assets/images/help/leaveFeedback.svg" /> Leave Feedback</a>
                    </div>
                    
               </div>
                <div *ngIf="helpReport?.feedback?.rating !== null" class="editFeedback">
                    <div class="header">
                        <div class="leftSec">
                            <b><img class="icon" src="../../../../assets/images/help/feedback-icon.svg"/>My Feedback</b>
                            <a *ngIf="helpReport?.feedback?.rating && (helpReport.status==='closed'|| helpReport.status==='resolved')" href="javascript:void(0)" (click)="onFeedBackOpen($event, helpReport)" class="btn-edit">Edit</a>
                        </div>
                        <em>{{getFormattedDate(helpReport?.feedback?.createdAt)}}</em>
                    </div>
                    <div *ngIf="helpReport?.feedback?.rating !== null" class="feedRating">
                        <div class="star" *ngFor="let star of [1,2,3,4,5]; let i= index"
                            [ngClass]="{'active': (star <= helpReport?.feedback?.rating)}">
                            <svg width="20" height="20" viewBox="0 0 28 26" fill="none"
                                xmlns="http://www.w3.org/2000/svg" class="active">
                                <path
                                    d="M14 0L17.1432 9.67376H27.3148L19.0858 15.6525L22.229 25.3262L14 19.3475L5.77101 25.3262L8.9142 15.6525L0.685208 9.67376H10.8568L14 0Z"
                                    fill="#C4C4C4" />
                            </svg>
                        </div>
                    </div>
                    <p>{{helpReport?.feedback?.message}}</p>
                    
                </div>
                
                
               <!-- <div class="viewHistory" *ngIf="helpReport.reopenedTickets.length>0">
                <a href="javascript:void(0);" (click)="toggleHistory(i)"><span class="openHistory" *ngIf="!helpReport.showHistory"> View History <img src="/assets/images/upArrowIcon.svg" /></span><span *ngIf="helpReport.showHistory"> View History <img src="/assets/images/upArrowIcon.svg" /></span></a>  
               </div>   -->
               </div>
            </div>
        </div>
    </div>
   </ng-container> 
    </div>
    <!-- <app-selected-card-loader *ngIf="showLoader" [cardName]="'swotBoard'"></app-selected-card-loader> -->
    <div class="swotboardLoader-container" *ngIf="showLoader">
        <ul>
          <li>
            <div class="graph-container">
              <div class="graph-text"></div>
              <div class="graph-text"></div>
              <div class="graph-text"></div>
            </div>
            <div class="graph-container">
                <div class="graph-text"></div>
                <div class="graph-text"></div>
                <div class="graph-text"></div>
              </div>
              <div class="graph-container">
                <div class="graph-text"></div>
                <div class="graph-text"></div>
                <div class="graph-text"></div>
              </div>
          </li>
        </ul>
      </div>
      <div *ngIf="!showLoader && !payload.searchQuery && !(helpDataFiltered && helpDataFiltered?.length) && !(filterData.issueTypes?.length || filterData.dateFrom || filterData.dateTo || (filterData.feedbackProvided !== undefined))" class="no-issue noneDiv noReport helpNodata">
        <!-- <div [hidden]="!filterDataEmpty && !showLoader" class="no-issue noneDiv"> -->
        <img src="/assets/images/help/no-issue-found.svg" alt="">
        <h4>No Issues Found</h4>
        <!-- <p *ngIf="helpStatus == 'closed'">None of the issues reported by you are <br />pending to be picked!</p> -->
        <!-- pending -->
        <p *ngIf="payload?.status[0] == 'All'">No issues have been reported by you!</p>
        <p *ngIf="payload?.status[0] == 'open' || payload?.status[0] == 'reopened'">None of the issues reported by you are <br />pending to be picked!</p>
        <!-- Ongoing -->
        <p *ngIf="payload?.status[0] == 'ongoing'">None of the issues reported by you are <br />currently being worked upon!</p>
        <!-- Resolved -->
        <p *ngIf="payload?.status[0] == 'resolved'">No issue reported by you has been <br />resolved so far!</p>
        <!-- closed -->
        <p *ngIf="payload?.status[0] == 'closed'">No issue reported by you has been closed <br />so far!</p>
    </div>
    <div *ngIf="(!helpDataFiltered || !helpDataFiltered?.length) && !showLoader && (filterData.issueTypes.length || filterData.dateFrom || filterData.dateTo || (filterData.feedbackProvided !== undefined) || payload.searchQuery) " class="no-issue noneDiv differentKey {{payload.searchQuery ? 'searchResEmpty' : ''}}">
        <img src="/assets/images/help/no-issue-found.svg" alt="">
        <h4 *ngIf="payload.searchQuery">No Results Found</h4>
        <h4 *ngIf="!payload.searchQuery">No Issues Found</h4>
       <p>{{getText()}}</p>
    </div>
</div>
<app-help-feedback *ngIf="!showReopenPopUp && !showTimelinePopUp" [currentHelpObj]="currentHelpObj" [type]="'help'"></app-help-feedback>
<app-help-light-box #helpLightBoxComponent></app-help-light-box>
<app-help-reopen-popup *ngIf="showReopenPopUp" (discardComplete)="handleReopenPopUp($event)" [issueId]="currentIssueId"></app-help-reopen-popup> 
<app-help-issue-timeline *ngIf="showTimelinePopUp" [timeline]="currentTimelineData"  (onClose)="toogleTimelinePopUp()" ></app-help-issue-timeline>
<app-provide-comments *ngIf="showCommentModal" [issueId]="commentId" (onClosePopUp)="onClosePopUp()" (onSuccessfulComment)="onSuccessfulComment()"></app-provide-comments>


<!-- status info modal -->
<div class="modal fade small-modal" tabindex="-1" id="helpSupportMobileStatusInfoPopup">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
      <div class="modal-content">
        <div class="modal-body">
          <img *ngIf="displayStatus" [src]="'/assets/images/help/'+infoPopUpData[displayStatus]?.iconUrl"/>
          <h3>{{infoPopUpData[displayStatus]?.heading}}</h3>
          <p>{{infoPopUpData[displayStatus]?.text}}</p>
        </div>
        <div class="modal-footer single-btn">
          <button type="button" class="btn btn-primary" data-dismiss="modal">Got It</button>
        </div>
      </div>
    </div>
  </div>
import { Injectable } from '@angular/core';
import {
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Router,
  ActivatedRoute
} from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from './stores/app.reducers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HelpGuideInfoService } from './help-guide-info.service';

@Injectable()
export class AuthGaurd implements CanActivate {
  public userDetail;
  constructor(private router: Router, private store: Store<fromApp.AppState>, private route: ActivatedRoute, private helpGuideService: HelpGuideInfoService) { }
  canActivate(state: ActivatedRouteSnapshot, route: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select('auth').pipe(
      map((auth) => {
        const isLoginPage = state.data.isLoginPage;
        const token = window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken');
        localStorage.setItem('lastRequested', route.url);
        if (auth.authenticated && token) {
          if(isLoginPage){
            this.router.navigateByUrl('home');
            return false;
          }
          if (this.helpGuideService.pdfId && route.url?.split('?')?.[0] !== '/learn/pdf/66338b417d066b471dda531d') {
            return false;
          }
          return true;
        } else {
          let learnDarkMode = localStorage.getItem('isDarkmodeforid');
          const lastRequested = localStorage.getItem('lastRequested');
          localStorage.clear();
          localStorage.setItem('isDarkmodeforid', learnDarkMode);
          sessionStorage.clear();
          localStorage.setItem('lastRequested', lastRequested);
          if(isLoginPage){
            return true;
          }
          this.router.navigate(['/']);
          return false;
        }
      }
      )
    );
  }
}

import { Injectable } from '@angular/core';

import { HttpService } from '../../shared/services/http.service';
import { AlertService } from '../../shared/services/alert.service';
import { environment } from 'src/environments/environment';
import { TaskDetails, TaskListItem } from '../cards-detail/right-selected-card/my-plan/my-plan-types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuccessIndexService {
  public successIndx = new BehaviorSubject<any>({});
  constructor(private http: HttpService,private alert:AlertService) { }

  getSuccessIndex(courseId): any {
      this.http.get('v4/auth/success-index' +
        (courseId ? '?courseId=' + courseId : ''), true, null, true,false,environment.serverURLv4
      ).subscribe((res) => {
        if (res &&res.code==200){
          this.successIndx.next(res.data.successIndex);
        } else {
          this.alert.showErrorMessage(res.message);
        }
    });
  }

getSuccessIndexCard(courseId): any {
  return new Promise((resolve, reject) => {
    this.http.get('v4/auth/success-index' +
      (courseId ? '?courseId=' + courseId : ''), true, null, true,false,environment.serverURLv4
    ).subscribe((res) => {
      if (res) {
        resolve(res.data);
      } else {
        this.alert.showErrorMessage(res.message);
      }
    });
  });
}

 

  }



<div class="dropdown profile-menu">
  <button
    class="btn btn-profile dropdown-toggle"
    type="button"
    id="dropdownMenuButton-button"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-label="profile menu"
    aria-expanded="false">
    <span class="profile-img" *ngIf="gotImage">
      <img src="{{photoUrl}}" />
    </span>
    <span *ngIf="!gotImage" class="alphabet-username {{userData.initialsClass}}"> {{userData.initials}}</span>
    <span class="d-none d-xl-block text-truncate username-text" title="{{userName}}" *ngIf="userName">{{userName}}</span>
    <span class="arrow-down d-none d-xl-block">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path d="M8.48193 11.3172L3.48193 6.31719L4.18193 5.61719L8.48193 9.91719L12.7819 5.61719L13.4819 6.31719L8.48193 11.3172Z" fill="#1D7DEA"/>
      </svg>
    </span>
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <div class="profile-user-info-block" onclick="event.stopPropagation()">
      <app-profile-user-info [userData]="userData"></app-profile-user-info>
    </div>
    <div class="profile-menu-container">
      <!-- <a  class="dropdown-item" href="javascript:void(0);" (click)="changePassword()"> Rate Us</a> -->
      <a class="dropdown-item" href="javascript:void(0)" (click)="goToProfile()"><img class="profile-icon" src="assets/images/header/view-profile-icon.svg" alt="View Profile"> View Profile</a>
      <!-- <a class="dropdown-item" href="#"
        >My Badges <span class="bages">NEW</span></a
      > -->
      <a class="dropdown-item d-none" href="javascript:void(0)" (click)="goToRefer()">Refer and Earn</a>
      <!-- <a class="dropdown-item" href="javascript:void(0)">Explore Course</a> -->
      <!-- Manage Devices -->
      <a class="dropdown-item" href="javascript:void(0)" (click)="goToManageDevice()"><img class="profile-icon" src="assets/images/header/manage-device-icon.svg" alt="Manage Devices"> Manage Devices</a>
      <a class="dropdown-item" href="javascript:void(0)" (click)="changePassword()"><img class="profile-icon" src="assets/images/header/change-password-icon.svg" alt="Change Password"> Change Password</a>
      <!-- <a class="dropdown-item" href="#">Start Guide</a> -->
      <a class="dropdown-item" href="javascript:void(0)" routerLinkActive="active"
      [routerLink]="['/terms-of-use']"><img class="profile-icon" src="assets/images/header/term-of-use-icon.svg" alt="Terms of Use"> Terms of Use</a>
      <a class="dropdown-item" [ngClass]="{'disble-dropdown-item': userData.isTeacher}"  href="javascript:void(0)" (click)="logoutConfirm()"><img class="profile-icon" src="assets/images/header/logout-icon.svg" alt="Logout"> Logout</a>
    </div>
  </div>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { HelpService } from 'src/app/help-and-support/services/help.service';
import * as fromApp from '../../../stores/app.reducers';
import { HttpService } from '../../../shared/services/http.service';
import { AlertService } from '../../services/alert.service';
import { HomeService } from 'src/app/home-page/services/home.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import _ from 'lodash';

declare var $: any;
@Component({
  selector: 'app-report-an-issue',
  templateUrl: './report-an-issue.component.html',
  styleUrls: ['./report-an-issue.component.scss'],
})
export class ReportAnIssueComponent implements OnInit {
  @Output() discardComplete = new EventEmitter<any>();
  reqObj = {
    type: 'issue',
    category: 'Select Problem Area',
    description: '',
    attachments: [],
  };

  errorAndSuccess = false;
  isLoading = false;
  selectedProblemTypeId: any;
  public dataToPassForUserCards: any;
  issueType = [];
  subCatergories: any = [];
  selectedSubCategory: string = 'Problem Type';
  showSubCatergoryError: boolean = false;
  selectSubCategoryError : boolean = false;
  descriptionError : boolean = false;
  submitBtnEnable;
  fileAdded: boolean = false;
  public disabledCrossIcon: boolean = false;
  public disabledCloseIcon: boolean = false;
  public prevProblemType ='';
  @Output() onSubmitSuccess = new EventEmitter<any>();
  @Output() reportAnIssueUpdate = new EventEmitter<any>();
  constructor(
    private helpServices: HelpService,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private httpService: HttpService,
    private alert: AlertService,
    private router: Router,
    private location: Location,
    private homePageService: HomeService
  ) {}

  ngOnInit(): void {
    this.disabledCloseIcon = false;
    this.resetErrors();
    this.helpServices.helpIssueType().then((res) => {
      this.issueType = _.get(res, 'issue.categories', []);
    });
  }

  onChangeProblem(type: string, item: any) {
    if (type === 'category') {
      this.errorTextProblem = false;
      if(this.reqObj.category != item.name){
        this.reqObj.description = '';
        this.descriptionLength = 0;
      }
      this.reqObj.category = item.name;
      if (this.reqObj.category === 'Select Problem Area') {
        this.errorTextProblem = true;
        this.subCatergories = [];
      } else {
        this.subCatergories = item.subCategories;
      }
      if(this.prevProblemType !== item?.name && type === 'category'){
        this.prevProblemType=item?.name;
        this.selectedSubCategory = 'Problem Type';
      }
    } else {
      this.showSubCatergoryError = false;
      this.selectSubCategoryError = false;
      this.descriptionError = false;
      this.selectedSubCategory = item;
      if (this.selectedSubCategory == 'Problem Type') {
        this.showSubCatergoryError = true;
      }
    }
  }

  mydata = [];
  fileSizeError = false;
  fileTypeAccept = false;

  onimageClick(){
   $('#helpfiles')[0].value = null; 
  }
  onSelectFile(event) {
    this.fileSizeError = false;
    this.fileTypeAccept = false;
    const files = event.target.files;
    var ext = $('#helpfiles').val().split('.').pop().toLowerCase();
    if (files.length == 0 || ext == ''){
      return;
    }
    if (
      ext !== 'jpg' &&
      ext !== 'jpeg' &&
      ext !== 'png' &&
      ext !== 'mp4' &&
      ext !== 'mov' &&
      ext !== 'hevc'
    ) {
      this.fileTypeAccept = true;
      this.fileAdded = false;
      return false;
    }

    if (files) {
      for (const file of files) {
        const reader = new FileReader();
        let fileType = (Math.floor(Math.log(file.size) / Math.log(1024)));
        let actualSize = Math.round(file.size / Math.pow(1024, fileType));
        const fileName = file.name.split(' ').join('');
        reader.onload = (e: any) => {
          if (file.type.indexOf('image') > -1) {
            if (fileType === 2 && actualSize >= 100) {
              this.fileSizeError = true;
              this.fileAdded = false;
              return;
            }
            const index = this.mydata.findIndex((obj) => obj.file.name == file.name)
            if (index == -1) {
              this.fileAdded = false;
              this.mydata.push({
                url: e.target.result,
                type: 'image',
                name: fileName,
                file: file,
              });
            } else {
              this.fileAdded = true;
              this.fileSizeError = false;
            }
            // this.getPreSignedUrl();
          } else if (file.type.indexOf('video') > -1) {
            if (fileType === 2 && actualSize >= 100) {
              this.fileSizeError = true;
              this.fileAdded = false;
              return;
            }
            const index = this.mydata.findIndex((obj) => obj.file.name == file.name)
            if (index == -1) {
              this.fileAdded = false;
              this.mydata.push({
                url: e.target.result,
                type: 'video',
                name: fileName,
                file: file,
              });
            } else {
              this.fileAdded = true;
              this.fileSizeError = false;
            }
          }
        };
        reader.readAsDataURL(file);
      }
    }
  }

  closeThumb(index) {
    this.fileAdded = false;
    this.mydata.splice(index, 1);
  }

  // get permission from s3 to upload image
  getPreSignedUrl(obj): void {
    const apiUrl = 'v4/auth/upload-signed-url?location=ideasAndSuggestions';
    this.httpService.get(apiUrl, true, { key: obj.name }, false).subscribe(
      (res) => {
        if (res && res.code === 200) {
          if (res.data.url && res.data.fields) {
            obj.photoUrlCopy = res.data.url + '/' + res.data.fields.key;
            this.uploadYourFileOnS3(res.data, obj);
          }
        }
      },
      (error) => {
        this.currentUploadIndex++;
        obj.status = 'Failed';
        this.uploadFiles();
      }
    );
  }

  // upload image after permision
  uploadYourFileOnS3(data, obj): void {
    const formData: FormData = new FormData();
    formData.append('key', data.fields.key);
    formData.append('bucket', data.fields.bucket);
    formData.append('acl', 'public-read');
    formData.append('X-Amz-Algorithm', data.fields['X-Amz-Algorithm']);
    formData.append('X-Amz-Credential', data.fields['X-Amz-Credential']);
    formData.append('X-Amz-Date', data.fields['X-Amz-Date']);
    formData.append('Policy', data.fields['Policy']);
    formData.append('X-Amz-Signature', data.fields['X-Amz-Signature']);
    formData.append('file', obj.file, obj.name);

    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    };
    this.http.post(data.url, formData, options).subscribe(
      (res) => {
        this.currentUploadIndex++;
        obj.status = 'Uploaded';
        this.uploadFiles();
      },
      () => {
        this.currentUploadIndex++;
        obj.status = 'Failed';
        this.uploadFiles();
      }
    );
  }

  uploadFiles() {
    this.errorAndSuccess = false;
    this.isLoading = true;
    if (this.currentUploadIndex < this.mydata.length) {
      this.getPreSignedUrl(this.mydata[this.currentUploadIndex]);
    } else {
      this.reqObj.attachments = [];
      this.mydata.forEach((item) => {
        this.reqObj.attachments.push({
          type: item.type,
          url: item.photoUrlCopy,
        });
      });
      const body: any = { ...this.reqObj };
      body.categories = [{
        name: this.reqObj.category,
        subCategories: [{ 'name': this.selectedSubCategory }]
      }];
      const navigatorType = navigator.userAgent;
      const browserArr = navigatorType.match(/(opera|chrome|safari|firefox|msie|trident|iPhone|iPad|iPod|Android(?=\/))\/?\s*(\d+)/i) || [];
      const browserName = {platform: null, brand: null, version: null};
      browserName.brand = browserArr[1];
      browserName.version = browserArr[2];
      if (navigatorType.indexOf("Mac") !== -1) {
        browserName.platform = 'MacOS';
      } else if (navigatorType.indexOf("Win") !== -1) {
        browserName.platform = "Windows OS";
      } else if (navigatorType.indexOf("Linux") !== -1 && !navigatorType.indexOf("Android")) {
        browserName.platform = 'Linux OS';
      } else if (navigatorType.indexOf("Android") !== -1) {
        browserName.platform = 'Android';
      }
      body.platformInfo = {
        device: 'web',
        operatingSystem: browserName.platform,
        browser: browserName.brand,
        browserVersion: browserName.version,
        type: "web"
      }
      this.helpServices.postReportAnIssue(body).then(
        (res) => {
          if (res.code === 200) {
            // success
            localStorage.setItem('reporteditem','help');
            this.reqObj.description = '';
            this.reqObj.category = 'Select Problem Area';
            this.selectedSubCategory = 'Problem Type';
            this.subCatergories = [];
            this.reqObj.attachments = [];
            this.errorTextProblem = false;
            this.selectSubCategoryError = false;
            this.descriptionError = false;
            this.mydata = [];
            $('#reportIssue').modal('hide');
            this.disabledCloseIcon = false;
            this.resetErrors();
            $('.modal-backdrop').removeClass('show');
            $('.reportIssue').removeClass('discardOpen');
            this.discardChanges = false;
            this.isLoading = false;
            //this.errorAndSuccess = true;
            this.alert.showSuccessMessage(res.message);
            this.alert.notificationCountUpdate.next(1);
            this.alert.helpNotifiStatus.next('');
            this.alert.helpStatus.next('');

            this.onSubmitSuccess.emit(true);
            this.reportAnIssueUpdate.emit(true);
            // hide loader and close popup then show sucsses res.message
            this.homePageService.fetchHomeCardsData().then((res) => {
              if (res) {
                if (res.userCards && res.userCards.length) {
                  this.dataToPassForUserCards = JSON.parse(
                    JSON.stringify(res.userCards)
                  );
                  this.dataToPassForUserCards[2].data.forEach((element) => {
                    if (element.type === 'help' && window.location.href.split('/')[3] !== 'help') {
                      this.helpServices.UpdatedDataHelp.next(element);
                    }
                  });
                }
              }
            });
          } else {
            // error: res.message
            this.alert.showErrorMessage(res.message);
            // hide loader
          }
        },
        (error) => {
          // error message: Something went wrong, Please try again
          this.alert.showErrorMessage('Something went wrong');
          // hide loader
        }
      );
    }
  }

  currentUploadIndex = 0;
  //errorText = false;
  errorTextProblem = false;
  reportAnIssue() {
    //this.errorText = false;
    this.isLoading = false;
    // this.disabledCrossIcon = true;
    // this.disabledCloseIcon = true;
    this.errorTextProblem = false;
    this.selectSubCategoryError = false;
    this.descriptionError = false;
    if (this.reqObj.category === 'Select Problem Area') {
      this.errorTextProblem = true;
      return;
    }
    if (this.subCatergories === 'Problem Type') {
      this.showSubCatergoryError = true;
      return;
    }
    if (this.reqObj.category !== 'Select Problem Area' && this.selectedSubCategory === 'Problem Type') {
      this.selectSubCategoryError = true;
      return;
    }
    if (this.reqObj.category !== 'Select Problem Area' && this.selectedSubCategory !== 'Problem Type' && (this.descriptionLength < 40)) {
      this.descriptionError = true;
      return;
    }
    if (this.reqObj.category !== 'Select Problem Area' && this.selectedSubCategory !== 'Problem Type' && (this.descriptionLength < 40)) {
      this.disabledCloseIcon = false;
      this.disabledCrossIcon = false;
      return;
    }
    else{
      this.disabledCrossIcon = true;
      this.disabledCloseIcon = true;
    }
    if (
      !this.reqObj.description ||
      this.reqObj.description.replace(/ /g, '').length < 40
    ) {
      //this.errorText = true;
      return;
    }
    this.reqObj.description = this.reqObj.description.trim();

    // show loader
    this.currentUploadIndex = 0;
    this.uploadFiles();
  }

  public descriptionLength = 0;

  onKeyUp() {
    const count = this.reqObj.description.replace(/\r?\n|\r/g, "");
    this.descriptionLength = count.replace(/ /g, '').length;
    this.descriptionError = false;
    if(!this.reqObj.description ||
    this.reqObj.description.replace(/ /g, '').length < 40){
      this.descriptionError = false;
    }
    else {
      this.descriptionError = false;
    }
    // const txt = document.getElementById('#textarea');
    // let strText = txt.value;
    // let strArr = new Array();
    // strArr = strText.split(" ");
    // for(let i = 0; i < strArr.length; i++)
    // {
    //     if(strArr[i] == "")
    //     {
    //       this.errorText = true;
    //         break;
    //     } else {
    //       this.errorText = false;
    //     }
    // }

  //   let CharArray = txt.value.split('');
  //  for(var i=0;i<CharArray.length;i++)
  //  {
  //    if (CharArray[i] == " ")
  //    {            
  //       if (CharArray[i+1] == " ")
  //       {
  //           this.errorText = true;
  //           //txt.value = txt.value.replace (" ");//this only erase the first space of the string, please if you can fix this let me know
  //           return false;    
  //       } else {
  //         this.errorText = false;
  //       }       
  //    }    
  //  }
  //  return true;
      //this.errorText = true;
    // } else {
    //   //this.errorText = false;
    // }
  }

  discardChanges = false;
  cancelClick() {
    this.discardChanges = false;
    $('.reportIssue').removeClass('discardOpen');
  }

  closePopup() {
    if (this.descriptionLength || this.mydata.length) {
      $('.reportIssue').addClass('discardOpen');
      this.discardChanges = true;
    } else {
      this.reqObj.description = '';
      this.reqObj.category = 'Select Problem Area';
      this.selectedSubCategory = 'Problem Type';
      this.errorTextProblem = false;
      this.selectSubCategoryError = false;
      this.descriptionError = false;
      this.subCatergories = [];
      this.mydata = [];
      this.reqObj.description = '';
      this.descriptionLength = 0;
      this.fileSizeError = false;
      this.fileTypeAccept = false;
      this.fileAdded = false;
      $('#reportIssue').modal('hide');
      $('.modal-backdrop').removeClass('show');
      this.resetErrors();
    }
  }

  onDiscard() {
    this.reqObj.description = '';
    this.reqObj.category = 'Select Problem Area';
    this.selectedSubCategory = 'Problem Type';
    this.mydata = [];
    this.subCatergories = [];
    this.discardComplete.emit();
    $('#reportIssue').modal('hide');
    $('.modal-backdrop').removeClass('show');
    $('.reportIssue').removeClass('discardOpen');
    $('.dsaBTN .dropdown').addClass('disabled');
    this.discardChanges = false;
    this.fileAdded = false;
    this.fileSizeError = false;
    this.fileTypeAccept = false;
    this.descriptionLength = 0;
    this.resetErrors();
  }

  checkIfDisabled() {
    if (
      this.selectedSubCategory !== 'Problem Type' && this.reqObj.category !== 'Select Problem Area' &&
      this.descriptionLength >= 40
    ) {
      return true;
    } else {
      return false;
    }
  }

  resetErrors() {
    //this.errorText = false;
    this.errorTextProblem = false;
    this.showSubCatergoryError = false;
    this.selectSubCategoryError = false;
    this.descriptionError = false;
  }
  onFocuscategory() {
    this.errorTextProblem = false;
  }
  onFocusSubCategory(){
    this.selectSubCategoryError = false;
  }
  onFocusDescription(){
    this.descriptionError = false;
  }
}

import { Action } from '@ngrx/store';

export const PROCESS_AFTER_SIGNUP = 'PROCESS_AFTER_SIGNUP';
export const PROCESS_AFTER_SIGNIN = 'PROCESS_AFTER_SIGNIN';
export const SIGNUP = 'SIGNUP';
export const SIGNIN = 'SIGNIN';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_TOKEN = 'SET_TOKEN';
export const VERIFY_OTP_EMAIL = 'VERIFY_OTP_EMAIL';
export const VERIFY_OTP_MOBILE = 'VERIFY_OTP_MOBILE';
export const VERIFY_OTP_MOBILE_SUCCESS = 'VERIFY_OTP_MOBILE_SUCCESS';
export const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
export const FAIL = 'FAIL';
export const SET_AUTHTENTICATION = 'SET_AUTHTENTICATION';
export const FIITJEE_MYPAT_LOGIN = 'FIITJEE_MYPAT_LOGIN';
export const OTP_LOGIN_FIITJEE = 'OTP_LOGIN_FIITJEE';
export const WAC_LOGIN_FIITJEE = 'OTP_LOGIN_FIITJEE';
export const RESET_PASSWORD_FIITJEE = 'RESET_PASSWORD_FIITJEE';
export const SET_DEVICE_USED = 'SET_DEVICE_USED';
export const SOCIAL_SIGNUP = 'SOCIAL_SIGNUP';
export const SOCIAL_SIGNIN = 'SOCIAL_SIGNIN';
export const ALREADY_LOGGED_IN = 'ALREADY_LOGGED_IN';
export const UNREGISTER_DEVICE = 'UNREGISTER_DEVICE';

export class Signup implements Action {
    readonly type = SIGNUP;
    constructor(public payload: {
        mobile: string;
        countryCode: string;
        referralCode: string;
        classId?: any;
        str?: any;
        className?: any;
    }) { }
}

export class Signin implements Action {
    readonly type = SIGNIN;
    constructor(public payload: {
        email: string,
        mobileNumber: string,
        countryCode: string,
        password: string
    }) { }
}

export class ProcessAfterSignup implements Action {
    readonly type = PROCESS_AFTER_SIGNUP;
}

export class ProcessAfterSignin implements Action {
    readonly type = PROCESS_AFTER_SIGNIN;
}

export class SetAuthentication implements Action {
    readonly type = SET_AUTHTENTICATION;
}

export class Logout implements Action {
    readonly type = LOGOUT;
}

export class LogoutSuccess implements Action {
    readonly type = LOGOUT_SUCCESS;
}

export class Fail implements Action {
    readonly type = FAIL;
}
export class SetToken implements Action {
    readonly type = SET_TOKEN;
    constructor(public payload: string) { }
}

export class VerifyOtpMobile implements Action {
    readonly type = VERIFY_OTP_MOBILE;
    constructor(public payload: {
        mobile: string,
        otp: string,
        countryCode: string
    }) { }
}
export class VerifyOtpEmail implements Action {
    readonly type = VERIFY_OTP_EMAIL;
    constructor(public payload: {
        emailId: string,
        otp: string
    }) { }
}

export class GetProfileData implements Action {
    readonly type = GET_PROFILE_DATA;
}

export class FiiJeeMyPatLogin implements Action {
    readonly type = FIITJEE_MYPAT_LOGIN;
    constructor(public payload: {
        userId: string,
        type: string,
        password: string,
        enrollmentno: string,
        isPassword: boolean,
        isLogin: boolean,
        logoutActiveDevice?: boolean,
    }) { }
}

export class OtpLoginFiitjee implements Action {
    readonly type = OTP_LOGIN_FIITJEE;
    constructor(public payload: {
        userId: string,
        otp: string,
        type: string,
        enrollmentno: string,
        isLogin: boolean,
        isPassword: boolean,
        logoutActiveDevice?: boolean,
    }) { }
}
export class WACLoginFiitjee implements Action {
    readonly type = WAC_LOGIN_FIITJEE;
    constructor(public payload: {
        wac: string,
        userId: string,
        enrollmentno: string,
        isPassword: boolean,
        isLogin: boolean,
        logoutActiveDevice?: boolean,
    }) { }
}

export class ResetPasswordFiitJee implements Action {
    readonly type = RESET_PASSWORD_FIITJEE;
    constructor(public payload: {
        password: string,
        confPassword: string,
        userId: string,
        enrollmentno: string
    }) { }
}

type DeviceUsed = 'mobile' | 'web';

export class SetDeviceUsed implements Action {
    readonly type = SET_DEVICE_USED;
    constructor(public payload: DeviceUsed) { }
}
export class SocialSignup implements Action {
    readonly type = SOCIAL_SIGNUP;
    constructor(public payload: {}) { }
}
export class SocialSignin implements Action {
    readonly type = SOCIAL_SIGNIN;
    constructor(public payload: {}) { }
}

export class AlreadyLoggedIn implements Action {
    readonly type = ALREADY_LOGGED_IN;
    constructor(public payload: {}) { }
}

export class UnregisterDevice implements Action {
    readonly type = UNREGISTER_DEVICE;
    constructor(public payload: {}) { }
}
export type AuthActionList = Signup | Signin | Logout | SetToken | Fail | SetAuthentication | FiiJeeMyPatLogin |
    OtpLoginFiitjee | WACLoginFiitjee | ResetPasswordFiitJee | SetDeviceUsed | SocialSignup | SocialSignin | LogoutSuccess | AlreadyLoggedIn | UnregisterDevice;

import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { HttpService } from '../../services/http.service';
import { AlertService } from '../../services/alert.service';
import { UtilityService } from '../../services/utility.service';

declare var $: any;
@Component({
  selector: 'app-transfer-to-paytm',
  templateUrl: './transfer-to-paytm.component.html',
  styleUrls: ['./transfer-to-paytm.component.scss']
})
export class TransferToPaytmComponent implements OnInit {

  @Input() userId: string;
  referralData: any;

  mobileNumber: number;
  user = {
    mobileNumber: 0,
    countryCode: ''
  };
  selectedCountryData: any = {};

  buttonText = 'Send OTP';
  currentState = 'initial';

  showTimer = true;
  timerSec: Observable<number>;
  timeValue = 30;
  interval: any;
  otpApiError = '';

  otpValue = 0;
  otpDigit1 = '';
  otpDigit2 = '';
  otpDigit3 = '';
  otpDigit4 = '';

  redeemablePoint: any;

  constructor(
    private httpService: HttpService,
    private alertService: AlertService,
    private utilityService: UtilityService
  ) {
    this.getCountryList();
  }

  ngOnInit(): void {
    this.loadScriptForOTP();
    this.getReferralDetails();
  }

  ngOnChanges(): void {
    this.startTimer();
  }

  getReferralDetails(): void {
    const apiName = `getReferralDetail?id=${this.userId}`;
    this.httpService.get(apiName, true, null, false, false, environment.serverURLv3).subscribe((res) => {
      if (res && res.code === 200) {
        this.referralData = res.data;
        this.redeemablePoint = this.referralData.balanceAmount > 1500 ? 1500 : this.referralData.balanceAmount;
      } else {
        this.alertService.showErrorMessage(res.message);
      }
    });
  }

  getCountryList(): void {
    const countryList = this.utilityService.getAllCountryList();
    this.selectedCountryData = countryList[0];
  }

  performAction(): void {
    switch (this.currentState) {
      case 'initial':
        this.sendOTP();
        break;
      case 'otpSent':
        this.verifyOTP();
        break;
      case 'otpVerified':
        this.confirmTransfer();
        break;
    };
  }

  checkIfValid(): boolean {
    let isValid = false;
    switch (this.currentState) {
      case 'initial':
        if (this.mobileNumber) {

          if (this.mobileNumber.toString().length === 10) {
            isValid = true;
          } else {
            isValid = false;
          }
        } else {
          isValid = false;
        }
        break;
      case 'otpSent':
        if (this.otpValue.toString().length === 4 && this.otpDigit1 && this.otpDigit2 && this.otpDigit3 && this.otpDigit4) {
          isValid = true;
        } else {
          isValid = false;
        }
        break;
      default:
        isValid = true;
    };
    return isValid;
  }

  sendOTP(): void {
    if (this.mobileNumber && this.mobileNumber.toString().match(this.selectedCountryData.regex)) {
      this.user = {
        mobileNumber: this.mobileNumber,
        countryCode: this.selectedCountryData.dialCode,
      };
      this.httpService.post('sendRedeemOtp', true, this.user, false, false, false, environment.serverURLv3).subscribe((res) => {
        if (res && res.code === 200) {
          this.startTimer();
          this.currentState = 'otpSent';
          this.buttonText = 'Verify';
        }
      });
    }
  }

  loadScriptForOTP(): void {
    // tslint:disable-next-line: typedef
    $('.inputs').keyup(function (e) {
      if (e.which === 8 || e.which === 46) {
        $(this).prev('.inputs').focus();
      } else {
        if (this.value.length === this.maxLength) {
          $(this).next('.inputs').focus();
        }
      }
    });
  }

  resendOTP(): void {
    this.sendOTP();
  }

  startTimer(): void {
    this.otpApiError = '';
    this.showTimer = true;
    this.timeValue = 30;
    this.interval = setInterval(() => {
      if (this.timeValue > 0) {
        this.timeValue--;
      } else {
        this.pauseTimer();
      }
    }, 1000);
  }

  pauseTimer(): void {
    this.showTimer = false;
    clearInterval(this.interval);
  }

  resendOtp(): void {
    this.sendOTP();
  }

  editMobile(): void {
    this.otpDigit1 = '';
    this.otpDigit2 = '';
    this.otpDigit3 = '';
    this.otpDigit4 = '';
    this.currentState = 'initial';
    this.buttonText = 'Send OTP';
  }

  onEnterOTP(): void {
    this.otpValue = Number(this.otpDigit1 + this.otpDigit2 + this.otpDigit3 + this.otpDigit4);
  }

  verifyOTP(): void {
    const payload = {
      otpNumber: this.otpValue,
      points: this.redeemablePoint
    };
    this.httpService.post('verifyRedeemOtp', true, payload, false, false, false, environment.serverURLv3).subscribe((res) => {
      if (res && res.code === 200) {
        this.currentState = 'otpVerified';
        this.buttonText = 'Confirm';
        this.redeemablePoint = res.data.redeemablepoint || res.data.data.redeemablepoint;
      }
    });
  }

  confirmTransfer(): void {
    const userObj = {
      redeemPoints: this.redeemablePoint,
      mobileNumber: this.mobileNumber,
      locale: 'IN'
    };
    this.httpService.post('redeempoints', true, userObj, false, false, false, environment.serverURLv3).subscribe(res => {
      if (res.code === 200) {
        this.currentState = 'confirm';
        this.getReferralDetails();
      }
    });
  }

  closeModal(): void {
    this.otpDigit1 = '';
    this.otpDigit2 = '';
    this.otpDigit3 = '';
    this.otpDigit4 = '';
    this.otpValue = 0;
    this.mobileNumber = null;
    this.currentState = 'initial';
    this.buttonText = 'Send OTP';
  }
}

const HELP_REPORT_STATUS = {
    CLOSED: "closed",
    ACCEPTED: "accepted",
    COMPLETED: "completed",
    SUBMITTED: "submitted",
    OPEN:"open",
    ONGOING: "ongoing",
    REOPENED: "reopened"

 };

export default HELP_REPORT_STATUS;
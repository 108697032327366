import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-numerical-input-pad',
  templateUrl: './numerical-input-pad.component.html',
  styleUrls: ['./numerical-input-pad.component.scss'],
})
export class NumericalInputPadComponent implements OnInit, OnChanges {
  @Input() isAttempted: boolean;
  @Input() isCorrectAttempt: boolean;
  @Input() intitalAnswer: string;
  @Output() updateAnswerKey = new EventEmitter<any>();
  public answerKey: string = '';
  public currentInputIndex: number = 0;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.answerKey = this.intitalAnswer;
  }
  // number click on virtual keyboard
  numberIntegerClick(num): void {
    // focus element
    let element: any;
    element = document.getElementById('integer');

    if (this.answerKey.length < 10) {
      this.answerKey =
        this.answerKey.substring(0, this.currentInputIndex) +
        String(num) +
        this.answerKey.substring(this.currentInputIndex);
      this.currentInputIndex = this.currentInputIndex + 1;
      element.setSelectionRange(
        this.currentInputIndex - 1,
        this.currentInputIndex - 1
      );
      element.focus();
    } else {
      element.setSelectionRange(this.answerKey.length, this.answerKey.length);
      element.focus();
    }
    this.updateAnswerKey.emit(this.answerKey);
  }
  // backspace click
  backspaceClick(): void {
    // focus element
    let element: any;
    element = document.getElementById('integer');

    if (this.currentInputIndex > 0) {
      this.currentInputIndex = this.currentInputIndex - 1;
      element.setSelectionRange(
        this.currentInputIndex - 1,
        this.currentInputIndex - 1
      );
      element.focus();
    } else {
      this.currentInputIndex = 0;
      element.setSelectionRange(0, 0);
      element.focus();
    }
    this.answerKey =
      this.answerKey.substring(0, this.currentInputIndex) +
      this.answerKey.substring(this.currentInputIndex + 1);

    this.updateAnswerKey.emit(this.answerKey);
  }

  //left arrow click on virtual keyboard
  leftArrowClick() {
    //focus element
    let element: any;
    element = document.getElementById('integer');
    if (this.currentInputIndex > 0) {
      this.currentInputIndex = this.currentInputIndex - 1;
      element.setSelectionRange(this.currentInputIndex, this.currentInputIndex);
      element.focus();
    } else {
      element.setSelectionRange(0, 0);
      element.focus();
    }
  }

  //right arrow click on virtual keyboard
  rightArrowClick() {
    //focus element
    let element: any;
    element = document.getElementById('integer');
    if (this.currentInputIndex < this.answerKey.length) {
      this.currentInputIndex = this.currentInputIndex + 1;
      element.setSelectionRange(this.currentInputIndex, this.currentInputIndex);
      element.focus();
    } else {
      element.setSelectionRange(this.answerKey.length, this.answerKey.length);
      element.focus();
    }
  }

  // dot click on virtual keyboard
  dotClick() {
    // focus element
    let dotCount = 0;
    for (let i = 0; i < this.answerKey.length; i++) {
      if (this.answerKey[i] == '.') {
        dotCount = 1;
        break;
      }
    }

    if (dotCount == 0) {
      let element: any;
      element = document.getElementById('integer');

      if (this.answerKey.length < 10) {
        this.answerKey =
          this.answerKey.substring(0, this.currentInputIndex) +
          String('.') +
          this.answerKey.substring(this.currentInputIndex);
        this.currentInputIndex = this.currentInputIndex + 1;
        element.setSelectionRange(
          this.currentInputIndex,
          this.currentInputIndex
        );
        element.focus();
      } else {
        element.setSelectionRange(this.answerKey.length, this.answerKey.length);
        element.focus();
      }
    } else {
      let element: any;
      element = document.getElementById('integer');
      element.focus();
    }
    this.updateAnswerKey.emit(this.answerKey);
  }

  private _normalizeAnswerKey(ansKey: string): string {
    let answerKey = ansKey || this.answerKey || '';
    const dashExists = !!answerKey.match(/-/);

    if (!dashExists) {
      return answerKey;
    }

    answerKey = answerKey.replace(/-/, '');
    answerKey = '-'.concat(answerKey);
    return answerKey;
  }

  dashClick() {
    let answerKey = this.answerKey || '';
    let currentInputIndex = this.currentInputIndex || 0;
    const dashExists = !!answerKey.match(/-/);

    if (dashExists) {
      answerKey = answerKey.replace(/-/, '');
      currentInputIndex = currentInputIndex - 1;
    } else {
      answerKey = '-'.concat(answerKey);
      currentInputIndex = currentInputIndex + 1;
    }
    if (answerKey.length > 0) {
      this.answerKey = this._normalizeAnswerKey(answerKey);
    } else {
      this.answerKey = answerKey;
    }
    this.currentInputIndex = currentInputIndex;

    let element: any;
    element = document.getElementById('integer');
    element.setSelectionRange(this.answerKey.length, this.answerKey.length);
    element.focus();
    this.updateAnswerKey.emit(this.answerKey);
  }
  updateValue(event) {
    this.updateAnswerKey.emit(this.answerKey);
  }
}

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-help-issue-timeline',
  templateUrl: './help-issue-timeline.component.html',
  styleUrls: ['./help-issue-timeline.component.scss']
})
export class HelpIssueTimelineComponent implements OnInit {
  @Output() onClose = new EventEmitter<any>();
  @Input() timeline;
  constructor(private datePipe:DatePipe) { }

  ngOnInit(): void {
   
  }
  getSolutionDate(dateString: string): string {
    const date: Date = new Date(dateString);
    const onlyTime = this.datePipe.transform(date, "h:mm a");;
    const displayDate: string =  onlyTime;
    return displayDate;
  }
  onClosePopup(){
    $('#timelinePopup').modal('hide');
    $('.modal-backdrop').removeClass('show');
    this.onClose.emit();
  }
}

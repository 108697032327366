<!-- BEGIN # MODAL LOGIN -->

<div class="modal fade" id="warn-serious" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog custmofferpopup">
      <div class="modal-content bor-reuds ">
  
        <div class="modal-body custmofferpopup-pdzero">
          <div class="row">
            <div class="col-md-12">
              <button type="button" (click)="resumeTest()" class="close" data-dismiss="modal">&times;</button>
              <div class="row">
                <div class="col-md-12 text-center ">
                  <img appLazyLoad src="{{dataRecieved.warningData.image}}" *ngIf="pageLoaded==='solution'" class="warning_img">
                  <img appLazyLoad src="../../../assets/img/s3-images/casual_attempt_icon.svg" *ngIf="pageLoaded==='test'" class="warning_img">
                  <h3 class="warning_header" *ngIf="pageLoaded==='test'">Casual Attempt!</h3>
                  <h3 class="warning_header" *ngIf="pageLoaded==='solution'">{{dataRecieved.warningData.heading}}</h3>
                  <p class="warning_msg" *ngIf="pageLoaded==='test'">You have attempted {{dataRecieved.totalQuestions}} questions in {{dataRecieved.totalTimeTaken | convertSeconds}} of time.
                    Your test will be labelled as casual and no competitive analysis will be generated if your performance is not up to the mark.</p>
                    <p class="warning_msg" *ngIf="pageLoaded==='solution'">{{dataRecieved.warningData.text}}</p>
                </div>
                <div class="col-md-12 text-center d-flex" *ngIf="pageLoaded==='test'">
                  <a class="btn back_dashboard" (click)="submitTest()">Submit</a>
                  <button type="button" class="btn btn-primary" (click)="resumeTest()">Resume</button>
                </div>
                <div class="col-md-12 text-center d-flex" *ngIf="pageLoaded==='solution'">
                  <a class="btn back_dashboard" (click)="submitTest()">Continue to solution</a>
                  <button type="button" class="btn btn-primary" (click)="closePopup()">BACK TO OVERVIEW</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Offers popup end -->
import { Pipe, PipeTransform } from '@angular/core';
import { DATE_SUFFIX, DEFAULT } from '../constants/my-classes.constant';

@Pipe({
  name: 'dateFormat'
})
export class DateFormat implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const date = new Date(value);
    const day = date.getDate();
    const month = date.toLocaleString(DEFAULT, { month: 'long' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  // private getDaySuffix(day: number): string { commented for furture refrence
  //   if (day >= DATE_SUFFIX.ELEVEN && day <= DATE_SUFFIX.THIRTEEN) {
  //     return DATE_SUFFIX.TH;
  //   }
  //   switch (day % DATE_SUFFIX.TEN) {
  //     case 1:
  //       return DATE_SUFFIX.ST;
  //     case 2:
  //       return DATE_SUFFIX.ND;
  //     case 3:
  //       return DATE_SUFFIX.RD;
  //     default:
  //       return DATE_SUFFIX.TH;
  //   }
  // }
}

<div class="reportIssue_modal modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" id="reopenIssue">
    <div class="modal-dialog modal-dialog-centered  reopenIssue" role="document">
      <div class="modal-content"><div class="opacityDiscard"></div>
        <div class="modal-header">
          <div class="col-1"></div>
          <div class="col-10 text-center">
            <h5 class="modal-title">Re-open Issue</h5>
          </div>
          <div class="col-1 ms-3">
            <button
              type="button"
              [ngClass]="discardChanges ? 'close closedisable':'close'"
              (click)="closePopup()"
              [disabled]="disabledCrossIcon"
            >
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div class="form-group marginBot">
            <!-- <div class="dropdown">
              <button
                class="btn dropdown-toggle"
                type="button"
                id="problemTypeList"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <p class="selectcoursetext">{{reqObj.category}}</p>
              </button>
              <div class="dropdown-menu" aria-labelledby="problemTypeList">
                <ul>
                  <li *ngFor="let item of issueType" (click)="onChangeProblem(item)">{{item}}</li>
                </ul>
              </div>
            </div> -->
            <div class="courseDropdown">
              <div class="dropdown">
                <button class="btn btn-goalList dropdown-toggle" type="button" id="goalListMenuList-Button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false" [disabled]="isLoading" [ngClass]="{'redborder': errorTextProblem}" (focus)="focusreopeningReason()">
                  <p class="selectcoursetext">{{reqObj.reopeningReason}}</p>
                </button>
                <div class="dropdown-menu" aria-labelledby="goalListMenuListButton">
                  <div class="goalList">
                    <a *ngFor="let item of issueType" (click)="onChangeProblem(item.name)" [class.active]="reqObj.reopeningReason === item.name" class="dropdown-item"
                     href="javascript:void(0)">
                      <span>{{item.name}}</span></a>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="error" *ngIf="errorTextProblem">Please Select Problem Type</div> -->
          </div>
          <div class="form-group marginBot2">
            <textarea [ngClass]="{'disabled': reqObj.reopeningReason === 'Select', 'redborder': descriptionError}" [disabled]="reqObj.reopeningReason === 'Select' || isLoading" [(ngModel)]="reqObj.description" (keyup)="onKeyUp()" placeholder="Describe your issue in detail…" rows="6"></textarea>
            <span class="counter" [ngClass]="descriptionLength >= 40 ? 'textColorChange' : ''"><span id="current" class="coutText">{{descriptionLength}} <span *ngIf="descriptionLength == 1">character</span><span *ngIf="descriptionLength != 1">characters</span></span> (min 40 characters)</span>
            <!-- <span class="error" *ngIf="errorText">Description too short! Please elaborate your issue further.</span> -->
          </div>
          <div class="form-group attachMents">
            <label>Attachments<div [ngClass]="fileTypeAccept?'error':'fileTypeUpload'" style="margin-top:0">only .jpg, .jpeg, .png and .mp4 files are supported</div></label>
            <div class="attachFile" [ngClass]="{'disabled':isLoading}">
              <span class="blankData">
                <label for="files" class="btn"></label>
                <input type='file' id="files" accept=".jpg,.jpeg,.png,.mp4" (change)="onSelectFile($event)" (click)="onimageClick()"  multiple/>
            </span>
              <span *ngFor="let list of mydata;let index = index">
                <img class="img" *ngIf="list.type == 'image'" [src]="list.url" />
                <!-- <video *ngIf="list.type == 'video'" class="img" [src]="list.url"></video> -->
                <img class="img" *ngIf="list.type == 'video'"
                    src="../../../../../../assets/images/help/bgwith-play.png"
                    alt="Video"
                />
                <button class="closebutton" (click)="closeThumb(index)" href="javascript:void(0);" [disabled]="disabledCrossIcon"
                  ><img
                    src="../../../../../../assets/images/help/attachClose.svg"
                    alt="close" alt="close" [disabled]='isLoading'
                /></button>
              </span>
              <div class="blankDiv w-100">
              <p class="fileSizeText">Max. File Size: 100 MB</p>
            </div>
            </div>
            <p class="error mtop" *ngIf="fileAdded">File is already added!</p>
            <div class="error mtop" *ngIf="fileSizeError">Files greater than 100 MB cannot be added</div>
            <!-- <div class="error" *ngIf="fileTypeAccept">Only .jpg, .jpeg, .png and .mp4, files are supported</div> -->
          </div>
          <div *ngIf="discardChanges" class="discardChanges">
            <div class="txtDiscard">
              <b>Discard Changes?</b>
              <em>Changes you made will be lost</em>
            </div>
            <div class="discardCta">
              <button type="button" (click)="onDiscard()" class="btn textButton">Discard</button>
              <button type="button" (click)="cancelClick()" class="btn btn-primary">Cancel</button>
            </div>
          </div>
          <div class="submitCta">
            <label *ngIf="!this.mydata.length" class="noAttachment">No File Attached</label>
            <label class="noAttachment" *ngIf="this.mydata.length">{{this.mydata.length}} Attachment{{this.mydata.length > 1 ? 's' : ''}} added</label>
            <button type="button" (click)="reportAnIssue()" [ngClass]="{'disabledClass': !checkIfDisabled}" [disabled]="isLoading" class="btn btn-primary">
              <span [ngClass]="{'spinner': isLoading}"></span>
              Submit
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formateTime'
})
export class FormateTimePipe implements PipeTransform {

  transform(time: any, args?: any): any {
    const hrs = ~~(time / 3600);
    const mins = ~~((time % 3600) / 60);
    const secs = ~~time % 60;

    let timeString = '';
    if (hrs > 0) {
      timeString = hrs + 'h ' + mins + 'm ';
    } else if (mins > 0) {
      timeString = mins + 'm ';
    } else {
      timeString = secs + 's';
    }
    return timeString;
  }

}

import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Router } from '@angular/router';
import { HttpService } from '../../shared/services/http.service';
import { AlertService } from '../../shared/services/alert.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class OverviewService {

  public dataForHeader = new BehaviorSubject<any>({});
  public dataForMarksDistribution = new BehaviorSubject<any>({});
  public dataForInsightCard = new BehaviorSubject<any>({});
  public dataForOtherUsersResult = new BehaviorSubject<any>({});
  public dataForDifficultyAbilityDistribution = new BehaviorSubject<any>({});
  public routeData = new BehaviorSubject<any>({});
  public dataForTopRankerListForOverview = new BehaviorSubject<any>({});
  public dataQuestionTypeDistributionForOverview = new BehaviorSubject<any>({});
  public isOverviewLoading = new BehaviorSubject<boolean>(true);
  public dataForRegenarationStrp = new BehaviorSubject<any>({});
  public performanceHistoryGraph = new BehaviorSubject<any>({});
  public goalperformanceHistoryGraph = new BehaviorSubject<any>({});
  public areTimedStatsAvailable = new BehaviorSubject<boolean>(false);
  public testModeType = new BehaviorSubject<any>({});
  public regenenerateData:any=new BehaviorSubject<any>({})
  public viewUpdateFlag: boolean=false;
  public applyClicked:boolean=false;
  public resultRegen:boolean=false;
  public recentDate: any;
  public tabSelected=new BehaviorSubject({});
  public regeneratedQues: any;
  public questionListDisplay=new BehaviorSubject<any>({});
  public modifiedSolutionList=new BehaviorSubject<any>({});


  pushedData = new EventEmitter<any>();

  constructor(private http: HttpService, private router: Router, private alert: AlertService) { }

  getStudentAnalysisForOverview(params) {
    this.isOverviewLoading.next(true);
    this.http.get('studentAnalysis', true, params, false, false, environment.serverURLv3).subscribe((res) => {
      res.data.marksDistributionAndTimeStrategy['isSubjective'] = res.data.isSubjective;
      res.data.marksDistributionAndTimeStrategy['isSubjectiveTest'] = res.data.isSubjectiveTest;
      this.areTimedStatsAvailable.next(res.data.timedStats);
      this.testModeType.next(res.data.attemptType);
      this.dataForMarksDistribution.next(res.data.marksDistributionAndTimeStrategy);
      this.dataForInsightCard.next(res.data.insightCard);
      res.data.header['nonSeriousAttempt'] = res.data.nonSeriousAttempt;
      this.dataForHeader.next(res.data.header);
      this.dataForOtherUsersResult.next(res.data.otherUsersResult);
      this.dataForRegenarationStrp.next(res.data.regenerationDetails);
      if (res && res.data && res.data.header) {
        this.isOverviewLoading.next(false);
        const payload = {
          'test type': (this.router.url.split('/')[4] || this.router.url.split('/')[5]) + ' test',
          'test name': res.data.header.testName,
          'target exams': res.data.header.courseName,
          'test score %': (Math.round(this.getCurrentAttemptScore(res.data.marksDistributionAndTimeStrategy) * 100) / 100) + '%',
          'attempt seriousness': res.data.nonSeriousAttempt ? 'No' : 'Yes',
          'attempt rate': res.data.marksDistributionAndTimeStrategy && res.data.marksDistributionAndTimeStrategy.overall
            && res.data.marksDistributionAndTimeStrategy.overall.attemptRate + '%',
          'accuracy rate': res.data.marksDistributionAndTimeStrategy && res.data.marksDistributionAndTimeStrategy.overall
            && res.data.marksDistributionAndTimeStrategy.overall.accuracyRate + '%'
        };
        localStorage.setItem('analyticsforclevertap', JSON.stringify(payload));
        // this.cleverTap.overAllAnalytics(payload);
      }
    });
    this.http.get('performance-history',  true, params, false, false, environment.serverURLv3).subscribe((res) => {
      this.performanceHistoryGraph.next(res?.data.performanceHistoryData)
    })
    this.http.get('goal-performance-summary',true, params, false, false, environment.serverURLv3).subscribe((res) => {
      this.goalperformanceHistoryGraph.next(res)
    })
  }

  getCurrentAttemptScore(scoreVals) {
    if (scoreVals.overall && scoreVals.overall.correctMarks) {
      return (scoreVals.overall.correctMarks / scoreVals.overall.totalMarks) * 100;
    }
  }

  // Fetching Data For TopRankers Component
  getTopRankerListForOverview(params) {
    const apiURl = `testToppers?testId=${params.testId}&courseId=${params.courseId}`
    this.http.get(apiURl, true, null, false, false, environment.serverURLv3).subscribe((res) => {
      if (res && res.data && res.data.topperStats) {
        this.dataForTopRankerListForOverview.next(res.data.topperStats);
      }
    });
  }

  // Fetching Data For Question Type Distribution
  getQuestionTypeDistributionForOverview(params) {
    this.http.get('testQuestionDistribution', true, params, false, false, environment.serverURLv3).subscribe((res) => {
      this.dataQuestionTypeDistributionForOverview.next(res.data);
      this.dataForDifficultyAbilityDistribution.next(res.data);
    });
  }

  pushData(val: any[]) {
    this.routeData.next(val);
  }

  reset(): void {
    // this.dataForHeader = new BehaviorSubject<any>({});
    // this.dataForMarksDistribution = new BehaviorSubject<any>({});
    // this.dataForInsightCard = new BehaviorSubject<any>({});
    // this.routeData = new BehaviorSubject<any>({});
    // this.dataForTopRankerListForOverview = new BehaviorSubject<any>({});
    // this.dataQuestionTypeDistributionForOverview = new BehaviorSubject<any>({});
    // this.isOverviewLoading = new BehaviorSubject<boolean>(true);
  }

  getRegerateResultDetails(attemptId) {
    return new Promise((resolve, reject) => {
      const url = 'result-regeneration/' + attemptId + '/details';
      this.http.get(url, true, null, true, false, environment.serverURLv3).subscribe((res) => {
        if (res.code === 200) {
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  getRemedialQuestion(attemptId){
    return this.http.get('v4/auth/remedial-questions', true, {attemptId}, true);
  }
}

<!--leave feedback popup-->
<div class="popup" tabindex="-1" id="leaveFeedbackModal" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">
      <div class="modal-body">
          <img src="/assets/images/help/cross-icon.svg" (click)="closeFeedBack()" class="cross" data-dismiss="modal" />
        <h3>{{currentHelpObj?.feedback?.rating > 0 ? 'Edit Feedback' : 'Leave Feedback'}}</h3>
        <div class="leaveFeedbackText" *ngIf="type === 'help'">
          <p><span>Problem Area:</span> {{currentHelpObj?.categories[0]?.name}}</p>
          <p class="truncateDiv1" title="{{currentHelpObj?.description}}"><span>Description:</span> {{currentHelpObj?.description}}</p>
          <div class="DateContainer" *ngIf="showDates">
          <div *ngIf="showDates" class="date" [innerHTML]="getTicketStatusWithDate()">
          </div>
        </div>
        </div>
        <div class="leaveFeedbackText" *ngIf="type === 'idea'">
          <p><span>ID:</span> {{currentHelpObj?.ticketId}}</p>
          <div class="">
            <p class="ideaType">
            <span style="text-transform: capitalize;">{{currentHelpObj?.type}} Type: </span><span class="listingModule" [innerHTML]="getCategoriesName(currentHelpObj)"></span></p>
            <button class="btn showCategoryDropdown dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="showCategory"*ngIf="checkNumberShow(currentHelpObj)"> +{{getNumber(currentHelpObj)}} <img src="/assets/images/idea-and-suggestion/downA.svg">
            </i>
          </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <span class="customTooltip">
              <em class="SuggestionItems" *ngFor="let item of selectedCategory">{{item}}</em>
            </span>
          </div>
          </div>
           <p class="truncateDiv1" title="{{currentHelpObj?.description}}"><span>Description:</span> {{currentHelpObj?.description}}</p>
           <div class="DateContainer" *ngIf="showDates">
           <div *ngIf="showDates" class="date {{(currentHelpObj.status === 'closed') ? 'closed' : ''}}" [innerHTML]="getTicketStatusWithDate()">
             </div>
          </div>
        </div>
        <div class="rating-container">
          <div class="star" *ngFor="let star of stars; let i= index" (click)="countStar(star)" id="{{'starId'+i}}" [ngClass]="{'active': (star <= selectedValue), 'activeStar': (star <= selectedValue), 'star-click': (star == selectedValue)}" (mouseover)="addClass(star)" (mouseout)="removeClass(star)">
            <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" class="active">
                <path d="M14 0L17.1432 9.67376H27.3148L19.0858 15.6525L22.229 25.3262L14 19.3475L5.77101 25.3262L8.9142 15.6525L0.685208 9.67376H10.8568L14 0Z" fill="#C4C4C4"/>
            </svg>
          </div>
        </div>
        <p *ngIf="ratingError" class="rating-error-msg">Please select the Rating</p>
        <div class="bgContainer">
          <textarea #feedbackInput id="feedback" [(ngModel)]="feedBackObj.message" (keyup)="onKeyUp()" name="feedback" rows="6"
            placeholder="{{getPlaceholderText}}" [ngClass]="{'redborder': descriptionError}" #feedBackContainer >
            </textarea>
            <div class="d-flex justify-content-between">
            <span class="error mt-0 position-absolute" *ngIf="descriptionError">Please write the Feedback</span>
            <span class="error counter" [ngClass]="descriptionLength >= 40 ? 'textColorChange' : ''" *ngIf="selectedValue < 4 && hideDescriptionlength"><span id="current" class="coutText">{{descriptionLength}} <span *ngIf="descriptionLength == 1">character</span><span *ngIf="descriptionLength != 1">characters</span></span> (min 40 characters)</span>
        </div>
        </div>
        <button type="button" [ngClass]="{'disabledClass': submitDisable()}" (click)="onSubmit()" class="btn submit-btn btn-primary" data-dismiss="modal">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

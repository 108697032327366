<header>
  <nav class="navbar navbar-expand-lg">
    <button class="navbar-toggler mobile-menu-header" type="button" data-toggle="collapse" data-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
    <div class="logo-view">
      <a class="logo desktop-logo" href="javascript:void(0)" (click)="navigateToHome()"><img *ngTemplateOutlet="glorifireLogo" /></a>
      <a class="logo mobile-logo" href="/home" (click)="navigateToHome()"><img *ngTemplateOutlet="glorifireLogo" /></a>
    </div>
    <!--<a aria-label="glorifire mobile logo" class="navbar-brand mobile-logo" href="javascript:void(0)" (click)="navigateToHome()">
      <img *ngTemplateOutlet="glorifireMobileIcon" />
    </a>-->
    <div class="mobile-overlay"></div>
    <div class="collapse navbar-collapse" id="navbarNav">
      <div class="menu-logo">
        <a aria-label="glorifire logo" class="mypat-logo-blue" href="javascript:void(0)" (click)="navigateToHome()">
          <img *ngTemplateOutlet="glorifireLogo" />
        </a>
        <!-- <a class="menu-profile-img"><img src="../../../../assets/images/user-profile-img.svg" /></a> -->
      </div>

      <ul class="navbar-nav">
        <li class="nav-item" [ngClass]="{'active':currentUrl==='/home' || currentUrl.includes('searchResult') || currentUrl.includes('my-revision-plan') || currentUrl.includes('revision/my-revision-plan')}">
          <a class="nav-link" href="javascript:void(0)" (click)="navigateToHome()">
            <img class="menu-icon" [src]="'/assets/images/home-icon.svg' " />
            Home
          </a>
        </li>
        <li *ngIf="!userData?.isNCRPUser" class="nav-item" routerLink="/live-classes" [ngClass]="{'active':currentUrl.includes('/live-classes')}">
          <a class="nav-link" href="javascript:void(0)"
            >
            <img class="menu-icon" [src]="'/assets/images/live-video-icon.svg'  " />
            Live Classes
          </a>
        </li>
        <li class="nav-item desktop-community" [ngClass]="{'active':currentUrl.includes('/community')}">
          <a class="nav-link" href="javascript:void(0)" (click)="navigateToCommunity()">community</a>
        </li>
        <li class="menu-icon nav-item communityMenu dropdown">
          <a class="nav-link dropdown-toggle" id="communityMenuButton" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" href="#">
            <img class="menu-icon" [src]="'/assets/images/community-icon.svg' " />COMMUNITY</a>
          <div class="dropdown-menu communityMenulist" aria-labelledby="communityMenuButton">
            <a class="dropdown-item active" href="#">My Feed</a>
            <a class="dropdown-item" href="#">My Questions (20)</a>
            <a class="dropdown-item" href="#">My answers (10)</a>
          </div>
        </li>
      </ul>
      
      <div class="d-block d-md-none applink">
        <a class="download-btn btn-block" href="https://play.google.com/store/apps/details?id=com.edfora.glorifire" target="_blank"><img class=""
            [src]="'/assets/images/app-icon.svg' " />
          Get the App
        </a>
      </div>
    </div>
  </nav>
  <!-- Global Search -->
  <div class="globalSearch" [class.searchHideRes]="isMobile && showSearchIcon">
    <app-home-search [clearSearch]="clearSearch" (onExpand)="onExpand($event)"></app-home-search>
  </div>
  <!-- End Global Search -->

  <div class="header_right_view" [class.hideRightNav]="hideRightNav">
    <ul class="right-list">
      <a class="searchIconResponsive" href="javascript:void(0);" (click)="searchClicked()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.4351 14.0629H14.7124L14.4563 13.8159C15.3838 12.7401 15.8935 11.3667 15.8925 9.94626C15.8925 8.7702 15.5438 7.62055 14.8904 6.64269C14.237 5.66484 13.3083 4.90269 12.2218 4.45263C11.1353 4.00258 9.93966 3.88482 8.7862 4.11426C7.63274 4.3437 6.57322 4.91002 5.74162 5.74162C4.91002 6.57322 4.3437 7.63274 4.11426 8.7862C3.88482 9.93966 4.00258 11.1353 4.45263 12.2218C4.90269 13.3083 5.66484 14.237 6.64269 14.8904C7.62055 15.5438 8.7702 15.8925 9.94626 15.8925C11.4191 15.8925 12.773 15.3528 13.8159 14.4563L14.0629 14.7124V15.4351L18.6369 20L20 18.6369L15.4351 14.0629ZM9.94626 14.0629C7.66838 14.0629 5.82962 12.2241 5.82962 9.94626C5.82962 7.66838 7.66838 5.82962 9.94626 5.82962C12.2241 5.82962 14.0629 7.66838 14.0629 9.94626C14.0629 12.2241 12.2241 14.0629 9.94626 14.0629Z" fill="#1D7DEA"/>
        </svg>          
      </a>
      <!-- <li class="d-none d-sm-block buy-nowtext">
        <span *ngIf="freeTrialData && freeTrialData.isFreeTrial" [innerHtml]="freeTrialData && freeTrialData.message"></span>
        <button class="btn btn-buynow">Buy Now</button>
      </li> -->
      <li class="d-none d-md-block">
        <!-- <button class="btn app-btn" (click)="goToDownloadApp()">
          <span><img src="../../../../assets/images/app-icon.svg" /></span>Get
          the App
        </button> (click)="goToDownloadApp()"-->
        <a aria-label="download app" class="btn app-btn" href="https://play.google.com/store/apps/details?id=com.edfora.glorifire" target="_blank">
          <span><img [src]="'/assets/images/blue-download-app.svg' " /></span>
        </a>        
      </li>
      <li>
        <app-notification *ngIf="userData.userId" [userData]="userData"></app-notification>
      </li>
      <li class="profile-menu">
        <app-profile-dropdown *ngIf="userData.userId" [userData]="userData" (openPassword)="changePassword()" (openLogout)="logoutConfirm()"></app-profile-dropdown>
      </li>
    </ul>

    <!--menu overlay start-->
    <!-- <div id="menu-overlay" class="overly" [ngClass]="{'hide': isClassVisible }">
      <span class="closeicon" (click)="isClassVisible = !isClassVisible;">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div> -->
    <!--menu overlay end-->
  </div>
</header>
<!-- Clear All Modal -->
<div class="modal fade modalClearall" id="modalClearall" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <img [src]="'/assets/images/globalSearch/PopupSearchIcon.svg' " />
        <h3>Search History</h3>
        <p>Are you sure you want to clear your Search History?</p>
      </div>
      <div class="modal-footer justify-content-end">
        <button type="button" class="btn btn-text" data-dismiss="modal" (click)="autoFocusSearchField()">No</button>
        <button type="button" class="btn btn-button" data-dismiss="modal" (click)="clearSearchResults(); autoFocusSearchField()">Yes</button>
        <!--<button type="button" class="btn btn-button" (click)="clearAll()">Yes</button>-->
      </div>
    </div>
  </div>
</div>
<!-- Clear All Modal -->
<app-logout-confirm *ngIf="showLogoutPopup" (closeLogoutPopup)="closePopup()"></app-logout-confirm>
<app-change-password *ngIf="showChangePassword" (closeChangePass)="closeChangePassword()"></app-change-password>
<app-done-with-revision *ngIf="showRevModal" (confirmExit)="confirmExit($event)" (closeRevPopup)="closeRevPopup()"></app-done-with-revision>
<app-myplan-popup></app-myplan-popup>

<ng-template #glorifireMobileIcon>
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_12353_323392" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="40" height="40">
    <circle cx="21" cy="21" r="19.4195" fill="#C4C4C4" stroke="black" stroke-width="1.16096"/>
    </mask>
    <g mask="url(#mask0_12353_323392)">
    <circle cx="7.6662" cy="34.3376" r="10.7692" fill="#4C7CB8"/>
    <circle cx="33.3149" cy="34.3376" r="10.7692" fill="#1CA85E"/>
    <circle cx="33.3149" cy="7.67157" r="10.7692" fill="#FEB845"/>
    <circle cx="7.6662" cy="7.67157" r="10.7692" fill="#EE403D"/>
    <circle cx="7.6662" cy="7.67157" r="10.7692" fill="#E93B38"/>
    </g>
    </svg>    
</ng-template>

<ng-template #glorifireLogo>  
  <img src="/assets/images/glorifire-logo-1.svg" alt="Glorifire Logo" />
</ng-template>
<app-feedback-popup></app-feedback-popup>
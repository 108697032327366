import { Component, Input, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-help-light-box',
  templateUrl: './help-light-box.component.html',
  styleUrls: ['./help-light-box.component.scss']
})
export class HelpLightBoxComponent implements OnInit {
  hasAttachments = false;
  helpDataImage = [];
  currentData: any;
  imageData:boolean = false;
  videoData:boolean = false;

  ngOnInit(): void {
    // $('#map').css('transform', 'scale(' + this.zoom + ')');
  }

  // zoomIn() {
  //   if (this.zoom < 2) {
  //     this.zoom += 0.1;
  //     $('#map').css('transform', 'scale(' + this.zoom + ')');
  //   }
  // }

  // zoomOut() {
  //   if (this.zoom > 1) {
  //     this.zoom -= 0.1;
  //     $('#map').css('transform', 'scale(' + this.zoom + ')');
  //   }
  // }
  
  currentIndex = 0;
  loadData(attachments, index): void {
    this.currentIndex = index
    this.helpDataImage = attachments;
    this.currentData = attachments[index]
    this.hasAttachments = true;
    if (this.currentData.type === 'image') {
      this.imageData = true;
      this.videoData = false;
    } else {
      this.videoData = true;
      this.imageData = false;
    }
    setTimeout(() => {
      $(document).ready(function () {
        $('.owl-carousel').owlCarousel({
          loop: false,
          dots: false,
          touchDrag: false,
          mouseDrag: false,
          autoplay: false,
          nav: true,
          autoWidth: false,
          margin: 15,
          navText: [`<div class='nav-btn prev-slide'>
          <img src='/assets/images/revision/left-arrow.svg' />
          </div>`, `<div class='nav-btn next-slide'>
          <img src='/assets/images/revision/right-arrow.svg' /></div>`],
          responsive: {
            0: {
              items: 2,
              autoHeight: true,
              autoWidth: true,
              margin: 15
            },
            600: {
              items: 2,
              autoHeight: true,
              autoWidth: false,
            },
            740: {
              items: 4,
              autoWidth: true
            },
            940: {
              items: 5,
              autoWidth: true
            },
            1025: {
              items: 6,
              autoWidth: true,
            }
          }
        })
        let carousel = $('.owl-carousel');
        carousel.owlCarousel();
        carousel.trigger("to.owl.carousel", [index, 500, true]);

        $('.owl-item').click(function () {
          $(".item").removeClass('active');
          $(this).addClass('imgActive').siblings().removeClass('imgActive');

        });
      });
    }, 500);
  }

  previewAttach(currentData) {
    this.currentData = currentData;
    if (this.currentData.type === 'image') {
      this.imageData = true;
      this.videoData = false;
    } else {
      this.videoData = true;
      this.imageData = false;
    }
    // this.zoom=1;
    // $('#map').css('transform', 'scale(' + this.zoom + ')');
    $('.preview').animate({ scrollTop: 0 }, "fast");
  }


  closeModal() {
    this.hasAttachments = false;
    $('video').trigger('pause');
    $('#previewModal').modal('hide');
    this.imageData = false;
  }
}

<div class="regDevice-contaier">
    <h4>{{fromScreen === 'login' ? 'Current ' : ''}}Registered Devices</h4>
    <div class="regDevice-panel">
        <div class="device-card" *ngFor="let deviceInfo of registeredDevicesData" [ngClass]="{'active':deviceInfo?.isLoggedIn}">
            <div>
                <div class="devicetype left">
                    <div class="registerDeviceImg">
                        <div class="activeDevice">
                            <img src="/assets/images/manage-device/{{deviceTypes[deviceInfo?.device?.type]}}.svg"
                            alt="Registered Device"/>
                        
                            <ng-lottie [options]="greenTickAnimationOptions" *ngIf="deviceInfo?.isLoggedIn"></ng-lottie>                    
                        </div>
                        <div>
                            <div class="deviceName">{{deviceInfo?.device?.model}} </div>
                            <p>{{deviceInfo?.device?.location}}</p>
                        </div>
                    </div>
                </div>
                <div class="devicetype left">
                    <img class="browser-logo" [src]="deviceInfo?.browserImg" alt="browser" />
                    <div>
                        <div class="deviceName" [title]="deviceInfo?.device?.name">{{deviceInfo?.device?.name | slice: 0: DEVICE_NAME_MAX_SIZE}}{{registeredDevice?.device?.name?.length > DEVICE_NAME_MAX_SIZE ? '...' : ''}}</div>
                        <p *ngIf="!deviceInfo?.isLoggedIn;else activeDevice">{{deviceInfo?.updatedLastActive}}</p>
                        <ng-template #activeDevice>
                            <a class="deviceStatus active">
                                <img src="/assets/images/manage-device/active-dot.svg" alt="Active"/>Active
                            </a>
                        </ng-template>
                    </div>
                    </div>
            </div>
            <a (click)="removeDeviceClicked(deviceInfo)" role="button"
                class="deviceStatus" data-toggle="modal" [attr.data-target]="deviceInfo?.isLoggedIn ? '#removeActiveDevice' : '#removeDevice'">Remove</a>
        </div>
    </div>
</div>
<!-- Remove device info modal -->
<div class="remove-device-modal modal fade small-modal" data-backdrop="false" tabindex="-1" id="removeDevice">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
        <div class="modal-content">
            <div class="modal-body">
                <img src="/assets/images/manage-device/remove-device-icon.svg" alt="Remove Device Icon" />
                <h3>Remove Device</h3>
                <p>Are you sure you want to remove <b>{{removeDeviceDetails?.device?.model}}</b> as your registered device?
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn textButton" data-dismiss="modal">No</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="confirmRemoveDevice()">Yes</button>
            </div>
        </div>
    </div>
</div>

<!-- Remove active device info modal -->
<div class="remove-active-device-modal modal fade small-modal" data-backdrop="false" tabindex="-1" id="removeActiveDevice">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
        <div class="modal-content">
            <div class="modal-body">
                <img src="/assets/images/manage-device/remove-active-device-icon.svg" alt="Remove Device Icon" />
                <h3>Remove Active Device</h3>
                <p>
                    You are already active on your registered device having <b>{{removeDeviceDetails?.device?.model}}</b>. At any given time, you can access Glorifire Platform through only one of your registered devices.
                </p>
                <div class="logout-prevDevice">
                    <p>Continuing further will automatically log you out and remove this device from your registered device list.</p>
                </div>
                <h6>Are you sure you want to {{fromScreen === 'login' ? 'remove this device and ' : ''}} continue?</h6>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn textButton" data-dismiss="modal">No</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="confirmRemoveDevice()">Yes</button>
            </div>
        </div>
    </div>
</div>